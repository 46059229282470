import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.service";
var TicketManagerService = /** @class */ (function () {
    function TicketManagerService(http, applicationService) {
        this.http = http;
        this.appService = applicationService;
    }
    TicketManagerService.prototype.getPccDetails = function () {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('my/pccs'), {}, { headers: headerData });
    };
    TicketManagerService.prototype.retrieveBookingRequest1 = function (pccId, timezoneId, pnrcode) {
        var headerData = this.appService.getHeaderAfterLogged();
        this.requestData = {
            'pcc': pccId,
            'pnrcode': pnrcode,
            'zone': timezoneId
        };
        return this.http.post(environment.apiLink.concat('retrieve/booking/request1'), this.requestData, { headers: headerData });
    };
    TicketManagerService.prototype.retrieveBookingRequest2 = function (pccId, timezoneId, pnrcode, retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('retrieve/booking/request2'), {
            'pcc': pccId,
            'pnrcode': pnrcode,
            'zone': timezoneId,
            'retrieveInstanceId': retrieveInstanceId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.retrieveBookingRequest3 = function (pccId, timezoneId, pnrcode, retrieveInstanceId, origin, destination) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('retrieve/booking/request3'), {
            'pcc': pccId,
            'pnrcode': pnrcode,
            'zone': timezoneId,
            'origin': origin,
            'destination': destination,
            'retrieveInstanceId': retrieveInstanceId,
        }, { headers: headerData });
    };
    TicketManagerService.prototype.retrieveBookingRequest4 = function (retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        if (sessionStorage.getItem("isCCard") && sessionStorage.getItem("isCCard") == "1") {
            this.requestData = {
                'retrieveInstanceId': retrieveInstanceId,
                'carddetails': JSON.parse(sessionStorage.getItem('carDetails'))
            };
        }
        else {
            this.requestData = {
                'retrieveInstanceId': retrieveInstanceId
            };
        }
        return this.http.post(environment.apiLink.concat('retrieve/booking/request4'), this.requestData, { headers: headerData });
    };
    TicketManagerService.prototype.addToQueueBooking = function (retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('queue/ticket'), {
            'retrieveInstanceId': retrieveInstanceId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.apiStartTimer = function (pnrCode, retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('start/timer'), {
            'pnr': pnrCode,
            'retrieveInstanceId': retrieveInstanceId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.apiGetRemainingTimer = function (pnrCode, retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('remaining/timer'), {
            'pnr': pnrCode,
            'retrieveInstanceId': retrieveInstanceId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.getPdfFilePath = function (pnrcode, pcc_id, zone) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('retrieve/ticket/save/file'), {
            'pcc': pcc_id,
            'pnrcode': pnrcode,
            'zone': zone
        }, { headers: headerData });
    };
    TicketManagerService.prototype.retrievePriceDetails = function (pnrcode, retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('retrieve/pricing'), {
            'pnr': pnrcode,
            'retrieveInstanceId': retrieveInstanceId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.checkPaymentStatus = function (retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('check/payment/status'), {
            'retrieveInstanceId': retrieveInstanceId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.issueAirTicket = function (retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('issue/ticket'), {
            'retrieveInstanceId': retrieveInstanceId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.minMaxAmount = function (retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('get/amounts'), {
            'retrieveInstanceId': retrieveInstanceId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.sendCreditCardAuthorizationRequest = function (retrieveInstanceId, amount) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('credit/card/authorization'), {
            'retrieveInstanceId': retrieveInstanceId,
            'amount': amount
        }, { headers: headerData });
    };
    TicketManagerService.prototype.voidAirTicket = function (ticketNumber, retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('void/ticket'), {
            'id': ticketNumber,
            'retrieveInstanceId': retrieveInstanceId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.downloadEticket = function (ticketNumber, showPrice, theme) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('validate/download/ticket'), {
            'id': ticketNumber,
            'showPrice': showPrice,
            'theme': theme
        }, { headers: headerData });
    };
    TicketManagerService.prototype.emailEticket = function (ticketNumber, showPrice, theme) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('email/ticket'), {
            'id': ticketNumber,
            'showPrice': showPrice,
            'theme': theme
        }, { headers: headerData });
    };
    TicketManagerService.prototype.getIssuedAirTickets = function () {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('issued/tickets'), {}, { headers: headerData });
    };
    TicketManagerService.prototype.getQueuedBookings = function () {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('queued/bookings'), {}, { headers: headerData });
    };
    TicketManagerService.prototype.validateCardDetails = function (cradDetails) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('vaildate/card/details'), cradDetails, { headers: headerData });
    };
    TicketManagerService.prototype.keepUserDataBeforePay = function (retrieveInstanceId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('keep/user/data'), {
            'retrieveInstanceId': retrieveInstanceId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.sendOtp = function () {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('auth/user/phonenumber'), {}, { headers: headerData });
    };
    TicketManagerService.prototype.refreshBookingDetails = function (bookingId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('retrive/booking/data'), {
            "booking_id": bookingId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.saveBookingreference = function (bookingReference) {
        var headerData = this.appService.getHeaderAfterLogged();
        var token = JSON.parse(localStorage.getItem("loggedDataCJW")).accessToken;
        return this.http.post(environment.apiLink.concat('save/booking/reference'), {
            'booking_reference': bookingReference,
            'token': token
        }, { headers: headerData });
    };
    TicketManagerService.prototype.getBookingDetails = function () {
        var params = {};
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('get/flight/booking/details'), {}, { headers: headerData });
    };
    TicketManagerService.prototype.cancelAFlightBooking = function (params) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('cancel/flight/booking'), params, { headers: headerData });
    };
    TicketManagerService.prototype.getMoreDetailsForBooking = function (params) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('more/booking/data'), params, { headers: headerData });
    };
    TicketManagerService.prototype.getUser1GPcc = function () {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('get/1g/details'), "", { headers: headerData });
    };
    TicketManagerService.prototype.getInqbaytorPayTrasnactions = function () {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('auth/user/inqbaytorpay/transaction'), {}, { headers: headerData });
    };
    TicketManagerService.prototype.getTrasnactions = function (startDate, endDate) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('auth/user/transactions'), {
            'start_date': startDate,
            'end_date': endDate
        }, { headers: headerData });
    };
    TicketManagerService.prototype.getStatementDetails = function (startDate) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('get/statement/date/details'), {
            'selected_date': startDate
        }, { headers: headerData });
    };
    TicketManagerService.prototype.getTicketPaymentData = function (currentDate, isYesterday) {
        var headerData = this.appService.getHeaderAfterLogged();
        var params = null;
        if (isYesterday == 1) {
            params = {
                'current_date': currentDate,
                'isYesterday': 1
            };
        }
        else {
            params = {
                'current_date': currentDate
            };
        }
        return this.http.post(environment.apiLink.concat('ticket/payment/data'), params, { headers: headerData });
    };
    TicketManagerService.prototype.transerUserLog = function (logData) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('user/log'), logData, { headers: headerData });
    };
    TicketManagerService.prototype.validateTicketDownload = function (ticketNumber) {
        var headerData = this.appService.getHeaderAfterLogged();
        var params = {
            'ticket_number': ticketNumber
        };
        return this.http.post(environment.apiLink.concat('validate/eticket/number'), params, { headers: headerData });
    };
    TicketManagerService.prototype.validateFlightBooking = function (flightBookingReference) {
        var headerData = this.appService.getHeaderAfterLogged();
        var params = {
            'booking_reference': flightBookingReference
        };
        return this.http.post(environment.apiLink.concat('validate/booking/reference'), params, { headers: headerData });
    };
    TicketManagerService.prototype.changeOwnerShip = function (bookingId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('change/booking/ownership'), {
            "booking_id": bookingId
        }, { headers: headerData });
    };
    TicketManagerService.prototype.showVendorRemarks = function (bookingId) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('get/virtual/remarks'), {
            "booking_id": bookingId
        }, { headers: headerData });
    };
    TicketManagerService.ngInjectableDef = i0.defineInjectable({ factory: function TicketManagerService_Factory() { return new TicketManagerService(i0.inject(i1.HttpClient), i0.inject(i2.AppService)); }, token: TicketManagerService, providedIn: "root" });
    return TicketManagerService;
}());
export { TicketManagerService };
