<div class="modal-dialog modal-m">
  <!-- Modal content-->
  <div class="modal-content">
    <div class="modal-header">

      <h4 class="modal-title">Ticket Issuance Session Expiry</h4>
    </div>
    <div class="modal-body">
      <p>Your ticket issuance session has expired, if you wish to proceed remove the filed fares from the GDS, save and exit
        booking and retry</p>
    </div>
    <div class="modal-footer">
      <button type="button" data-dismiss="modal" class="btn btn-primary btn-flat" (click)="cancelPopup()">Ok</button>
    </div>
  </div>

</div>