/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-session-expire.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user-session-expire.component";
import * as i3 from "ngx-bootstrap/modal/bs-modal-ref.service";
import * as i4 from "ngx-bootstrap/modal/bs-modal.service";
import * as i5 from "@angular/router";
var styles_UserSessionExpireComponent = [i0.styles];
var RenderType_UserSessionExpireComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserSessionExpireComponent, data: {} });
export { RenderType_UserSessionExpireComponent as RenderType_UserSessionExpireComponent };
export function View_UserSessionExpireComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "modal-dialog modal-m"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logoutFormTheSystem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Session Expiary"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your session has expired, please re-login to renew your session."])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logoutFormTheSystem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary btn-flat"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"]))], null, null); }
export function View_UserSessionExpireComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-session-expire", [], null, null, null, View_UserSessionExpireComponent_0, RenderType_UserSessionExpireComponent)), i1.ɵdid(1, 114688, null, 0, i2.UserSessionExpireComponent, [i3.BsModalRef, i4.BsModalService, i5.Router, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserSessionExpireComponentNgFactory = i1.ɵccf("app-user-session-expire", i2.UserSessionExpireComponent, View_UserSessionExpireComponent_Host_0, {}, {}, []);
export { UserSessionExpireComponentNgFactory as UserSessionExpireComponentNgFactory };
