import { OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';
var ConfirmRetrieveComponent = /** @class */ (function () {
    function ConfirmRetrieveComponent(modalRef, comparentchildservice) {
        this.modalRef = modalRef;
        this.comparentchildservice = comparentchildservice;
    }
    ConfirmRetrieveComponent.prototype.ngOnInit = function () {
    };
    ConfirmRetrieveComponent.prototype.confirmRetrieve = function () {
        this.comparentchildservice.publish('confirm-retrieve-popup');
        this.cancelPopup();
    };
    ConfirmRetrieveComponent.prototype.cancelPopup = function () {
        this.modalRef.hide();
        this.modalRef = null;
    };
    return ConfirmRetrieveComponent;
}());
export { ConfirmRetrieveComponent };
