import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resend-email-verification',
  templateUrl: './resend-email-verification.component.html',
  styleUrls: ['./resend-email-verification.component.scss']
})
export class ResendEmailVerificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
