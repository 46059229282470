import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CountdownComponent } from 'ngx-countdown';
import { UserDetailsService } from 'src/app/functionalservices/user-details.service';
import { redirectUrls } from '../helpers/redirect-urls';

@Component({
  selector: 'app-old-phone-number-verification',
  templateUrl: './old-phone-number-verification.component.html',
  styleUrls: ['./old-phone-number-verification.component.scss']
})
export class OldPhoneNumberVerificationComponent implements OnInit {
  @ViewChild(CountdownComponent) counter: CountdownComponent;
  smsVerficationForm: FormGroup;
  loggedUserId: String;
  mobileNumber: String;
  successMessage: String;
  errorMessage: String;
  warningMessage: String;
  infoMessage: String;
  appService: AppService;
  commonErrorObject: any;
  code: String;
  tel: String;
  oldPhoneData: any;
  remainingTime: number;
  disableResend: any;

  constructor(
    formBuilder: FormBuilder,
    applicationService: AppService,
    private router: Router,
    private userDetailService: UserDetailsService
  ) {
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    this.loggedUserId = loggedUser.id;
    this.mobileNumber = loggedUser.mobile_number;
    this.appService = applicationService;
    this.disableResend = true;
    this.smsVerficationForm = formBuilder.group({
      'oldcode': ['', [Validators.required]],
      'newcode': ['', [Validators.required]],
      'telOld': ['', []],
      'telNew': ['', []]
    });
  }

  ngOnInit() {
    this.oldPhoneData = JSON.parse(localStorage.getItem('oldPhoneData'));
    this.appService.getResendRemainingTimer().subscribe(
      (data: any) => {
        if (data.code === 1 || data.code === '1') {
          this.remainingTime = data.remainingSeconds;
          this.disableResend = true;
        } else {
          this.disableResend = false;
        }
      },
      (errorResponse: any) => {

      }
    );
    this.smsVerficationForm.controls['telOld'].setValue(this.oldPhoneData.phoneNumber);
    this.smsVerficationForm.controls['telNew'].setValue(this.mobileNumber);
  }
  get f() { return this.smsVerficationForm.controls; }

  verifySMSCode() {
    // tslint:disable-next-line:label-position
    this.successMessage = null;
    this.errorMessage = null;
    const mode = 'verify_both';
    const oldCode = this.f['oldcode'].value;
    const newCode = this.f['newcode'].value;
    this.appService.verifyBothSmsCode(mode, oldCode, newCode).subscribe(
      (data: any) => {
        if (data.code === 1) {
          localStorage.setItem('oldPhoneData', null);
          console.log(data.message);
          sessionStorage.setItem('dashboardSuccessMsg', data.message);
          this.userDetailService.getUserDetails().subscribe(
            (userData: any) => {
              if (userData !== null) {
                localStorage.setItem('user', JSON.stringify(userData));
                let token = localStorage.getItem('accessToken');
                const url = redirectUrls.redirectWelcome+'?token=' + token;                
                window.location.href = url;
              }
            }
          );
        } else {
          this.errorMessage = data.message;
          this.successMessage = null;
        }
      },
      (errorResponse: any) => {
      }
    );
  }

  resendeSMSCode() {
    this.successMessage = null;
    this.errorMessage = null;
    this.disableResend = true;
    this.appService.resendBothSMSVerification().subscribe(
      (data: any) => {
        if (data.code === 1) {
          localStorage.setItem('user', JSON.stringify(data.updatedUserData));
          this.successMessage = data.message;
          this.errorMessage = null;
          this.f['oldcode'].setValue('');
          this.f['newcode'].setValue('');
          this.remainingTime = 120;
        } else {
          this.errorMessage = data.message;
          this.successMessage = null;
          this.disableResend = false;
        }
      },
      (errorResponse: any) => {
      }
    );
  }

  skipVerfication() {
    const mode = 'skip';
    console.log(mode);
    this.appService.skipSMSVerification(mode).subscribe(
      (data: any) => {
        if (data.code === 1) {
          localStorage.setItem('user', JSON.stringify(data.updatedUserData));
          let token = localStorage.getItem('accessToken');
          const url = redirectUrls.redirectWelcome+'?token=' + token;                
          window.location.href = url;
          }
      },
      (errorResponse: any) => {
      }
    );
  }

  closeErrorAlert() {
    this.errorMessage = null;
  }
  closeSuccessMessage() {
    this.successMessage = null;
  }

  finshedTimer() {
    this.disableResend = false;
  }
}
