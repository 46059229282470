import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserDetailsService } from 'src/app/functionalservices/user-details.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserAuthenticaionService } from '../user-authenticaion.service';
var ChangePhoneNumberComponent = /** @class */ (function () {
    function ChangePhoneNumberComponent(formBuilder, applicationService, router, userDetailService, route, authService) {
        this.router = router;
        this.userDetailService = userDetailService;
        this.route = route;
        this.authService = authService;
        this.errorContactNumber = false;
        this.changePhoneNumberForm = formBuilder.group({
            'tel': ['', [Validators.required]]
        });
    }
    ChangePhoneNumberComponent.prototype.ngOnInit = function () {
    };
    ChangePhoneNumberComponent.prototype.checkPhoneNumberValid = function () {
        var _this = this;
        var data = {
            "phoneNumber": this.changePhoneNumberForm.controls['tel'].value,
            "country": this.phoneNumberInput['selectedCountry']['countryCode']
        };
        this.authService.phoneNumberValidating(data).subscribe(function (data) {
            if (data.code == 1) {
                _this.changePhoneNUmber();
            }
            else {
                _this.errorContactNumber = true;
            }
        }, function (htError) {
        });
    };
    ChangePhoneNumberComponent.prototype.onKey = function (e) {
        this.errorContactNumber = false;
        if (this.f['tel'].value.length > 5) {
            if (this.f['tel'].value.substring(0, 5) == "+9474") {
                this.changePhoneNumberForm.get('tel').clearValidators();
                this.changePhoneNumberForm.controls['tel'].updateValueAndValidity();
            }
            else {
                this.changePhoneNumberForm.get('tel').setValidators([Validators.required]);
                this.changePhoneNumberForm.controls['tel'].updateValueAndValidity();
            }
        }
        else {
            this.changePhoneNumberForm.get('tel').clearValidators();
            this.changePhoneNumberForm.controls['tel'].updateValueAndValidity();
            this.changePhoneNumberForm.get('tel').setValidators([Validators.required]);
            this.changePhoneNumberForm.controls['tel'].updateValueAndValidity();
        }
    };
    ChangePhoneNumberComponent.prototype.changePhoneNUmber = function () {
        var _this = this;
        var phoneNumber = this.f['tel'].value;
        this.userDetailService.changeUserContactNumber(phoneNumber).subscribe(function (data) {
            if (data.code === 1 || data.code === '1') {
                _this.userDetailService.getUserDetails().subscribe(function (userData) {
                    if (userData !== null) {
                        localStorage.removeItem('user');
                        localStorage.setItem('user', JSON.stringify(userData));
                        _this.router.navigate(['/sms/verification']);
                    }
                });
            }
            else {
                _this.errorMessage = data.message;
            }
        }, function (httpError) {
        });
    };
    Object.defineProperty(ChangePhoneNumberComponent.prototype, "f", {
        get: function () { return this.changePhoneNumberForm.controls; },
        enumerable: true,
        configurable: true
    });
    return ChangePhoneNumberComponent;
}());
export { ChangePhoneNumberComponent };
