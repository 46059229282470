import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { UserAuthenticaionService } from 'src/app/user-authenticaion.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  fpemail: String = '';
  forgotPasswordEmailForm: FormGroup;
  errorMessage: String;

  constructor(formBuilder: FormBuilder, private applicationService: AppService, private router: Router) {
    this.forgotPasswordEmailForm = formBuilder.group({
      'fpemail': ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
  }

  get f() { return this.forgotPasswordEmailForm.controls; }

  submitEmail() {
    this.errorMessage = '';
    const email = this.f['fpemail'].value;
    this.applicationService.sendForgotPasswordEmail(email).subscribe(
      (data: any) => {
        if (data.code === 1 || data.code === '1') {
          localStorage.setItem('fpemail', email);
          this.router.navigate(['/verify/otp']);
        } else {
          this.errorMessage = data.message;
        }
      },
      (httpError: any) => {
        this.errorMessage = "Sorry, Something went wrong. Please try again later.";
      }
    );
  }

}
