import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
  selector: 'app-http-error-popup',
  templateUrl: './http-error-popup.component.html',
  styleUrls: ['./http-error-popup.component.scss']
})
export class HttpErrorPopupComponent implements OnInit, OnDestroy {
  httpErrorObject: any;
  constructor(
    public modalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.httpErrorObject = JSON.parse(sessionStorage.getItem('httpError'));
  }
  ngOnDestroy() {
    sessionStorage.removeItem('httpError');
    this.modalRef = null;
  }

  cancelPopup() {
    this.modalRef.hide();
    this.modalRef = null;
  }

}
