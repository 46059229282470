import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserSessionExpireComponent } from 'src/app/popup-modals/user-session-expire/user-session-expire.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-bootstrap/modal/bs-modal.service";
var UserAuthenticaionService = /** @class */ (function () {
    function UserAuthenticaionService(http, modalService) {
        this.http = http;
        this.modalService = modalService;
    }
    UserAuthenticaionService.prototype.checkUserAuthentication = function (email, password) {
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return this.http.post(environment.apiLink.concat('login'), {
            'email': email,
            'password': password
        }, { headers: headerData });
    };
    UserAuthenticaionService.prototype.subscriptionDetails = function (region) {
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return this.http.post(environment.apiLink.concat('home/subscription'), {
            'region': region,
        }, { headers: headerData });
    };
    UserAuthenticaionService.prototype.userGuidelinesDetails = function () {
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return this.http.post(environment.apiLink.concat('user-guidelines'), { headers: headerData });
    };
    UserAuthenticaionService.prototype.checkTokenisValid = function () {
        var authorizationValue = 'Bearer '.concat(localStorage.getItem('accessToken'));
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authorizationValue
        });
        return this.http.get(environment.apiLink.concat('user'), { headers: headerData });
    };
    UserAuthenticaionService.prototype.settingCjwToken = function (cjwToken) {
        var authorizationValue = 'Bearer '.concat(localStorage.getItem('accessToken'));
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authorizationValue
        });
        return this.http.post(environment.apiLink.concat('save/cjw/token'), {
            "cjw_token": cjwToken,
        }, { headers: headerData });
    };
    UserAuthenticaionService.prototype.registerUserData = function (user) {
        // environment.apiLink
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'X-CSRF-TOKEN': localStorage.getItem('csrfToken')
        });
        return this.http.post(environment.apiLink.concat('v2/register'), JSON.stringify(user), { headers: headerData });
    };
    UserAuthenticaionService.prototype.sendEmailVerification = function (pendingEmailId) {
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return this.http.post(environment.apiLink.concat('email/verfication'), {
            'pendingEmailId': pendingEmailId,
        }, { headers: headerData });
    };
    UserAuthenticaionService.prototype.phoneNumberValidating = function (phoneNumber) {
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return this.http.post(environment.apiLink.concat('phone-number/validation'), {
            "phoneNumber": phoneNumber.phoneNumber,
            "country": phoneNumber.country
        }, { headers: headerData });
    };
    UserAuthenticaionService.prototype.showUserSessionExpiaryPopup = function () {
        this.modalService.show(UserSessionExpireComponent);
    };
    UserAuthenticaionService.prototype.checkUserLoggedOrNot = function () {
        if (localStorage.getItem('accessToken') == null && localStorage.getItem('user') == null) {
            return false;
        }
        else {
            return true;
        }
    };
    UserAuthenticaionService.prototype.authhome = function () {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        // return this.http.post<any[]>(environment.hotelAuthapi.concat('/token/reference'), [], httpOptions);
        return this.http.post(environment.hotelApi.concat('/auth/token/reference'), [], httpOptions);
    };
    UserAuthenticaionService.ngInjectableDef = i0.defineInjectable({ factory: function UserAuthenticaionService_Factory() { return new UserAuthenticaionService(i0.inject(i1.HttpClient), i0.inject(i2.BsModalService)); }, token: UserAuthenticaionService, providedIn: "root" });
    return UserAuthenticaionService;
}());
export { UserAuthenticaionService };
