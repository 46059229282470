/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-confirm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./delete-confirm.component";
import * as i3 from "ngx-bootstrap/modal/bs-modal-ref.service";
import * as i4 from "../../service/com-parent-child.service";
var styles_DeleteConfirmComponent = [i0.styles];
var RenderType_DeleteConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteConfirmComponent, data: {} });
export { RenderType_DeleteConfirmComponent as RenderType_DeleteConfirmComponent };
export function View_DeleteConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete Confirmation"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "center", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to proceed?"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-danger btn-flat"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0\u00A0 "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-secondary btn-flat"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No"]))], null, null); }
export function View_DeleteConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-confirm", [], null, null, null, View_DeleteConfirmComponent_0, RenderType_DeleteConfirmComponent)), i1.ɵdid(1, 114688, null, 0, i2.DeleteConfirmComponent, [i3.BsModalRef, i4.ComParentChildService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteConfirmComponentNgFactory = i1.ɵccf("app-delete-confirm", i2.DeleteConfirmComponent, View_DeleteConfirmComponent_Host_0, {}, {}, []);
export { DeleteConfirmComponentNgFactory as DeleteConfirmComponentNgFactory };
