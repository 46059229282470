import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserAuthenticaionService } from '../user-authenticaion.service';

@Component({
  selector: 'app-user-guidelines',
  templateUrl: './user-guidelines.component.html',
  styleUrls: ['./user-guidelines.component.scss']
})
export class UserGuidelinesComponent implements OnInit {
  loading = false;
  userAuthService: UserAuthenticaionService;
  userGuidelines = [];

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    // this.checkTokenIsValid();
    this.getUserGuidelinesDetails();
  }

  /**
   * This will get the available user guidelines
   */
   getUserGuidelinesDetails(){
    this.loading = true;
    this.userGuidelinesDetails().subscribe(
      (res: any) => {
        this.loading = false;
        if(res.code === 1){
          this.userGuidelines = res.other;
        } 
      },
      (errorEwaponse: any) => {
      }
    ); 
  }

  userGuidelinesDetails() {
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.get(
      environment.apiLink.concat('user-guidelines/home'),
      { headers: headerData });
  }

  checkTokenIsValid(){
    this.userAuthService.checkTokenisValid().subscribe(
      (res: any) => {}
    );    
  }

}
