import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-forget-password-email-verification',
  templateUrl: './forget-password-email-verification.component.html',
  styleUrls: ['./forget-password-email-verification.component.scss']
})
export class ForgetPasswordEmailVerificationComponent implements OnInit {
  otp: String = '';
  forgotPasswordEmailVerifyForm: FormGroup;
  errorMessage: String = "";
  constructor(formBuilder: FormBuilder, private applicationService: AppService, private router: Router) {
    this.forgotPasswordEmailVerifyForm = formBuilder.group({
      'otp': ['', [Validators.required]],
    });
  }

  ngOnInit() {
  }
  get f() { return this.forgotPasswordEmailVerifyForm.controls; }

  submitOtp() {
    this.errorMessage = '';
    const otp = this.f['otp'].value;
    const email = localStorage.getItem('fpemail');
    var requestData = {
      "otp":otp,
      "email":email
    }
    this.applicationService.sendOtpVerifiication(requestData).subscribe(
      (data: any) => {
        if (data.code === 1 || data.code === '1') {
          localStorage.setItem('fotp', otp);
          this.router.navigate(['/reset/password']);
        } else {
          this.errorMessage = data.message;
        }
      },
      (httpError: any) => {
        this.errorMessage = "Sorry, Something went wrong. Please try again later.";
      }
    );
  }
}
