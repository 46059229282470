<app-header></app-header>
<ng-http-loader backgroundColor="#F7721E" spinner="sk-wave"></ng-http-loader>

<style type="text/css">
  .body {
    height: 100%;
    position: relative;
    position: relative;
    padding-bottom: 6rem;
    min-height: 687px;
  }

  .wrapper {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 85px;
  }
</style>
<div class="body">
  <div class="wrapper">
    <div class="col-md-12 top-header">
      <h3>User Guidelines</h3>
    </div>
    <div class="content-wrapper" style="min-height: 487px; margin-bottom: 50px;">
      <div class="container-fluid">
        <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
          <div class="panel-body boxshadow">
            <div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div *ngFor="let item of userGuidelines" class="mb-3">
                  <p style="font-weight:600;">{{item.title}}</p>
                  <p style="white-space: pre-wrap;">{{item.description}}</p>
                  <p style="font-size: 10px;">Last Updated: {{item.updated_at | date: 'dd-MM-yyyy'}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>