import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';

@Component({
  selector: 'app-timer-expiary',
  templateUrl: './timer-expiary.component.html',
  styleUrls: ['./timer-expiary.component.scss']
})
export class TimerExpiaryComponent implements OnInit {

  constructor(
    public modalRef: BsModalRef,
    private comparentchildservice: ComParentChildService
  ) { }

  ngOnInit() {
  }

  cancelPopup() {
    this.modalRef.hide();
    this.modalRef = null;
  }
}
