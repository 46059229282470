import { OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';
var TimerExpiaryComponent = /** @class */ (function () {
    function TimerExpiaryComponent(modalRef, comparentchildservice) {
        this.modalRef = modalRef;
        this.comparentchildservice = comparentchildservice;
    }
    TimerExpiaryComponent.prototype.ngOnInit = function () {
    };
    TimerExpiaryComponent.prototype.cancelPopup = function () {
        this.modalRef.hide();
        this.modalRef = null;
    };
    return TimerExpiaryComponent;
}());
export { TimerExpiaryComponent };
