/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-updated-password-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/angularx-qrcode/angularx-qrcode.ngfactory";
import * as i3 from "angularx-qrcode";
import * as i4 from "@angular/common";
import * as i5 from "./show-updated-password-popup.component";
import * as i6 from "ngx-bootstrap/modal/bs-modal-ref.service";
var styles_ShowUpdatedPasswordPopupComponent = [i0.styles];
var RenderType_ShowUpdatedPasswordPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowUpdatedPasswordPopupComponent, data: {} });
export { RenderType_ShowUpdatedPasswordPopupComponent as RenderType_ShowUpdatedPasswordPopupComponent };
function View_ShowUpdatedPasswordPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "center", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Username: ", ""])), (_l()(), i1.ɵted(-1, null, [" \u00A0\u00A0 "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Password: ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["id", "qrcode_div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "qrcode", [["cssClass", "coolQRCode"]], null, null, null, i2.View_QRCodeComponent_0, i2.RenderType_QRCodeComponent)), i1.ɵdid(11, 573440, null, 0, i3.QRCodeComponent, [i1.Renderer2], { qrdata: [0, "qrdata"], cssClass: [1, "cssClass"], errorCorrectionLevel: [2, "errorCorrectionLevel"], width: [3, "width"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.qrCodeValue; var currVal_3 = "coolQRCode"; var currVal_4 = "M"; var currVal_5 = 200; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataArray[0]; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.dataArray[1]; _ck(_v, 7, 0, currVal_1); }); }
export function View_ShowUpdatedPasswordPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "modal-dialog modal-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Updated User Details"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-body"], ["style", "background-color: #f7f7f7;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowUpdatedPasswordPopupComponent_1)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.qrCodeValue; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_ShowUpdatedPasswordPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-show-updated-password-popup", [], null, null, null, View_ShowUpdatedPasswordPopupComponent_0, RenderType_ShowUpdatedPasswordPopupComponent)), i1.ɵdid(1, 114688, null, 0, i5.ShowUpdatedPasswordPopupComponent, [i6.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShowUpdatedPasswordPopupComponentNgFactory = i1.ɵccf("app-show-updated-password-popup", i5.ShowUpdatedPasswordPopupComponent, View_ShowUpdatedPasswordPopupComponent_Host_0, {}, {}, []);
export { ShowUpdatedPasswordPopupComponentNgFactory as ShowUpdatedPasswordPopupComponentNgFactory };
