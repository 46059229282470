import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthenticaionService } from '../user-authenticaion.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { redirectUrls } from '../helpers/redirect-urls';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  post: any;
  email: String = '';
  password: String = '';
  submitted = false;
  userAuthService: UserAuthenticaionService;
  isLoginFailed = false;
  errorMessage: String = '';
  successMessage: String = '';

  constructor(formBuilder: FormBuilder, userAuthService: UserAuthenticaionService,
    private router: Router, private route: ActivatedRoute) {
    this.userAuthService = userAuthService;
    this.loginForm = formBuilder.group({
      'email': ['', [Validators.required, this.emailWithWhitespaceValidator]],
      'password': ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  emailWithWhitespaceValidator(control: FormControl) {
    const isValid = control.value
      .trim()
      .match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
    return isValid ? null : {
      isValid: false
    };
  } 

ngOnInit() {
    // if (localStorage.getItem('accessToken') != null && localStorage.getItem('user')) {
    //   let data = localStorage.getItem('accessToken');
    //   const url = redirectUrls.redirectWelcome+'?token=' + data.accessToken;
    // }


    this.route.queryParams.subscribe(
      params => {
        if (params.err === 0 || params.err === '0') {
          this.successMessage = params.message;
          this.errorMessage = null;
        } else {
          this.errorMessage = params.message;
          this.successMessage = null;
        }
      }
    );
    if (localStorage.getItem('registrationMessage')) {
      this.successMessage = localStorage.getItem('registrationMessage');
      this.errorMessage = "";
      localStorage.removeItem('registrationMessage');
    }

    localStorage.clear();
    sessionStorage.clear();
  }
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.userAuthService.checkUserAuthentication(this.f['email'].value.trim(), this.f['password'].value).subscribe(
      (data: any) => {
        if (data.hasOwnProperty('accessToken')) {
          localStorage.clear();
          sessionStorage.clear();
          const oldPhoneDetails = data.oldPhoneData;
          localStorage.setItem('accessToken', data.accessToken);
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('oldPhoneData', JSON.stringify(data.oldPhoneData));
          var reqData = {
            "token":data.accessToken,
            "email":data.user.email,
            "mobile":data.user.mobile_number
          }
          localStorage.setItem('userData', JSON.stringify(reqData));
          const url = redirectUrls.redirectWelcome+'?data=' + btoa(JSON.stringify(reqData));
          window.location.href = url;
          // if (data.user.phone_verify_status == 1 || data.user.phone_verify_status == 2) {
            // this.router.navigate(['/dashboard']);
            // const url = 'http://tguiv3.enabledbeings.com/account/welcome?token=' + data.accessToken;
            // const url = 'https://tguiv3.enabledbeings.com/account/welcome?token=' + data.accessToken;
            // const url = 'http://localhost:4200/account/welcome?token=' + data.accessToken;
            // var reqData = {
            //   "token":data.accessToken,
            //   "email":this.f['reg_email'].value,
            //   "mobile":this.f['reg_contact_number'].value
            // }
            // const url = redirectUrls.redirectWelcome+'?data=' + btoa(JSON.stringify(reqData));
            // window.location.href = url;
            // const url = redirectUrls.redirectWelcome+'?token=' + data.accessToken;
            // window.location.href = url;
          // } else if (data.user.phone_verify_status == 0 && oldPhoneDetails !== null) {
          //   this.router.navigate(['/verify/old-new']);
          // } else {
          //   this.router.navigate(['/sms/verification']);
          // }
        } else {
          this.isLoginFailed = true;
          this.errorMessage = data.message;
          this.successMessage = "";
        }
      },
      (errorEwaponse: any) => {
        this.isLoginFailed = true;
      }
    );
  }
  closeErrorAlert() {
    this.errorMessage = null;
  }
  closeSuccessMessage() {
    this.successMessage = null;
  }

}
