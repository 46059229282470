<app-header></app-header>
<ng-http-loader backgroundColor="#F7721E" spinner="sk-wave"></ng-http-loader>

<style type="text/css">
  .body {
    height: 100%;
    position: relative;
    position: relative;
    padding-bottom: 6rem;
    min-height: 687px;
  }

  .wrapper {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 85px;
  }
</style>
<div class="body">
  <div class="wrapper">
    <div class="col-md-12 top-header">
      <h3>Change Mobile Number</h3>
    </div>
    <div class="content-wrapper" style="min-height: 487px;">
      <div id="login-interface">
        <div class="no-padding col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
          <div class="panel-body boxshadow" style="margin-top: 52px;">
            <div class="box box-default">
              <div class="box-body">
                <div class="alert alert-success alert-dismissible" [hidden]="!successMessage">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true" >&times;</button>
                  {{ successMessage }}
                </div>
                <div class="alert alert-danger alert-dismissible" [hidden]="!errorMessage">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true" >&times;</button>
                  {{ errorMessage }}
                </div>
                <div class="alert alert-warning alert-dismissible" [hidden]="!warningMessage">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                  Warning Message
                </div>
                <form class="form-horizontal" [formGroup]='changePhoneNumberForm' (ngSubmit)="f.form.valid && checkPhoneNumberValid()" #f="ngForm"
                  novalidate>
                  <div class="col-md-12">
                    <!-- <div class="form-group" id="tel_div">
                      <label class="control-label">Mobile Number</label>
                      <input type="tel" class="form-control" formControlName="tel" [ngClass]="{ 'error-border': f.submitted && changePhoneNumberForm.controls['tel'].invalid }"
                        placeholder="Enter Mobile Number " maxlength="15 " value="">
                      <p id="p_tel_error" class="text-danger"></p>
                    </div> -->
                    <div class="form-group">
                      <label class="control-label">Mobile Number</label>
                      <international-phone-number #phoneNumberInput (keyup)="onKey($event)" placeholder="Enter phone number" [minlength]="10" [maxlength]="15" [defaultCountry]="'lk'" [required]="false"
                        formControlName="tel" [ngClass]="{ 'intlContactNumber': (f.submitted && changePhoneNumberForm.controls['tel'].invalid)||errorContactNumber }"></international-phone-number>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <a href="" class="btn btn-primary btn-block">Back </a>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <button type="submit" id="update" name="update" class="btn btn-primary btn-block" value="update">Update and Send Code</button>
                    </div>
                  </div>
                  <input type="hidden" name="tel2" id="tel2">
                </form>
              </div>
            </div>
            <!-- /.box -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>