import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { User } from 'src/models/user';
import { environment } from 'src/environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserSessionExpireComponent } from 'src/app/popup-modals/user-session-expire/user-session-expire.component';



@Injectable({
  providedIn: 'root'
})
export class UserAuthenticaionService {

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
  ) { }

  checkUserAuthentication(email, password) {
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(
      environment.apiLink.concat('login'),
      {
        'email': email,
        'password': password
      },
      { headers: headerData });
  }

  subscriptionDetails(region) {
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(
      environment.apiLink.concat('home/subscription'),
      {
        'region': region,
      },
      { headers: headerData });
  }

  userGuidelinesDetails() {
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(
      environment.apiLink.concat('user-guidelines'),
      { headers: headerData });
  }

  checkTokenisValid(){
    const authorizationValue = 'Bearer '.concat(localStorage.getItem('accessToken'));
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization':authorizationValue
    });
    return this.http.get(environment.apiLink.concat('user'),
      { headers: headerData });
  }

  settingCjwToken(cjwToken){
    const authorizationValue = 'Bearer '.concat(localStorage.getItem('accessToken'));
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization':authorizationValue
    });
    return this.http.post(environment.apiLink.concat('save/cjw/token'),
      {
        "cjw_token":cjwToken,
      },
    { headers: headerData });
  }    

  registerUserData(user: User) {
    // environment.apiLink
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-TOKEN': localStorage.getItem('csrfToken')
    });
    return this.http.post(
      environment.apiLink.concat('v2/register'),
      JSON.stringify(user),
      { headers: headerData }
    );
  }

  sendEmailVerification(pendingEmailId) {
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(environment.apiLink.concat('email/verfication'),
      {
        'pendingEmailId': pendingEmailId,
      }
      , { headers: headerData });
  }

  phoneNumberValidating(phoneNumber) {
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(environment.apiLink.concat('phone-number/validation'),
      {
        "phoneNumber": phoneNumber.phoneNumber,
        "country": phoneNumber.country
      }
      , { headers: headerData });
  }


  showUserSessionExpiaryPopup() {
    this.modalService.show(UserSessionExpireComponent);
  }

  checkUserLoggedOrNot() {
    if (localStorage.getItem('accessToken') == null && localStorage.getItem('user') == null) {
      return false;
    } else {
      return true;
    }
  }


  authhome(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    // return this.http.post<any[]>(environment.hotelAuthapi.concat('/token/reference'), [], httpOptions);
    return this.http.post<any[]>(environment.hotelApi.concat('/auth/token/reference'), [], httpOptions);
  }

}
