<div class="modal-header">
  <h4 class="modal-title pull-left">Confirm Search</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <center>
    <div class="row">Please note that you will not be able to issue the ticket(s) unless you have removed all filed fares and exited/ignored
      (Eg: In Galileo type 'I' and enter) from all GDS terminals.</div>
    <br />
    <div class="row">
      <button (click)="confirmRetrieve()" class="btn btn-success btn-flat">I have done the needful</button>&nbsp;&nbsp;&nbsp;
      <button class="btn btn-primary btn-flat" (click)="cancelPopup()">I will do the needful and try again later</button>
    </div>
  </center>
</div>