import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
var ForgetPasswordComponent = /** @class */ (function () {
    function ForgetPasswordComponent(formBuilder, applicationService, router) {
        this.applicationService = applicationService;
        this.router = router;
        this.fpemail = '';
        this.forgotPasswordEmailForm = formBuilder.group({
            'fpemail': ['', [Validators.required, Validators.email]],
        });
    }
    ForgetPasswordComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(ForgetPasswordComponent.prototype, "f", {
        get: function () { return this.forgotPasswordEmailForm.controls; },
        enumerable: true,
        configurable: true
    });
    ForgetPasswordComponent.prototype.submitEmail = function () {
        var _this = this;
        this.errorMessage = '';
        var email = this.f['fpemail'].value;
        this.applicationService.sendForgotPasswordEmail(email).subscribe(function (data) {
            if (data.code === 1 || data.code === '1') {
                localStorage.setItem('fpemail', email);
                _this.router.navigate(['/verify/otp']);
            }
            else {
                _this.errorMessage = data.message;
            }
        }, function (httpError) {
            _this.errorMessage = "Sorry, Something went wrong. Please try again later.";
        });
    };
    return ForgetPasswordComponent;
}());
export { ForgetPasswordComponent };
