import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CountdownComponent } from 'ngx-countdown';
import { UserAuthenticaionService } from 'src/app/user-authenticaion.service';
import { UserDetailsService } from 'src/app/functionalservices/user-details.service';
import { redirectUrls } from '../helpers/redirect-urls';
var PhoneNumberVerificationComponent = /** @class */ (function () {
    function PhoneNumberVerificationComponent(formBuilder, applicationService, router, route, userDetailService, authService) {
        this.router = router;
        this.route = route;
        this.userDetailService = userDetailService;
        this.authService = authService;
        this.errorContactNumber = false;
        var loggedUser = JSON.parse(localStorage.getItem('user'));
        this.loggedUserId = loggedUser.id;
        this.mobileNumber = loggedUser.mobile_number;
        this.appService = applicationService;
        this.disableResend = false;
        this.smsVerficationForm = formBuilder.group({
            'code': ['', [Validators.required]],
            'tel': []
        });
        this.smsVerficationForm.controls['tel'].setValue(this.mobileNumber);
    }
    PhoneNumberVerificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appService.getResendRemainingTimer().subscribe(function (data) {
            if (data.code === 1 || data.code === '1') {
                _this.remainingTime = data.remainingSeconds;
                _this.disableResend = true;
            }
            else {
                _this.disableResend = false;
            }
        }, function (errorResponse) {
        });
        this.resendeSMSCode();
    };
    Object.defineProperty(PhoneNumberVerificationComponent.prototype, "f", {
        get: function () { return this.smsVerficationForm.controls; },
        enumerable: true,
        configurable: true
    });
    /**
     * This will check if phone number is valid or not
     */
    PhoneNumberVerificationComponent.prototype.checkPhoneNumberValid = function () {
        var _this = this;
        var data = {
            "phoneNumber": this.smsVerficationForm.controls['tel'].value,
            "country": this.phoneNumberInput['selectedCountry']['countryCode']
        };
        this.authService.phoneNumberValidating(data).subscribe(function (data) {
            if (data.code == 1) {
                _this.verifySMSCode();
            }
            else {
                _this.errorContactNumber = true;
            }
        }, function (htError) {
        });
    };
    PhoneNumberVerificationComponent.prototype.verifySMSCode = function () {
        var _this = this;
        // tslint:disable-next-line:label-position
        var mode = 'verify';
        this.appService.verifySmsCode(mode, this.f['code'].value).subscribe(function (data) {
            if (data.code === 1) {
                _this.userDetailService.getUserDetails().subscribe(function (userData) {
                    if (userData !== null) {
                        localStorage.removeItem('user');
                        localStorage.setItem('user', JSON.stringify(userData));
                        var token = localStorage.getItem('accessToken');
                        var url = redirectUrls.redirectWelcome + '?token=' + token;
                        window.location.href = url;
                    }
                });
            }
            else {
                _this.errorMessage = data.message;
                _this.successMessage = null;
            }
        }, function (errorResponse) {
        });
    };
    PhoneNumberVerificationComponent.prototype.resendeSMSCode = function () {
        var _this = this;
        this.successMessage = null;
        this.errorMessage = null;
        this.disableResend = true;
        this.appService.resendSMSVerification().subscribe(function (data) {
            if (data.code === 1) {
                _this.successMessage = data.message;
                _this.errorMessage = null;
                _this.remainingTime = 120;
            }
            else {
                _this.errorMessage = data.message;
                _this.successMessage = null;
            }
        }, function (errorResponse) {
            _this.disableResend = false;
        });
    };
    PhoneNumberVerificationComponent.prototype.skipVerfication = function () {
        var mode = 'skip';
        this.appService.skipSMSVerification(mode).subscribe(function (data) {
            if (data.code === 1) {
                localStorage.setItem('user', JSON.stringify(data.updatedUserData));
                var token = localStorage.getItem('accessToken');
                var url = redirectUrls.redirectWelcome + '?token=' + token;
                window.location.href = url;
            }
        }, function (errorResponse) {
        });
    };
    PhoneNumberVerificationComponent.prototype.closeErrorAlert = function () {
        this.errorMessage = null;
    };
    PhoneNumberVerificationComponent.prototype.closeSuccessMessage = function () {
        this.successMessage = null;
    };
    PhoneNumberVerificationComponent.prototype.finshedTimer = function () {
        this.disableResend = false;
    };
    PhoneNumberVerificationComponent.prototype.onKey = function (e) {
        this.errorContactNumber = false;
        if (this.f['tel'].value.length > 5) {
            if (this.f['tel'].value.substring(0, 5) == "+9474") {
                this.smsVerficationForm.get('tel').clearValidators();
                this.smsVerficationForm.controls['tel'].updateValueAndValidity();
            }
            else {
                this.smsVerficationForm.get('tel').setValidators([Validators.required]);
                this.smsVerficationForm.controls['tel'].updateValueAndValidity();
            }
        }
        else {
            this.smsVerficationForm.get('tel').clearValidators();
            this.smsVerficationForm.controls['tel'].updateValueAndValidity();
            this.smsVerficationForm.get('tel').setValidators([Validators.required]);
            this.smsVerficationForm.controls['tel'].updateValueAndValidity();
        }
    };
    return PhoneNumberVerificationComponent;
}());
export { PhoneNumberVerificationComponent };
