<app-header></app-header>
<ng-http-loader backgroundColor="#F7721E" spinner="sk-wave"></ng-http-loader>

<style type="text/css">
  .body {
    height: 100%;
    position: relative;
    position: relative;
    padding-bottom: 6rem;
    min-height: 687px;
  }

  .wrapper {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 85px;
  }
</style>
<div class="body">
  <div class="wrapper">
    <div class="col-md-12 top-header">
      <h3>Forgot Password</h3>
    </div>
    <div class="content-wrapper" style="min-height: 487px;">
      <div class="container-fluid">
        <div id="login-interface">

          <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
            <div class="panel-body boxshadow" style="padding-bottom: 50px;">
              <div class="box box-default">
                <div class="box-body">

                  <div id="error-messages" class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [hidden]="!errorMessage">
                    <div class="alert alert-danger alert-dismissible">
                      <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                      <p class="erMessage">{{ errorMessage }}</p>
                    </div>
                  </div>

                  <!-- form start -->
                  <form [formGroup]='forgotPasswordEmailForm' (ngSubmit)="f.form.valid && submitEmail()" #f="ngForm" novalidate>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Please enter your email address below. You will receive an otp to reset your password.</label>
                        <input class="form-control" formControlName="fpemail" type="email" placeholder="Enter Email Address" autocomplete="off" [ngClass]="{ 'error-border': f.submitted && forgotPasswordEmailForm.controls['fpemail'].invalid }"
                          required/>
                        <p class="text-danger hidden" id="pErrMsg"></p>
                      </div>
                      <button id="reg_btnAdd" type="submit" class="btn btn-primary next pull-right">Submit</button>
                    </div>
                  </form>
                </div>
                <!-- /.box-body -->
              </div>
              <!-- /.box -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>