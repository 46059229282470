import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
// import { AuthService } from './auth.service';
var HttpErrorInterceptor = /** @class */ (function () {
    function HttpErrorInterceptor() {
    }
    HttpErrorInterceptor.prototype.intercept = function (request, next) {
        return next.handle(request)
            .pipe(retry(0), catchError(function (error) {
            console.log(error.status);
            var errorMessage = "";
            if (error.status == 401) {
                if (error.error[0] == "UNAUTHORIZED") {
                    window.location.href = "/login";
                }
                else {
                    localStorage.removeItem("loggedDataCJW");
                    console.log("reloading");
                    window.location.href = "flight/search/engine";
                }
            }
            return throwError(errorMessage);
        }));
    };
    return HttpErrorInterceptor;
}());
export { HttpErrorInterceptor };
