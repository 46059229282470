import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';

@Component({
  selector: 'app-confirm-retrieve',
  templateUrl: './confirm-retrieve.component.html',
  styleUrls: ['./confirm-retrieve.component.scss']
})
export class ConfirmRetrieveComponent implements OnInit {

  constructor(
    public modalRef: BsModalRef,
    private comparentchildservice: ComParentChildService
  ) { }

  ngOnInit() {

  }
  confirmRetrieve() {
    this.comparentchildservice.publish('confirm-retrieve-popup');
    this.cancelPopup();
  }
  cancelPopup() {
    this.modalRef.hide();
    this.modalRef = null;
  }
}
