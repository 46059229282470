import { throwError as observableThrowError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AppService = /** @class */ (function () {
    function AppService(http) {
        this.http = http;
        this.level1 = '';
        this.level2 = '';
        this.level3 = '';
        this.level4 = '';
    }
    AppService.prototype.getXCRFToken = function () {
        var headerData = this.getHeaderBeforeLogged();
        return this.http.post(environment.apiLink.concat('csrf'), '');
    };
    AppService.prototype.getRegistrationData = function () {
        var headerData = this.getHeaderBeforeLogged();
        return this.http.post(environment.apiLink.concat('registration/data'), '', { headers: headerData });
    };
    AppService.prototype.extractData = function (res) {
        var body = res.json();
        return body || {};
    };
    AppService.prototype.handleErrorObservable = function (error) {
        console.error(error.message || error);
        return observableThrowError(error.message || error);
    };
    AppService.prototype.handleErrorPromise = function (error) {
        console.error(error.message || error);
        return Promise.reject(error.message || error);
    };
    AppService.prototype.setMenuBarLevels = function (level1, level2, level3) {
        this.level1 = level1;
        this.level2 = level2;
        this.level3 = level3;
    };
    AppService.prototype.getMenuBarLevel1 = function () {
        return this.level1;
    };
    AppService.prototype.getMenuBarLevel2 = function () {
        return this.level2;
    };
    AppService.prototype.getMenuBarLevel3 = function () {
        return this.level3;
    };
    AppService.prototype.verifySmsCode = function (mode, code) {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('verify/sms'), {
            '_mode': mode,
            'code': code
        }, { headers: headerData });
    };
    AppService.prototype.resendSMSVerification = function () {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('resend/sms'), {}, { headers: headerData });
    };
    AppService.prototype.skipSMSVerification = function (mode) {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('skip/sms/verification'), {
            '_mode': mode,
        }, { headers: headerData });
    };
    AppService.prototype.verifyBothSmsCode = function (mode, oldCode, newCode) {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('verify/sms'), {
            '_mode': mode,
            'old_number_code': oldCode,
            'new_number_code': newCode,
        }, { headers: headerData });
    };
    AppService.prototype.resendBothSMSVerification = function () {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('resend/old/new/sms'), {}, { headers: headerData });
    };
    // changePhoneNumber() {
    //   const headerData = new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/json'
    //   });
    //   return this.http.post(
    //     environment.apiLink.concat(''),
    //     {
    //       '_mode': mode,
    //       'code': code
    //     },
    //     { headers: headerData });
    // }
    AppService.prototype.getDashboardData = function () {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('dashboard/details'), {}, { headers: headerData });
    };
    AppService.prototype.getDashboardGraphData = function (optionValue) {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('issued/tickets/graph/data'), {
            'graph_option': optionValue
        }, { headers: headerData });
    };
    AppService.prototype.createNewSecondaryUser = function (username, email) {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('create/secondary/user'), {
            'username': username,
            'email': email
        }, { headers: headerData });
    };
    AppService.prototype.getSecondaryUserList = function () {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('secondary/user/list'), {}, { headers: headerData });
    };
    AppService.prototype.setNewPassword = function (userid) {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('update/secondar/user/password'), {
            "secondary_user_id": userid
        }, { headers: headerData });
    };
    AppService.prototype.checkRedisToken = function () {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('check/redis/user'), {}, { headers: headerData });
    };
    AppService.prototype.getHeaderAfterLogged = function () {
        var authorizationValue = 'Bearer '.concat(localStorage.getItem('accessToken'));
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': authorizationValue
        });
        return headerData;
    };
    AppService.prototype.getHeaderBeforeLogged = function () {
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return headerData;
    };
    AppService.prototype.sendPendingmail = function (pendingEmailId, endPoint) {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat(endPoint), {
            'pendingEmailId': pendingEmailId
        }, { headers: headerData });
    };
    AppService.prototype.getResendRemainingTimer = function () {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('resend/remaining/time'), {}, { headers: headerData });
    };
    AppService.prototype.sendForgotPasswordEmail = function (email) {
        return this.http.post(environment.apiLink.concat('user/forget-password'), {
            'email': email
        }, {});
    };
    AppService.prototype.sendOtpVerifiication = function (data) {
        return this.http.post(environment.apiLink.concat('user/otp-verification'), {
            'email': data.email,
            'otp': data.otp
        }, {});
    };
    AppService.prototype.resetForgotPassword = function (email, authCode, newPassword, confirmPassword, isClear) {
        return this.http.post(environment.apiLink.concat('user/reset-password'), {
            'email': email,
            'password': newPassword,
            'con_password': confirmPassword,
            'otp': authCode,
            'is_clear': isClear ? 1 : 0,
        }, {});
    };
    AppService.prototype.saveTopupRequest = function (fileToUpload, amount) {
        var authorizationValue = 'Bearer '.concat(localStorage.getItem('accessToken'));
        var headerData = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': authorizationValue
        });
        var formData = new FormData();
        if (fileToUpload) {
            formData.append('proofDoc', fileToUpload, fileToUpload.name);
        }
        formData.append('amount', amount);
        return this.http.post(environment.apiLink.concat('topup/request'), formData, { headers: headerData });
    };
    AppService.prototype.listTopupRequest = function () {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('latest/topup/requests'), {}, { headers: headerData });
    };
    AppService.prototype.proofAttachmentUrl = function (topupRequestId) {
        var headerData = this.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('get/proof/attachment/url'), { "topup_request_id": topupRequestId }, { headers: headerData });
    };
    AppService.ngInjectableDef = i0.defineInjectable({ factory: function AppService_Factory() { return new AppService(i0.inject(i1.HttpClient)); }, token: AppService, providedIn: "root" });
    return AppService;
}());
export { AppService };
