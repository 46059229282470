<div class="modal-dialog modal-md">
  <!-- Modal content-->
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="cancelPopup()">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 class="modal-title">Updated User Details</h4>
    </div>
    <div class="modal-body" style="background-color: #f7f7f7;">
      <div *ngIf="qrCodeValue">
        <center>
          <div>
            <span>Username: {{dataArray[0]}}</span> &nbsp;&nbsp;
            <span>Password: {{dataArray[1]}}</span>
          </div>
          <br>
          <div id="qrcode_div">
            <qrcode [qrdata]="qrCodeValue" [width]="200" [errorCorrectionLevel]="'M'" cssClass="coolQRCode"></qrcode>
          </div>
        </center>
      </div>
    </div>
  </div>
</div>