import { OnInit } from '@angular/core';
import { TicketManagerService } from 'src/app/functionalservices/ticket-manager.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';
var EmailEticketComponent = /** @class */ (function () {
    function EmailEticketComponent(ticketService, modalRef, comparentchildservice) {
        this.ticketService = ticketService;
        this.modalRef = modalRef;
        this.comparentchildservice = comparentchildservice;
    }
    EmailEticketComponent.prototype.ngOnInit = function () {
    };
    EmailEticketComponent.prototype.getTheme = function (theme) {
        this.theme = theme;
    };
    EmailEticketComponent.prototype.getShowPriceValues = function (showPrice) {
        this.showPriceValues = showPrice;
    };
    EmailEticketComponent.prototype.emailETicket = function () {
        var _this = this;
        var ticketNumber = sessionStorage.getItem('emailEticketNo');
        this.ticketService.emailEticket(ticketNumber, this.showPriceValues, this.theme).subscribe(function (data) {
            if (data.code === 1 || data.code === '1') {
                _this.comparentchildservice.publish('email-sent');
                _this.cancelPopup();
            }
        });
    };
    EmailEticketComponent.prototype.cancelPopup = function () {
        this.modalRef.hide();
        this.modalRef = null;
    };
    return EmailEticketComponent;
}());
export { EmailEticketComponent };
