export class User {
    reg_name: String;
    reg_company_name: String;
    reg_contact_number: String;
    reg_street1: String;
    reg_street2: String;
    reg_addresscity: String;
    reg_province: String;
    reg_addresspostalcode: String;
    reg_addresscountry: String;
    reg_website: String;
    reg_currency: String;
    reg_office_number: String;
    reg_work_email: String;
    reg_email: String;
    reg_password: String;
    confirmpassword: String;
    reg_user_profile: String;

    setName(name: String) {
        this.reg_name = name;
    }
    setCompanyName(companyName: String) {
        this.reg_company_name = companyName;
    }
    setUserProfile(reg_user_profile: String) {
        this.reg_user_profile = reg_user_profile;
    }
    setMobileNumber(mobileNumber: String) {
        this.reg_contact_number = mobileNumber;
    }
    setStreetAddress1(streetAddress1: String) {
        this.reg_street1 = streetAddress1;
    }
    setStreetAddress2(streetAddress2: String) {
        this.reg_street2 = streetAddress2;
    }
    setAddressCity(addressCity: String) {
        this.reg_addresscity = addressCity;
    }
    setProvince(province: String) {
        this.reg_province = province;
    }
    setAddressPostalCode(addressPostalCode: String) {
        this.reg_addresspostalcode = addressPostalCode;
    }
    setAddressCountry(addressCountry: String) {
        this.reg_addresscountry = addressCountry;
    }
    setWebsite(website: String) {
        this.reg_website = website;
    }
    setCurrency(currency: String) {
        this.reg_currency = currency;
    }
    setWorkNumber(workNumber: String) {
        this.reg_office_number = workNumber;
    }
    setWorkEmail(workEmail: String) {
        this.reg_work_email = workEmail;
    }
    setEmail(email: String) {
        this.reg_email = email;
    }
    setPassword(password: String) {
        this.reg_password = password;
    }
    setConfirmPassword(confirmPassword: String) {
        this.confirmpassword = confirmPassword;
    }
}
