import { Component, ViewContainerRef } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UserAuthenticaionService } from "./user-authenticaion.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Ticketgadget';
  constructor(
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    private authService:UserAuthenticaionService) {
    this.appService.setMenuBarLevels('free', '', '');
    if (!localStorage.getItem('csrfToken')) {
      this.getCSRFToken();
    }

    console.log(localStorage.getItem('authResult'));

    // if(localStorage.getItem('authResult') == null || localStorage.getItem('authResult') == undefined){
    //   this.getAuth();
    // }
    // else if(!(JSON.parse(localStorage.getItem('authResult'))).hasOwnProperty('accessToken')){
    //   this.getAuth();
    // } 
  }
  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      const scrollToTop = window.setInterval(() => {
        const pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 50); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }
  }
  getCSRFToken() {
    this.appService.getXCRFToken().subscribe(
      (data: any) => {
        if (data.code === 1) {
          localStorage.setItem('csrfToken', data.csrftoken);
        }
      }, (htError: HttpErrorResponse) => {

      });
  }

//   getAuth(): void {
// console.log('i am in auth');
//     this.authService.authhome()
//     .subscribe(data => {
//       console.log(data);
//       localStorage.setItem("authResult", JSON.stringify(data));
//     }
//     );
// }

}
