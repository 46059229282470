<div class="modal-header">
  <h4 class="modal-title pull-left">Void Confirmation</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <center>
    <div class="row">{{xmlString}}</div>
    <br />
    <div class="row">
      <button class="btn btn-primary btn-flat" (click)="cancelPopup()">Close</button>
    </div>
  </center>
</div>