<header id="header">
  <!-- End SmartBanner configuration -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">
  <div class="container d-flex">

    <div class="logo mr-auto">
      <!-- <h1 class="text-light"><a href="index.html"><span>Ticket Gadget</span></a></h1> -->
      <!-- Uncomment below if you prefer to use an image logo -->
      <a href="index.html">
        <img src="assets/img/50xticket-gadget.png" alt="" class="img-fluid" width="200">
      </a>
    </div>

    <nav class="nav-menu d-none d-lg-block">
      <ul>
        <li class="active">
          <a routerLink="/">Home</a>
        </li>
        <li>
          <a routerLink="/about" class="internal-cl">About</a>
        </li>
        <li>
          <a routerLink="/why" class="internal-cl">Why</a>
        </li>
        <li>
          <a routerLink="/contact" class="internal-cl">Contact</a>
        </li>
        <li>
          <a routerLink="/subscription" class="internal-cl">Subscriptions</a>
        </li>
        <li>
          <a routerLink="/register">Register</a>
        </li>
        <li>
          <a routerLink="/login">Login</a>
        </li>


      </ul>
    </nav>
    <!-- .nav-menu -->

  </div>
</header>
<noscript>
  <div class="row" id="DivJSWarnignMsg" style="padding-top: 10px;">
    <div class="col-md-offset-4 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <p class="alert alert-danger">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> We have detected that JavaScript is not enabled on your browser. Kindly enable JavaScript in order to proceed.
      </p>
    </div>
  </div>
</noscript>