
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  level1 = '';
  level2 = '';
  level3 = '';
  level4 = '';

  constructor(private http: HttpClient) { }

  getXCRFToken() {
    const headerData = this.getHeaderBeforeLogged();
    return this.http.post(environment.apiLink.concat('csrf'), '');
  }

  getRegistrationData() {
    const headerData = this.getHeaderBeforeLogged();
    return this.http.post(environment.apiLink.concat('registration/data'), '', { headers: headerData });
  }

  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }
  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }
  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  setMenuBarLevels(level1, level2, level3) {
    this.level1 = level1;
    this.level2 = level2;
    this.level3 = level3;
  }
  getMenuBarLevel1() {
    return this.level1;
  }
  getMenuBarLevel2() {
    return this.level2;
  }
  getMenuBarLevel3() {
    return this.level3;
  }

  verifySmsCode(mode, code) {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('verify/sms'),
      {
        '_mode': mode,
        'code': code
      },
      { headers: headerData });
  }
  resendSMSVerification() {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('resend/sms'),
      {},
      { headers: headerData });
  }

  skipSMSVerification(mode) {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('skip/sms/verification'),
      {
        '_mode': mode,
      },
      { headers: headerData });
  }

  verifyBothSmsCode(mode, oldCode, newCode) {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('verify/sms'),
      {
        '_mode': mode,
        'old_number_code': oldCode,
        'new_number_code': newCode,
      },
      { headers: headerData });
  }

  resendBothSMSVerification() {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('resend/old/new/sms'),
      {},
      { headers: headerData });
  }
  // changePhoneNumber() {
  //   const headerData = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Accept': 'application/json'
  //   });
  //   return this.http.post(
  //     environment.apiLink.concat(''),
  //     {
  //       '_mode': mode,
  //       'code': code
  //     },
  //     { headers: headerData });
  // }

  getDashboardData() {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('dashboard/details'), {},
      { headers: headerData });
  }
  getDashboardGraphData(optionValue) {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('issued/tickets/graph/data'),
      {
        'graph_option': optionValue
      },
      { headers: headerData });
  }

  createNewSecondaryUser(username, email) {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('create/secondary/user'),
      {
        'username': username,
        'email': email
      },
      { headers: headerData });
  }

  getSecondaryUserList() {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('secondary/user/list'),
      {},
      { headers: headerData });
  }

  setNewPassword(userid) {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('update/secondar/user/password'),
      {
        "secondary_user_id": userid
      },
      { headers: headerData });
  }

  checkRedisToken() {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('check/redis/user'),
      {},
      { headers: headerData });
  }

  getHeaderAfterLogged() {
    const authorizationValue = 'Bearer '.concat(localStorage.getItem('accessToken'));
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': authorizationValue
    });
    return headerData;
  }
  getHeaderBeforeLogged() {
    const headerData = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return headerData;
  }

  sendPendingmail(pendingEmailId, endPoint) {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat(endPoint),
      {
        'pendingEmailId': pendingEmailId
      },
      { headers: headerData }
    );
  }

  getResendRemainingTimer() {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('resend/remaining/time'),
      {},
      { headers: headerData });
  }

  sendForgotPasswordEmail(email) {
    return this.http.post(
      environment.apiLink.concat('user/forget-password'),
      {
        'email': email
      },
      {}
    );
  }

  sendOtpVerifiication(data) {
    return this.http.post(
      environment.apiLink.concat('user/otp-verification'),
      {
        'email': data.email,
        'otp':data.otp
      },
      {}
    );
  }

  resetForgotPassword(email,authCode, newPassword, confirmPassword, isClear) {
    return this.http.post(
      environment.apiLink.concat('user/reset-password'),
      {
        'email': email,
        'password': newPassword,
        'con_password': confirmPassword,
        'otp':authCode,
        'is_clear':isClear?1:0,
      },
      {}
    );
  }

  saveTopupRequest(fileToUpload: File, amount) {
    const authorizationValue = 'Bearer '.concat(localStorage.getItem('accessToken'));
    const headerData = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': authorizationValue
    });
    const formData: FormData = new FormData();
    if (fileToUpload) {
      formData.append('proofDoc', fileToUpload, fileToUpload.name);
    }
    formData.append('amount', amount);
    return this.http.post(
      environment.apiLink.concat('topup/request'),
      formData,
      { headers: headerData });
  }

  listTopupRequest() {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('latest/topup/requests'),
      {},
      { headers: headerData });
  }

  proofAttachmentUrl(topupRequestId) {
    const headerData = this.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('get/proof/attachment/url'),
      { "topup_request_id": topupRequestId },
      { headers: headerData });
  }

}
