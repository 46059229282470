/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-eticket.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./email-eticket.component";
import * as i3 from "../../functionalservices/ticket-manager.service";
import * as i4 from "ngx-bootstrap/modal/bs-modal-ref.service";
import * as i5 from "../../service/com-parent-child.service";
var styles_EmailEticketComponent = [i0.styles];
var RenderType_EmailEticketComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailEticketComponent, data: {} });
export { RenderType_EmailEticketComponent as RenderType_EmailEticketComponent };
export function View_EmailEticketComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customize E-Ticket & Email"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 32, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 24, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "col-md-4"], ["ng-init", "theme = 1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Theme"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "input", [["id", "radioButtonThemeBNW"], ["name", "radiotheme"], ["type", "radio"], ["value", "1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getTheme("blackandwhite") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Black & White "])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "input", [["name", "radiotheme"], ["type", "radio"], ["value", "2"]], [[8, "checked", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getTheme("colored") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Coloured "])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 11, "div", [["class", "col-md-8"], ["ng-init", "showprice = 1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Do you want to show price details in the ticket?"])), (_l()(), i1.ɵeld(23, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "input", [["id", "radioButtonPriceShow"], ["name", "radioshowprice"], ["type", "radio"], ["value", "1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getShowPriceValues("true") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Yes "])), (_l()(), i1.ɵeld(27, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 0, "input", [["name", "radioshowprice"], ["type", "radio"], ["value", "2"]], [[8, "checked", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getShowPriceValues("false") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" No "])), (_l()(), i1.ɵeld(31, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 6, "div", [["class", "btn-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emailETicket() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "button", [["class", "btn btn-primary btn-flat"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Submit"])), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0\u00A0 "])), (_l()(), i1.ɵeld(37, 0, null, null, 1, "button", [["class", "btn btn-default btn-flat"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 17, 0, currVal_0); var currVal_1 = true; _ck(_v, 29, 0, currVal_1); }); }
export function View_EmailEticketComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-email-eticket", [], null, null, null, View_EmailEticketComponent_0, RenderType_EmailEticketComponent)), i1.ɵdid(1, 114688, null, 0, i2.EmailEticketComponent, [i3.TicketManagerService, i4.BsModalRef, i5.ComParentChildService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailEticketComponentNgFactory = i1.ɵccf("app-email-eticket", i2.EmailEticketComponent, View_EmailEticketComponent_Host_0, {}, {}, []);
export { EmailEticketComponentNgFactory as EmailEticketComponentNgFactory };
