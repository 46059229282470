import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CountdownComponent } from 'ngx-countdown';
import { UserAuthenticaionService } from 'src/app/user-authenticaion.service';
import { UserDetailsService } from 'src/app/functionalservices/user-details.service';
import { redirectUrls } from '../helpers/redirect-urls';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-phone-number-verification',
  templateUrl: './phone-number-verification.component.html',
  styleUrls: ['./phone-number-verification.component.scss']
})
export class PhoneNumberVerificationComponent implements OnInit {
  @ViewChild(CountdownComponent) counter: CountdownComponent;

  smsVerficationForm: FormGroup;
  loggedUserId: String;
  mobileNumber: String;
  successMessage: String;
  errorMessage: String;
  warningMessage: String;
  infoMessage: String;
  appService: AppService;
  code: String;
  tel: String;
  remainingTime: number;
  disableResend: any;
  errorContactNumber = false;
  
  constructor(
    formBuilder: FormBuilder,
    applicationService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private userDetailService: UserDetailsService,
    private authService: UserAuthenticaionService,
  ) {
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    this.loggedUserId = loggedUser.id;
    this.mobileNumber = loggedUser.mobile_number;
    this.appService = applicationService;
    this.disableResend = false;
    this.smsVerficationForm = formBuilder.group({
      'code': ['', [Validators.required]],
      'tel': []
    });
    this.smsVerficationForm.controls['tel'].setValue(this.mobileNumber);
  }
  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef;
  ngOnInit() {
    this.appService.getResendRemainingTimer().subscribe(
      (data: any) => {
        if (data.code === 1 || data.code === '1') {
          this.remainingTime = data.remainingSeconds;
          this.disableResend = true;
        } else {
          this.disableResend = false;
        }
      },
      (errorResponse: any) => {

      }
    );
    this.resendeSMSCode();
  }
  get f() { return this.smsVerficationForm.controls; }

  /**
   * This will check if phone number is valid or not
   */
   checkPhoneNumberValid(){
    var data = {
      "phoneNumber": this.smsVerficationForm.controls['tel'].value,
      "country":this.phoneNumberInput['selectedCountry']['countryCode']
    }
    this.authService.phoneNumberValidating(data).subscribe(
      (data: any) => {
        if (data.code == 1) {
          this.verifySMSCode();
        } else {
          this.errorContactNumber = true;
        }
      }, (htError: HttpErrorResponse) => {
      });
  }  

  verifySMSCode() {
    // tslint:disable-next-line:label-position
    const mode = 'verify';
    this.appService.verifySmsCode(mode, this.f['code'].value).subscribe(
      (data: any) => {
        if (data.code === 1) {
          this.userDetailService.getUserDetails().subscribe(
            (userData: any) => {
              if (userData !== null) {
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(userData));
                let token = localStorage.getItem('accessToken');
                const url = redirectUrls.redirectWelcome+'?token=' + token;                
                window.location.href = url;              
              }
            }
          );
        } else {
          this.errorMessage = data.message;
          this.successMessage = null;
        }
      },
      (errorResponse: any) => {
      }
    );
  }

  resendeSMSCode() {
    this.successMessage = null;
    this.errorMessage = null;
    this.disableResend = true;
    this.appService.resendSMSVerification().subscribe(
      (data: any) => {
        if (data.code === 1) {
          this.successMessage = data.message;
          this.errorMessage = null;
          this.remainingTime = 120;
        } else {
          this.errorMessage = data.message;
          this.successMessage = null;
        }
      },
      (errorResponse: any) => {
        this.disableResend = false;
      }
    );
  }

  skipVerfication() {
    const mode = 'skip';
    this.appService.skipSMSVerification(mode).subscribe(
      (data: any) => {
        if (data.code === 1) {
          localStorage.setItem('user', JSON.stringify(data.updatedUserData));
          let token = localStorage.getItem('accessToken');
          const url = redirectUrls.redirectWelcome+'?token=' + token;                
          window.location.href = url; 
        }
      },
      (errorResponse: any) => {
      }
    );
  }

  closeErrorAlert() {
    this.errorMessage = null;
  }
  closeSuccessMessage() {
    this.successMessage = null;
  }

  finshedTimer() {
    this.disableResend = false;
  }

  onKey(e){
    this.errorContactNumber = false;
    if(this.f['tel'].value.length > 5){
      if(this.f['tel'].value.substring(0,5) == "+9474"){
        this.smsVerficationForm.get('tel').clearValidators();
        this.smsVerficationForm.controls['tel'].updateValueAndValidity();
      }else{
        this.smsVerficationForm.get('tel').setValidators([Validators.required]);
        this.smsVerficationForm.controls['tel'].updateValueAndValidity();
      }
    }else{
      this.smsVerficationForm.get('tel').clearValidators();
      this.smsVerficationForm.controls['tel'].updateValueAndValidity();
      this.smsVerficationForm.get('tel').setValidators([Validators.required]);
      this.smsVerficationForm.controls['tel'].updateValueAndValidity();      
    }
  }



}
