<!-- ======= Footer ======= -->
<footer id="footer">

  <div class="container d-lg-flex justify-content-center py-4">

    <div class="text-center text-lg-left d-flex">
      <div class="copyright" style="display: flex;align-items: center;">
        <!-- &copy; Copyright <strong><span>Inqbaytor Group Pvt Ltd</span></strong> Trading As Ticket Gadget. All Rights Reserved -->
        &copy; Copyright
        <a href="https://inqbaytor.com/">&nbsp; Inqbaytor &nbsp;</a> All Rights Reserved
      </div>

    </div>
    <div class="social-links text-center text-lg-right pt-3 pt-lg-0 pl-3">
      <a href="https://www.facebook.com/ticketgadget" class="facebook">
        <i class="bx bxl-facebook"></i>
      </a>
      <!-- <a href="#" class="instagram">
        <i class="bx bxl-instagram"></i>
      </a> -->
      <!-- <a href="https://www.linkedin.com/company/70997416/" class="linkedin">
        <i class="bx bxl-linkedin"></i>
      </a> -->
    </div>
  </div>
</footer>
<!-- End Footer -->
<!-- <a href="#" class="back-to-top">
  <i class="icofont-simple-up"></i>
</a> -->
