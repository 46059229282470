import { OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
var ShowUpdatedPasswordPopupComponent = /** @class */ (function () {
    function ShowUpdatedPasswordPopupComponent(modalRef) {
        this.modalRef = modalRef;
    }
    ShowUpdatedPasswordPopupComponent.prototype.ngOnInit = function () {
        this.qrCodeValue = sessionStorage.getItem("qrcode_data");
        console.log(this.qrCodeValue);
        if (this.qrCodeValue) {
            this.dataArray = this.qrCodeValue.split(',');
        }
    };
    ShowUpdatedPasswordPopupComponent.prototype.cancelPopup = function () {
        this.modalRef.hide();
        this.modalRef = null;
    };
    return ShowUpdatedPasswordPopupComponent;
}());
export { ShowUpdatedPasswordPopupComponent };
