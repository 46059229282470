import { OnInit } from '@angular/core';
var FooterComponent = /** @class */ (function () {
    function FooterComponent() {
        this.year = new Date().getFullYear();
    }
    FooterComponent.prototype.ngOnInit = function () {
    };
    return FooterComponent;
}());
export { FooterComponent };
