import { Subject } from 'rxjs';
var ComParentChildService = /** @class */ (function () {
    function ComParentChildService() {
        this.subjects = [];
    }
    ComParentChildService.prototype.publish = function (eventName, data) {
        if (data === void 0) { data = null; }
        // ensure a subject for the event name exists
        this.subjects[eventName] = this.subjects[eventName] || new Subject();
        // publish event
        this.subjects[eventName].next();
    };
    ComParentChildService.prototype.on = function (eventName) {
        // ensure a subject for the event name exists
        this.subjects[eventName] = this.subjects[eventName] || new Subject();
        // return observable
        return this.subjects[eventName].asObservable();
    };
    return ComParentChildService;
}());
export { ComParentChildService };
