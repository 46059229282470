<link type="text/css" rel="stylesheet" href="../../assets/css/auth-pages/nav-bar.css" />
<link type="text/css" rel="stylesheet" href="../../assets/css/auth-pages/auth-pages-new.css" />
<header>
  <nav class="navbar navbar-default navbar-fixed-top" id="navbar">
    <div class="naup_row"></div>
    <div class="container-fluid">
      <a class="navbar-brand" href="">
        <div id="logoText">
          <img style="box-sizing: initial;" src="/assets/images/50xticket-gadget.png" />
        </div>
      </a>
    </div>
  </nav>
</header>
<style type="text/css">
  .body {
    height: 100%;
    position: relative;
    position: relative;
    padding-bottom: 6rem;
    min-height: 687px;
  }

  .wrapper {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 85px;
  }
</style>
<div class="body">
  <div class="wrapper">
    <div class="col-md-12 top-header">
      <h3>Verify Mobile Number</h3>
    </div>
    <div class="content-wrapper" style="min-height: 487px;">

      <div id="login-interface">
        <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12 no-padding">
          <div class="panel-body boxshadow" style="margin-top: 52px;">
            <div class="box box-default">
              <!-- @if($status['code']!=7) -->
              <span style="display:block; height: 10px;"></span>
              <!-- @endif @if($status['code']!= -1) -->
              <div class="box-body">
                <!-- @if($status['code']==1 or $status['code']==5 or $status['code']==7 or $status['code']==10) -->
                <div class="alert alert-success alert-dismissible" [hidden]="!successMessage">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="closeSuccessMessage()">&times;</button>
                  {{ successMessage }}
                </div>
                <!-- @elseif($status['code']==2 or $status['code']==6 or $status['code']==8) -->
                <div class="alert alert-info alert-dismissible" [hidden]="!infoMessage">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                  Infor Message
                </div>
                <!-- @elseif($status['code']==3 or $status['code']==-1) -->
                <div class="alert alert-danger alert-dismissible" [hidden]="!errorMessage">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="closeErrorAlert()">&times;</button>
                  {{ errorMessage }}
                </div>
                <!-- @else -->
                <div class="alert alert-warning alert-dismissible" [hidden]="!warningMessage">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                  Warning Message
                </div>
                <!-- @endif -->

                <!-- <div class="text-center">
                  <span id="spanSkip" (click)="skipVerfication()" class="orange-color bold-on-hover" style="cursor: pointer;">Skip</span>
                </div> -->
                <!-- form start -->
                <form [formGroup]='smsVerficationForm' (ngSubmit)="f.form.valid && verifySMSCode()" #f="ngForm" novalidate>
                  <input type="hidden" id="hiddenMode" name="_mode" value="verify" />
                  <!-- @if($status['code']!=7 and $status['code']!=8 and $status['code']!=1 and $status['code']!=2 and $status['code']!=8) -->
                  <div>
                    <div class="form-group">
                      <label class="control-label">Old Mobile Number</label>
                      
                      <international-phone-number placeholder="Enter phone number" [minlength]="10" [maxlength]="15" [defaultCountry]="'lk'" [required]="true"
                        formControlName="telOld" [ngClass]="{ 'intlContactNumber': f.submitted && smsVerficationForm.controls['telOld'].invalid }"></international-phone-number>
                    </div>
                    <div class="form-group ">
                      <label class="control-label ">Code</label>
                      <input type="text" class="form-control" formControlName="oldcode" [ngClass]="{ 'error-border': f.submitted && smsVerficationForm.controls['oldcode'].invalid }"
                        placeholder="Enter 6-digit code sent via SMS" maxlength="6" value="old code or empty" required>
                    </div>
                  </div>
                  <div>
                    <div class="form-group">
                      <label class="control-label">New Mobile Number</label>
                      
                      <international-phone-number placeholder="Enter phone number" [minlength]="10" [maxlength]="15" [defaultCountry]="'lk'" [required]="true"
                        formControlName="telNew" [ngClass]="{ 'intlContactNumber': f.submitted && smsVerficationForm.controls['telNew'].invalid }"></international-phone-number>
                    </div>
                    <div class="form-group ">
                      <label class="control-label ">Code</label>
                      <input type="text" class="form-control" formControlName="newcode" [ngClass]="{ 'error-border': f.submitted && smsVerficationForm.controls['newcode'].invalid }"
                        placeholder="Enter 6-digit code sent via SMS" maxlength="6" value="old code or empty" required>
                    </div>
                  </div>
                  <!-- @endif -->
                  <div class="form-group " style="padding-bottom: 50px; ">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 " style="padding-left: 0; ">
                      <button *ngIf="disableResend" type="button" id="btnResendCode" name="resend" class="btn btn-primary btn-block" [disabled]="disableResend">
                        Resend Code (
                        <countdown (finished)="finshedTimer()" [config]="{leftTime: remainingTime}">$!m!:$!s!</countdown>)
                      </button>
                      <button *ngIf="!disableResend" type="button" (click)="resendeSMSCode()" id="btnResendCode" name="resend" class="btn btn-primary btn-block">
                        Resend Code
                      </button>
                    </div>
                    <!-- @endif -->
                    <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12{{$statusCode!=7 && $statusCode!=8 && $statusCode!=1?
                        '': ' pull-right'}} "
                    style="padding-left: 0; "> -->
                    <!-- @if($status['code']!=7) -->
                    <!-- <a href="Change_number " class="btn btn-primary btn-block ">Change Number</a> -->
                    <!-- @endif -->
                    <!-- </div> -->

                    <!-- @if($status['code']!=7 and $status['code']!=8 and $status['code']!=1) -->
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 pull-right " style="padding-left: 0; ">
                      <button type="submit" id="btnVerify" name="verify" class="btn btn-primary btn-block" value="verify">Verify</button>
                    </div>
                    <!-- @endif -->
                  </div>
                </form>
              </div>
            </div>
            <!-- /.box -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>