var User = /** @class */ (function () {
    function User() {
    }
    User.prototype.setName = function (name) {
        this.reg_name = name;
    };
    User.prototype.setCompanyName = function (companyName) {
        this.reg_company_name = companyName;
    };
    User.prototype.setUserProfile = function (reg_user_profile) {
        this.reg_user_profile = reg_user_profile;
    };
    User.prototype.setMobileNumber = function (mobileNumber) {
        this.reg_contact_number = mobileNumber;
    };
    User.prototype.setStreetAddress1 = function (streetAddress1) {
        this.reg_street1 = streetAddress1;
    };
    User.prototype.setStreetAddress2 = function (streetAddress2) {
        this.reg_street2 = streetAddress2;
    };
    User.prototype.setAddressCity = function (addressCity) {
        this.reg_addresscity = addressCity;
    };
    User.prototype.setProvince = function (province) {
        this.reg_province = province;
    };
    User.prototype.setAddressPostalCode = function (addressPostalCode) {
        this.reg_addresspostalcode = addressPostalCode;
    };
    User.prototype.setAddressCountry = function (addressCountry) {
        this.reg_addresscountry = addressCountry;
    };
    User.prototype.setWebsite = function (website) {
        this.reg_website = website;
    };
    User.prototype.setCurrency = function (currency) {
        this.reg_currency = currency;
    };
    User.prototype.setWorkNumber = function (workNumber) {
        this.reg_office_number = workNumber;
    };
    User.prototype.setWorkEmail = function (workEmail) {
        this.reg_work_email = workEmail;
    };
    User.prototype.setEmail = function (email) {
        this.reg_email = email;
    };
    User.prototype.setPassword = function (password) {
        this.reg_password = password;
    };
    User.prototype.setConfirmPassword = function (confirmPassword) {
        this.confirmpassword = confirmPassword;
    };
    return User;
}());
export { User };
