import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserAuthenticaionService } from '../user-authenticaion.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { redirectUrls } from '../helpers/redirect-urls';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, userAuthService, router, route) {
        this.router = router;
        this.route = route;
        this.email = '';
        this.password = '';
        this.submitted = false;
        this.isLoginFailed = false;
        this.errorMessage = '';
        this.successMessage = '';
        this.userAuthService = userAuthService;
        this.loginForm = formBuilder.group({
            'email': ['', [Validators.required, this.emailWithWhitespaceValidator]],
            'password': ['', [Validators.required, Validators.minLength(8)]]
        });
    }
    LoginComponent.prototype.emailWithWhitespaceValidator = function (control) {
        var isValid = control.value
            .trim()
            .match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        return isValid ? null : {
            isValid: false
        };
    };
    LoginComponent.prototype.ngOnInit = function () {
        // if (localStorage.getItem('accessToken') != null && localStorage.getItem('user')) {
        //   let data = localStorage.getItem('accessToken');
        //   const url = redirectUrls.redirectWelcome+'?token=' + data.accessToken;
        // }
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params.err === 0 || params.err === '0') {
                _this.successMessage = params.message;
                _this.errorMessage = null;
            }
            else {
                _this.errorMessage = params.message;
                _this.successMessage = null;
            }
        });
        if (localStorage.getItem('registrationMessage')) {
            this.successMessage = localStorage.getItem('registrationMessage');
            this.errorMessage = "";
            localStorage.removeItem('registrationMessage');
        }
        localStorage.clear();
        sessionStorage.clear();
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.userAuthService.checkUserAuthentication(this.f['email'].value.trim(), this.f['password'].value).subscribe(function (data) {
            if (data.hasOwnProperty('accessToken')) {
                localStorage.clear();
                sessionStorage.clear();
                var oldPhoneDetails = data.oldPhoneData;
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('user', JSON.stringify(data.user));
                localStorage.setItem('oldPhoneData', JSON.stringify(data.oldPhoneData));
                var reqData = {
                    "token": data.accessToken,
                    "email": data.user.email,
                    "mobile": data.user.mobile_number
                };
                localStorage.setItem('userData', JSON.stringify(reqData));
                var url = redirectUrls.redirectWelcome + '?data=' + btoa(JSON.stringify(reqData));
                window.location.href = url;
                // if (data.user.phone_verify_status == 1 || data.user.phone_verify_status == 2) {
                // this.router.navigate(['/dashboard']);
                // const url = 'http://tguiv3.enabledbeings.com/account/welcome?token=' + data.accessToken;
                // const url = 'https://tguiv3.enabledbeings.com/account/welcome?token=' + data.accessToken;
                // const url = 'http://localhost:4200/account/welcome?token=' + data.accessToken;
                // var reqData = {
                //   "token":data.accessToken,
                //   "email":this.f['reg_email'].value,
                //   "mobile":this.f['reg_contact_number'].value
                // }
                // const url = redirectUrls.redirectWelcome+'?data=' + btoa(JSON.stringify(reqData));
                // window.location.href = url;
                // const url = redirectUrls.redirectWelcome+'?token=' + data.accessToken;
                // window.location.href = url;
                // } else if (data.user.phone_verify_status == 0 && oldPhoneDetails !== null) {
                //   this.router.navigate(['/verify/old-new']);
                // } else {
                //   this.router.navigate(['/sms/verification']);
                // }
            }
            else {
                _this.isLoginFailed = true;
                _this.errorMessage = data.message;
                _this.successMessage = "";
            }
        }, function (errorEwaponse) {
            _this.isLoginFailed = true;
        });
    };
    LoginComponent.prototype.closeErrorAlert = function () {
        this.errorMessage = null;
    };
    LoginComponent.prototype.closeSuccessMessage = function () {
        this.successMessage = null;
    };
    return LoginComponent;
}());
export { LoginComponent };
