import { Component, OnInit } from '@angular/core';
import { TicketManagerService } from 'src/app/functionalservices/ticket-manager.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';

@Component({
  selector: 'app-email-eticket',
  templateUrl: './email-eticket.component.html',
  styleUrls: ['./email-eticket.component.scss']
})
export class EmailEticketComponent implements OnInit {

  theme: String;
  showPriceValues: any;

  constructor(
    private ticketService: TicketManagerService,
    public modalRef: BsModalRef,
    private comparentchildservice: ComParentChildService
  ) { }

  ngOnInit() {
  }

  getTheme(theme) {
    this.theme = theme;
  }

  getShowPriceValues(showPrice) {
    this.showPriceValues = showPrice;
  }

  emailETicket() {
    const ticketNumber = sessionStorage.getItem('emailEticketNo');
    this.ticketService.emailEticket(ticketNumber, this.showPriceValues, this.theme).subscribe(
      (data: any) => {
        if (data.code === 1 || data.code === '1') {
          this.comparentchildservice.publish('email-sent');
          this.cancelPopup();
        }
      }
    );
  }

  cancelPopup() {
    this.modalRef.hide();
    this.modalRef = null;
  }
}
