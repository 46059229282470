import { OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';
var DeleteConfirmComponent = /** @class */ (function () {
    function DeleteConfirmComponent(modalRef, comparentchildservice) {
        this.modalRef = modalRef;
        this.comparentchildservice = comparentchildservice;
    }
    DeleteConfirmComponent.prototype.ngOnInit = function () {
    };
    DeleteConfirmComponent.prototype.confirmDelete = function () {
        this.comparentchildservice.publish('confirm-delete');
        this.cancelPopup();
    };
    DeleteConfirmComponent.prototype.cancelPopup = function () {
        this.modalRef.hide();
        this.modalRef = null;
    };
    return DeleteConfirmComponent;
}());
export { DeleteConfirmComponent };
