import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-session-expire',
  templateUrl: './user-session-expire.component.html',
  styleUrls: ['./user-session-expire.component.scss']
})
export class UserSessionExpireComponent implements OnInit {

  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  logoutFormTheSystem() {
    this.modalRef.hide();
    this.modalRef = null;
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    this.router.navigate(['/login']);
  }

}
