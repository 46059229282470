import { Platform } from '@angular/cdk/platform';
import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ScrollToAnimationEasing, ScrollToConfigOptions, ScrollToOffsetMap, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { redirectUrls } from '../helpers/redirect-urls';
import { UserAuthenticaionService } from '../user-authenticaion.service';

// declare function scrollMethod():any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  linkNum: number = 0;
  userAuthService: UserAuthenticaionService;
  selectedCountry;
  selctedFlag;
  ngxScrollToDestination: string;
  ngxScrollToDuration: number;
  ngxScrollToEasing: ScrollToAnimationEasing;
  ngxScrollToOffset: number;
  ngxScrollToOffsetMap: ScrollToOffsetMap;
  showBanner = false;
  priceDesc;
  subscription = [];
  totalSubscription = [];
  activeSubsciptionData = 1;
  loading = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private myElement: ElementRef,
    userAuthService: UserAuthenticaionService,
    private scrollToService: ScrollToService,
    private platform: Platform,
  ) { 
    this.userAuthService = userAuthService;
  }

  toggleDestination() {
    this.ngxScrollToDestination = this.ngxScrollToDestination === 'about' ? 'services' : 'faq';
  }
  // @HostListener('window:scroll', ['$event']) onScrollEvent($event){
  //   // console.log($event);
  //   // console.log("scrolling");
  // } 
  triggerScrollTo() {
    
    /**
     * @see NOTE:1
     */
    const config: ScrollToConfigOptions = {
      container: 'custom-container',
      target: 'faq',
      duration: 650,
      easing: 'easeOutElastic',
      offset: 20
    };

    this.scrollToService.scrollTo(config);
  }
  
  ngOnInit() {
    this.onCountryChange(1);
    this.checkPlatForm();
    this.activatedRoute.data.subscribe((data) => {
      let idx = 0;
      switch (data.kind) {
        case "about": {
          this.linkNum = 1;
          idx = 1;
          break;
        }
        case "why": {
          this.linkNum = 2;
          idx = 2;
          break;
        }
        case "contact": {
          this.linkNum = 3;
          idx = 3;
          break;
        }
        case "subscription": {
          this.linkNum =4;
          idx = 4;
          break;
        }
      }
      if (idx == 1) {
        setTimeout(
          () => {
            this.aboutSection();
          },
          100
        );
      } else if (idx == 2) {
        setTimeout(
          () => {
            this.whySection();
          },
          100
        );
      } else if (idx == 3) {
        setTimeout(
          () => {
            this.contactSection();
          },
          100
        );
      } else if (idx == 4) {
        setTimeout(
          () => {
            this.subscriptionSection();
          },
          100
        );
      }
      return;
    });
  }


  

  // maps the appropriate column to fields property
  public fields: Object = { text: 'country', value: 'key' };
  public value: number = 1;
  // set the height of the popup element
  public height: string = '250px';
  // set the placeholder to DropDownList input element
  public watermark: string = 'Country';
  public data: { [key: string]: Object }[] = [
    { key: 1, country: 'Sri Lanka', flagImg: '50xticket-gadget', link: 'https://crazyjets.com' },
    // { key: 2, country: 'Australia', flagImg: 'flag-of-Australia', link: 'https://crazyjets.com.au' },
    // { key: 3, country: 'New Zealand', flagImg: 'flag-of-New-Zealand', link: 'https://crazyjets.co.nz' }
  ];
  onScroll($event){
    console.log("sce")
  }
  windowScroll() {
    // if (
    //   document.body.scrollTop > 50 ||
    //   document.documentElement.scrollTop > 50
    // ) {
    //   document.getElementById('topnav').classList.add('nav-sticky');
    // } else {
    //   document.getElementById('topnav').classList.remove('nav-sticky');
    // }

    // if (document.body.scrollTop > 100 ||
    //   document.documentElement.scrollTop > 100) {
    //   document.getElementById('back-to-top').style.display = 'inline';
    // } else {
    //   document.getElementById('back-to-top').style.display = 'none';
    // }
  }

  onSectionChange(sectionId: string) {
    // alert(sectionId)
    // this.currentSection = sectionId;
    console.log("test");
  }
  
  /**
   * This will get the available subscription data
   */
  getSubscriptionData(region){
    this.loading = true;
    this.userAuthService.subscriptionDetails(region).subscribe(
      (res: any) => {
        if(res.code === 1){
          this.totalSubscription = res.other;
          this.subscription = [];
          this.totalSubscription['travel_agent'].forEach((e)=>{
            // if(e.type == 1){
              this.subscription.push(e);
            // }
          })
        }
        this.loading = false;
      },
      (errorEwaponse: any) => {
      }
    ); 
  }
  /**
   * This will trigger when user click on change sunscription type;
   */
  changeSubscriptionType(type){
    this.activeSubsciptionData = type;
    if(type == 1){
      this.subscription = []
      this.totalSubscription['travel_agent'].forEach((e)=>{
          this.subscription.push(e);
      })
    }else if(type == 2){
      this.subscription = []
      this.totalSubscription['corporate'].forEach((e)=>{
          this.subscription.push(e);
      })      
    }
  }

  checkTokenIsValid(){
    this.userAuthService.checkTokenisValid().subscribe(
      (res: any) => {
        if(res){
          // this.router.navigate(['/dashboard']);
          var data = localStorage.getItem('accessToken');
          // const url = 'http://tguiv3.enabledbeings.com/account/welcome?token=' + data;
          var cjwData = localStorage.getItem("loggedDataCJW");
          if(cjwData != null && cjwData != undefined){
            const token = JSON.parse(cjwData).accessToken;
            // var cjwToken = {
            //   "cjwToken":token,
            // }
            this.userAuthService.settingCjwToken(token).subscribe(
              (res: any) => {
                if(res){
                  //Test
                  // const url = 'https://tguiv3.enabledbeings.com/account/welcome?token=' + data+'&ref='+res.reference
                  //Live
                  const url = redirectUrls.redirectWelcome+'?token=' + data+'&ref='+res.reference
                  window.location.href = url;          
                }
              },
              (errorEwaponse: any) => {
              }
            );              
          }else{
            // const url = 'http://localhost:4200/dashboard?token=' + data
            // const url = 'https://tguiv3.enabledbeings.com/account/welcome?token=' + data
            //Live
            var reqData;
            if(localStorage.getItem('userData') != null){
              var user = JSON.parse(localStorage.getItem('userData'));
              reqData = {
                "token":user.token,
                "email":user.email,
                "mobile":user.mobile
              }             
            }else{
              var token = localStorage.getItem('accessToken');
              reqData = {
                "token":token,
                "email":'',
                "mobile":''
              }  
            }
            const url = redirectUrls.redirectWelcome+'?data=' + btoa(JSON.stringify(reqData));
            window.location.href = url; 
          }
        }
      },
      (errorEwaponse: any) => {
      }
    );    
  }

  onClickOnLogin(){
    if (localStorage.getItem('accessToken') != null && localStorage.getItem('user')) {
      this.checkTokenIsValid();
    }else{
      this.router.navigateByUrl('/login')
    }
  }


  onCountryChange(id){
    if(id == 1){
      this.selctedFlag ="assets/images/global.png"
      this.selectedCountry ="Global"
      sessionStorage.setItem('selectedCountry','lk')
      // const url = "https://ticketgadget.com"
      // window.location.href = url;    
      this.getSubscriptionData('US');
    }else if(id == 2){
      this.selctedFlag ="https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg"
      this.selectedCountry ="Australia"
      sessionStorage.setItem('selectedCountry','au')
      const url = "https://ticketgadget.com.au"
      window.location.href = url;  
      this.getSubscriptionData('AU');  
    }else if(id == 3){
      this.selctedFlag ="https://cdn.britannica.com/17/3017-004-DCC13F9D/Flag-New-Zealand.jpg"
      this.selectedCountry ="New Zealand"
      sessionStorage.setItem('selectedCountry','nz')
      const url = "https://ticketgadget.co.nz"
      window.location.href = url;  
      this.getSubscriptionData('NZ');        
    }
  }



  // aboutSection() {
  //   let el = document.getElementById('about');
  //   el.scrollIntoView({behavior:'smooth', block: 'center'});
  // }

  aboutSection(){
    var element = document.getElementById('about');
    var headerOffset = 80;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }

  whySection() {
    // let el = document.getElementById('services');
    // el.scrollIntoView({behavior:'smooth', block: 'center'});
    var element = document.getElementById('services');
    var headerOffset = 80;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
    
  }

  subscriptionSection(){
    var element = document.getElementById('subscription');
    var headerOffset = 80;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }

  contactSection() {
    let element = document.getElementById('contact');
    // el.scrollIntoView({behavior:'smooth', block: 'center'});
    // var element = document.getElementById('subscription');
    var headerOffset = 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }


  //on view click action, check the platform and redirect to the store url
  onViewClick(){
    if (this.platform.IOS) {
      const url = "https://apps.apple.com/app/id1526102889"
      window.location.href = url;        
    }else if(this.platform.ANDROID || this.platform.BLINK){
      const url = "https://play.google.com/store/apps/details?id=com.inq.ticketgadget"
      window.location.href = url;  
    }
  }

  onClickOnClose(){
    this.showBanner = false;
    sessionStorage.setItem('showBanner',JSON.stringify(this.showBanner))
  }

  checkPlatForm(){
    if (this.platform.IOS || this.platform.ANDROID || this.platform.BLINK){
      this.showBanner = true;
      let bannerStatus = JSON.parse(sessionStorage.getItem('showBanner')); 
      if(bannerStatus != null){
        this.showBanner = bannerStatus;
      }
    }
    if(this.platform.IOS){
      this.priceDesc = "FREE - On the App Store"
    }else if(this.platform.ANDROID || this.platform.BLINK){
      this.priceDesc = "FREE - In Google Play"
    }
  };

}
