import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(formBuilder, applicationService, router, route) {
        this.applicationService = applicationService;
        this.router = router;
        this.route = route;
        this.fppassword = '';
        this.fpconfirmpassword = '';
        this.show = false;
        this.confirmShow = false;
        this.isClear = true;
        this.resetPasswordForm = formBuilder.group({
            'fppassword': ['', [Validators.required, Validators.pattern('^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()_+]).*$')]],
            'fpconfirmpassword': ['', [Validators.required]],
        }, {
            validator: MustMatch('fppassword', 'fpconfirmpassword'),
        });
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.authCode = params.code;
        });
    };
    Object.defineProperty(ResetPasswordComponent.prototype, "f", {
        get: function () { return this.resetPasswordForm.controls; },
        enumerable: true,
        configurable: true
    });
    ResetPasswordComponent.prototype.submitPasswords = function () {
        var _this = this;
        var newPassword = this.f['fppassword'].value;
        var confirmPassword = this.f['fpconfirmpassword'].value;
        this.authCode = localStorage.getItem('fotp');
        var email = localStorage.getItem('fpemail');
        if (newPassword === confirmPassword) {
            this.applicationService.resetForgotPassword(email, this.authCode, newPassword, confirmPassword, this.isClear).subscribe(function (data) {
                if (data.code === 1 || data.code === '1') {
                    localStorage.setItem('registrationMessage', data.message);
                    _this.router.navigate(['/login']);
                }
                else {
                    _this.errorMessage = data.message;
                }
            }, function (httpError) {
            });
        }
    };
    ResetPasswordComponent.prototype.password = function () {
        this.show = !this.show;
    };
    ResetPasswordComponent.prototype.confirmPassword = function () {
        this.confirmShow = !this.confirmShow;
    };
    ResetPasswordComponent.prototype.onAgreeChange = function (event) {
        if (event.target.checked) {
            this.isClear = true;
        }
        else {
            this.isClear = false;
        }
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
export function MustMatch(controlName, matchingControlName) {
    return function (formGroup) {
        var control = formGroup.controls[controlName];
        var matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        }
        else {
            matchingControl.setErrors(null);
        }
    };
}
