import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';

@Component({
  selector: 'app-show-remark-data',
  templateUrl: './show-remark-data.component.html',
  styleUrls: ['./show-remark-data.component.scss']
})
export class ShowRemarkDataComponent implements OnInit {
  xmlString: string;

  constructor(
    public modalRef: BsModalRef,
    private comparentchildservice: ComParentChildService
  ) { }

  ngOnInit() {
  }

  cancelPopup() {
    this.modalRef.hide();
    this.modalRef = null;
  }

}
