import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { UserAuthenticaionService } from 'src/app/user-authenticaion.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  fppassword: String = '';
  fpconfirmpassword: String = '';
  authCode: any;
  resetPasswordForm: FormGroup;
  errorMessage: String;
  show: boolean = false;
  confirmShow:boolean = false;
  isClear:boolean = true;
  constructor(formBuilder: FormBuilder, private applicationService: AppService, private router: Router, private route: ActivatedRoute) {
    this.resetPasswordForm = formBuilder.group({
      'fppassword': ['', [Validators.required,Validators.pattern('^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()_+]).*$')]],
      'fpconfirmpassword': ['', [Validators.required]],
    },{
      validator: MustMatch('fppassword', 'fpconfirmpassword'),
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.authCode = params.code;
      }
    );
  }
  get f() { return this.resetPasswordForm.controls; }

  submitPasswords() {
    const newPassword = this.f['fppassword'].value;
    const confirmPassword = this.f['fpconfirmpassword'].value;
    this.authCode = localStorage.getItem('fotp');
    const email = localStorage.getItem('fpemail');
    if (newPassword === confirmPassword) {
      this.applicationService.resetForgotPassword(email,this.authCode, newPassword, confirmPassword, this.isClear).subscribe(
        (data: any) => {
          if (data.code === 1 || data.code === '1') {
            localStorage.setItem('registrationMessage', data.message);
            this.router.navigate(['/login']);
          } else {
            this.errorMessage = data.message;
          }
        },
        (httpError: any) => {

        }
      );
    }
  }

  password(){
    this.show = !this.show;
  }

  confirmPassword(){
    this.confirmShow = !this.confirmShow;
  }

  onAgreeChange(event){
    if(event.target.checked){
     this.isClear = true;
    }else{
      this.isClear = false;
    }
   }


}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          return;
      }

      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  };
  
}
