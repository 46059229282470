/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-retrieve.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./confirm-retrieve.component";
import * as i3 from "ngx-bootstrap/modal/bs-modal-ref.service";
import * as i4 from "../../service/com-parent-child.service";
var styles_ConfirmRetrieveComponent = [i0.styles];
var RenderType_ConfirmRetrieveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmRetrieveComponent, data: {} });
export { RenderType_ConfirmRetrieveComponent as RenderType_ConfirmRetrieveComponent };
export function View_ConfirmRetrieveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm Search"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "center", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please note that you will not be able to issue the ticket(s) unless you have removed all filed fares and exited/ignored (Eg: In Galileo type 'I' and enter) from all GDS terminals."])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-success btn-flat"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmRetrieve() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["I have done the needful"])), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0\u00A0 "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-primary btn-flat"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["I will do the needful and try again later"]))], null, null); }
export function View_ConfirmRetrieveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-retrieve", [], null, null, null, View_ConfirmRetrieveComponent_0, RenderType_ConfirmRetrieveComponent)), i1.ɵdid(1, 114688, null, 0, i2.ConfirmRetrieveComponent, [i3.BsModalRef, i4.ComParentChildService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmRetrieveComponentNgFactory = i1.ɵccf("app-confirm-retrieve", i2.ConfirmRetrieveComponent, View_ConfirmRetrieveComponent_Host_0, {}, {}, []);
export { ConfirmRetrieveComponentNgFactory as ConfirmRetrieveComponentNgFactory };
