import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { EROFS } from 'constants';
import { environment } from '../environments/environment'
// import { AuthService } from './auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
            retry(0),
            catchError((error: HttpErrorResponse) => {
                console.log(error.status);
                let errorMessage = "";
                if (error.status == 401) {
                    if(error.error[0] == "UNAUTHORIZED"){
                        window.location.href = "/login"
                    }else{
                        localStorage.removeItem("loggedDataCJW");
                        console.log("reloading");
                        window.location.href = "flight/search/engine";
                    }
                }
                return throwError(errorMessage);
            })
            )
    }
    constructor(
    ) {

    }

    // logout() {
    //     localStorage.removeItem("loggedData");
    //     localStorage.removeItem("authUserData");
    //     localStorage.removeItem("skipNF")
    //     localStorage.removeItem("skipEF")
    //     localStorage.removeItem("skipDOBF")
    //     localStorage.removeItem("skipGF")
    //     localStorage.removeItem("skipAF")
    //     this.getAuth();
    // }
    // getAuth(): void {
    //     this.authService.auth()
    //         .subscribe(data => {
    //             //this.searchResults = data;
    //             //this.openModalWithComponent();
    //             console.log(data);
    //             if (localStorage.getItem("loggedData") === null || localStorage.getItem("loggedData") == undefined) {
    //                 localStorage.setItem("loggedData", JSON.stringify(data));
    //                 // window.location.reload();
    //             }
    //             //this.loading = false;
    //             //this.router.navigate(['/search-results']);
    //         }
    //         );
    // }
}
