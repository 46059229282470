import { OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
var HttpErrorPopupComponent = /** @class */ (function () {
    function HttpErrorPopupComponent(modalRef) {
        this.modalRef = modalRef;
    }
    HttpErrorPopupComponent.prototype.ngOnInit = function () {
        this.httpErrorObject = JSON.parse(sessionStorage.getItem('httpError'));
    };
    HttpErrorPopupComponent.prototype.ngOnDestroy = function () {
        sessionStorage.removeItem('httpError');
        this.modalRef = null;
    };
    HttpErrorPopupComponent.prototype.cancelPopup = function () {
        this.modalRef.hide();
        this.modalRef = null;
    };
    return HttpErrorPopupComponent;
}());
export { HttpErrorPopupComponent };
