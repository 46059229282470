import { Component, OnInit } from '@angular/core';
import { TicketManagerService } from 'src/app/functionalservices/ticket-manager.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';

@Component({
  selector: 'app-eticket-options',
  templateUrl: './eticket-options.component.html',
  styleUrls: ['./eticket-options.component.scss']
})
export class EticketOptionsComponent implements OnInit {

  theme: String;
  showPriceValues: any;

  constructor(
    private ticketService: TicketManagerService,
    public modalRef: BsModalRef,
    private comparentchildservice: ComParentChildService
  ) { }

  ngOnInit() {
  }

  getTheme(theme) {
    this.theme = theme;
  }

  getShowPriceValues(showPrice) {
    this.showPriceValues = showPrice;
  }

  downloadETicket() {
    const ticketNumber = sessionStorage.getItem('downEticketNo');
    this.ticketService.downloadEticket(ticketNumber, this.showPriceValues, this.theme).subscribe(
      (data: any) => {
        if (data.code === 1 || data.code === '1') {
          window.location.href = data.url;
          this.cancelPopup();
          return false;
        }
      },
      (httpError: any) => {
        if (httpError instanceof ErrorEvent) {
          console.log(httpError);
        console.log("error occurred");
        }
      }
    );
  }

  cancelPopup() {
    this.modalRef.hide();
    this.modalRef = null;
  }
}
