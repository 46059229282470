import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
var UserGuidelinesComponent = /** @class */ (function () {
    function UserGuidelinesComponent(http) {
        this.http = http;
        this.loading = false;
        this.userGuidelines = [];
    }
    UserGuidelinesComponent.prototype.ngOnInit = function () {
        // this.checkTokenIsValid();
        this.getUserGuidelinesDetails();
    };
    /**
     * This will get the available user guidelines
     */
    UserGuidelinesComponent.prototype.getUserGuidelinesDetails = function () {
        var _this = this;
        this.loading = true;
        this.userGuidelinesDetails().subscribe(function (res) {
            _this.loading = false;
            if (res.code === 1) {
                _this.userGuidelines = res.other;
            }
        }, function (errorEwaponse) {
        });
    };
    UserGuidelinesComponent.prototype.userGuidelinesDetails = function () {
        var headerData = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return this.http.get(environment.apiLink.concat('user-guidelines/home'), { headers: headerData });
    };
    UserGuidelinesComponent.prototype.checkTokenIsValid = function () {
        this.userAuthService.checkTokenisValid().subscribe(function (res) { });
    };
    return UserGuidelinesComponent;
}());
export { UserGuidelinesComponent };
