<div class="modal-header">
  <h4 class="modal-title pull-left">{{type}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <center>
    <div class="outerpad">
      <div class="row">
        <p [innerHTML]="message">
        </p>
        <p *ngIf="source=='NameChange'">1. If you have your passport details on you now select 'ENTER NOW' and enter your passport details.</p>
        <p *ngIf="source=='NameChange'">2. If you don't have your passport details on you now select 'SKIP' and we can update this later once your booking
          is placed.</p>
      </div>
      <br />
      <div class="row">
        <button *ngIf="source == 'SearchResults' || source == 'BookingError'" type="submit" class="modify-search-button btn transition-effect"
          (click)="onSubmitModifySearch(); hideModal();">MODIFY SEARCH
        </button>
        <button *ngIf="source == 'SearchResults' || source == 'BookingError'" type="submit" class="modify-search-button btn transition-effect"
          (click)="redirectHome(); hideModal();">HOME</button>
        <button *ngIf="source == 'FormError'" type="submit" class="modify-search-button btn transition-effect" (click)="redirectHome(); hideModal();">HOME</button>
        <button *ngIf="source == 'FormError'" type="submit" class="modify-search-button btn transition-effect" (click)="hideModal();">Close
        </button>
        <button type="submit" *ngIf="source == 'NameChange'" class="modify-search-button btn transition-effect" (click)="enterNow();">Enter Now
        </button>
        <button type="submit" *ngIf="source == 'NameChange'" class="modify-search-button btn transition-effect" (click)="skip();">Skip</button>
        <button *ngIf="source == 'PaymentError' || source == 'PriceError'" type="submit" class="modify-search-button btn transition-effect"
          (click)="continuePayment();">Continue
        </button>
        <button *ngIf="source == 'PaymentError' || source == 'PriceError'" type="submit" class="modify-search-button btn transition-effect"
          (click)="redirectHome(); hideModal();">HOME</button>
      </div>
    </div>
  </center>
</div>
<!-- <div class="modal-dialog modal-md">
  <div class="">
    <div class="modal-body">

      <div class="error-pop-up-header">
        <button type="button" class="close" (click)="hideModal()" aria-label="Close">
          <span aria-hidden="true">
            <i class="fa fa-times"></i>
          </span>
        </button>
        <h5>{{type}}</h5>
      </div>
      <p [innerHTML]="message">
      </p> -->
<!-- <p *ngIf="source=='NameChange'">1. If you have your passport details on you now select 'ENTER NOW' and enter your
        passport details.</p>
      <p *ngIf="source=='NameChange'">2. If you don't have your passport details on you now select 'SKIP' and we can
        update this later once your booking
        is placed.</p> -->
<!-- <div class="col-md-12 col-sm-12 col-xs-6" *ngIf="source == 'SearchResults' || source == 'BookingError'">
        <div class="form-gp">
          <div class="col-md-5 col-sm-12 pull-right">
            <button type="submit" class="modify-search-button btn transition-effect"
              (click)="onSubmitModifySearch(); hideModal();">MODIFY SEARCH
            </button>
          </div>
          <div class="col-md-3 col-sm-12 pull-right">
            <button type="submit" class="modify-search-button btn transition-effect"
              (click)="redirectHome(); hideModal();">HOME</button>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-6" *ngIf="source == 'FormError'">
        <div class="form-gp">
          <div class="col-md-3 col-sm-12 pull-right">
            <button type="submit" class="modify-search-button btn transition-effect"
              (click)="redirectHome(); hideModal();">HOME</button>
          </div>
          <div class="col-md-5 col-sm-12 pull-right">
            <button type="submit" class="modify-search-button btn transition-effect" (click)="enterNow();">Close
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-6" *ngIf="source == 'PaymentError' || source == 'PriceError'">
        <div class="form-gp">
          <div class="col-md-5 col-sm-12 pull-right">
            <button type="submit" class="modify-search-button btn transition-effect"
              (click)="continuePayment();">Continue
            </button>
          </div>
          <div class="col-md-3 col-sm-12 pull-right">
            <button type="submit" class="modify-search-button btn transition-effect"
              (click)="redirectHome(); hideModal();">HOME</button>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div> -->