import { OnInit } from '@angular/core';
import { TicketManagerService } from 'src/app/functionalservices/ticket-manager.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';
var EticketOptionsComponent = /** @class */ (function () {
    function EticketOptionsComponent(ticketService, modalRef, comparentchildservice) {
        this.ticketService = ticketService;
        this.modalRef = modalRef;
        this.comparentchildservice = comparentchildservice;
    }
    EticketOptionsComponent.prototype.ngOnInit = function () {
    };
    EticketOptionsComponent.prototype.getTheme = function (theme) {
        this.theme = theme;
    };
    EticketOptionsComponent.prototype.getShowPriceValues = function (showPrice) {
        this.showPriceValues = showPrice;
    };
    EticketOptionsComponent.prototype.downloadETicket = function () {
        var _this = this;
        var ticketNumber = sessionStorage.getItem('downEticketNo');
        this.ticketService.downloadEticket(ticketNumber, this.showPriceValues, this.theme).subscribe(function (data) {
            if (data.code === 1 || data.code === '1') {
                window.location.href = data.url;
                _this.cancelPopup();
                return false;
            }
        }, function (httpError) {
            if (httpError instanceof ErrorEvent) {
                console.log(httpError);
                console.log("error occurred");
            }
        });
    };
    EticketOptionsComponent.prototype.cancelPopup = function () {
        this.modalRef.hide();
        this.modalRef = null;
    };
    return EticketOptionsComponent;
}());
export { EticketOptionsComponent };
