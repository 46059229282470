<div class="modal-dialog modal-m">
  <!-- Modal content-->
  <div class="modal-content">
    <div class="modal-header">

      <h4 class="modal-title">Warning Message</h4>
    </div>
    <div class="modal-body">
      <p>Your session has expired, please re-login to renew your session.</p>
    </div>
    <div class="modal-footer">
      <a (click)="logout()">
        <button type="button" class="btn btn-primary btn-block btn-flat">Ok</button>
      </a>
    </div>
  </div>
</div>