<div class="modal-dialog modal-m">
  <!-- Modal content-->
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="cancelPopup()">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 class="modal-title">{{ httpErrorObject.name }}</h4>
    </div>
    <div class="modal-body" style="background-color: #f7f7f7;">
      <center>
        <p style="padding-bottom: 5px;text-align: center;">{{ httpErrorObject.message }}</p>
      </center>
    </div>
  </div>
</div>