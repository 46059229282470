/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth-registerredirecter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./auth-registerredirecter.component";
var styles_AuthRegisterredirecterComponent = [i0.styles];
var RenderType_AuthRegisterredirecterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthRegisterredirecterComponent, data: {} });
export { RenderType_AuthRegisterredirecterComponent as RenderType_AuthRegisterredirecterComponent };
export function View_AuthRegisterredirecterComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_AuthRegisterredirecterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth-registerredirecter", [], null, null, null, View_AuthRegisterredirecterComponent_0, RenderType_AuthRegisterredirecterComponent)), i1.ɵdid(1, 114688, null, 0, i2.AuthRegisterredirecterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthRegisterredirecterComponentNgFactory = i1.ɵccf("app-auth-registerredirecter", i2.AuthRegisterredirecterComponent, View_AuthRegisterredirecterComponent_Host_0, {}, {}, []);
export { AuthRegisterredirecterComponentNgFactory as AuthRegisterredirecterComponentNgFactory };
