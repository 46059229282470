import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import { User } from 'src/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  appService: AppService;
  constructor(private http: HttpClient, applicationService: AppService) {
    this.appService = applicationService;
  }

  getUserDetails() {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('user/details'),
      {},
      { headers: headerData }
    );
  }

  updateUserDetails(user: User) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('update/user'),
      JSON.stringify(user),
      { headers: headerData }
    );
  }

  changePassword(jsonData) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('changepassworddeails'),
      jsonData,
      { headers: headerData }
    );
  }

  getUserPccs() {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('all/pccs'),
      {},
      { headers: headerData }
    );
  }

  getAllProviders() {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('all/providers'),
      {},
      { headers: headerData }
    );
  }

  requestPccs(jsonData: any) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('request/pccs'),
      jsonData,
      { headers: headerData }
    );
  }

  changeUserContactNumber(phoneNumber) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('changetel'),
      {
        "phone_number": phoneNumber
      },
      { headers: headerData }
    );
  }

}
