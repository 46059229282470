import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { UserAuthenticaionService } from 'src/app/user-authenticaion.service';
import { User } from 'src/models/user';
import { Router } from '@angular/router';
import { redirectUrls } from '../helpers/redirect-urls';
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(formBuilder, appService, authService, router) {
        this.appService = appService;
        this.authService = authService;
        this.router = router;
        this.reg_name = '';
        this.reg_company_name = '';
        this.reg_contact_number = '';
        this.reg_email = '';
        this.reg_password = '';
        this.confirmpassword = '';
        this.currencyData = '';
        this.countryData = '';
        this.userCountryData = '';
        this.country2Data = '';
        this.errorPassword = "";
        this.errorConfirmPasswordMsg = "";
        this.errorEmailExist = false;
        this.errorName = false;
        this.errorCompanyName = false;
        this.errorContactNumber = false;
        this.errorStreet1 = false;
        this.errorStreet2 = false;
        this.errorAddresscity = false;
        this.errorProvince = false;
        this.errorAddresspostalcode = false;
        this.errorAddresscountry = false;
        this.errorWebsite = false;
        this.errorCurrency = false;
        this.errorOffice_contact_number = false;
        this.errorWork_email = false;
        this.errorEmail = false;
        this.errorInPassword = false;
        this.errorConfirmpassword = false;
        this.errorAgree = false;
        this.errorMobile = false;
        this.errorMobileText = '';
        this.agree = false;
        this.selectedCountry = 'lk';
        this.errorMessage = '';
        this.userProfileGroups = [];
        this.show = false;
        this.confirmShow = false;
        this.registerForm = formBuilder.group({
            reg_name: ['', [Validators.required]],
            reg_company_name: ['', [Validators.required]],
            reg_user_profile: ['', [Validators.required]],
            reg_contact_number: '',
            reg_email: ['', [Validators.required, Validators.email]],
            reg_password: ['', [Validators.required, Validators.pattern('^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()_+]).*$')]],
            confirmpassword: ['', [Validators.required]],
            isAgree: [false]
        });
    }
    Object.defineProperty(RegisterComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        // if (localStorage.getItem('accessToken') != null && localStorage.getItem('user')) {
        //   // this.router.navigate(['/dashboard']);
        //   let data = localStorage.getItem('accessToken');
        //   const url = redirectUrls.redirectWelcome+'?token=' + data;
        //   window.location.href = url; 
        // }
        var country = sessionStorage.getItem('selectedCountry');
        if (country != null) {
            this.selectedCountry = country;
        }
        this.appService.getRegistrationData().subscribe(function (data) {
            _this.countryData = data[0];
            _this.currencyData = data[1];
            _this.userProfileGroups = data[4];
            if (_this.userProfileGroups[0]) {
                _this.f.reg_user_profile.setValue(_this.userProfileGroups[0].id.toString());
                _this.subscriptionDescription = _this.userProfileGroups[0].subscription_description;
            }
        }, function (htError) {
        });
        this.disableRegister = false;
    };
    /**
     * This will check if phone number is valid or not
     */
    RegisterComponent.prototype.checkPhoneNumberValid = function () {
        var _this = this;
        this.errorMobile = false;
        this.errorContactNumber = false;
        if (this.phoneNumberInput['selectedCountry'] != null) {
            var data = {
                "phoneNumber": this.f['reg_contact_number'].value,
                "country": this.phoneNumberInput['selectedCountry']['countryCode']
            };
            this.authService.phoneNumberValidating(data).subscribe(function (data) {
                if (data.code == 1) {
                    if (!_this.f['isAgree'].value) {
                        _this.errorAgree = true;
                    }
                    else {
                        _this.errorAgree = false;
                        _this.onSubmit();
                    }
                }
                else {
                    _this.errorContactNumber = true;
                }
                _this.disableRegister = false;
            }, function (htError) {
                console.log(htError);
                _this.disableRegister = false;
            });
        }
        else {
            this.errorMobile = true;
            this.errorContactNumber = true;
            this.errorMobileText = "Please select the country";
        }
    };
    RegisterComponent.prototype.onAgreeChange = function (event) {
        if (event.target.checked) {
            this.errorAgree = false;
            this.f.isAgree.setValue(true);
        }
        else {
            this.f.isAgree.setValue(false);
        }
    };
    RegisterComponent.prototype.onSubmit = function () {
        var _this = this;
        this.disableRegister = true;
        var user = new User();
        user.setName(this.f['reg_name'].value);
        user.setCompanyName(this.f['reg_company_name'].value);
        user.setMobileNumber(this.f['reg_contact_number'].value);
        user.setEmail(this.f['reg_email'].value);
        user.setPassword(this.f['reg_password'].value);
        user.setConfirmPassword(this.f['confirmpassword'].value);
        user.setUserProfile(this.f['reg_user_profile'].value);
        this.authService.registerUserData(user).subscribe(function (data) {
            if (data.code === '1' || (data.code === 1)) {
                localStorage.setItem('registrationMessage', data.message);
                // this.authService.sendEmailVerification(data.emailId).subscribe(
                //   (emailData: any) => {
                //     console.log('succes data');
                //   }, (htError: HttpErrorResponse) => {
                //     console.log('error occrured');
                //     console.log(htError);
                //   });
                // console.log(localStorage.getItem('registrationMessage'));
                // this.router.navigate(['/login']);
                var reqData = {
                    "token": data.accessToken,
                    "email": _this.f['reg_email'].value,
                    "mobile": data['reg_contact_number']
                };
                localStorage.setItem('userData', JSON.stringify(reqData));
                var url = redirectUrls.redirectWelcome + '?data=' + btoa(JSON.stringify(reqData));
                window.location.href = url;
            }
            else {
                if ('reg_name' in data) {
                    if (data.reg_name.code === 0 || data.reg_name.code === '0') {
                        _this.errorName = true;
                    }
                }
                else {
                    _this.errorName = false;
                }
                if ('reg_company_name' in data) {
                    if (data.reg_company_name.code === 0 || data.reg_company_name.code === '0') {
                        _this.errorCompanyName = true;
                    }
                }
                else {
                    _this.errorCompanyName = false;
                }
                if ('reg_contact_number' in data) {
                    if (data.reg_contact_number.code === 0 || data.reg_contact_number.code === '0') {
                        _this.errorContactNumber = true;
                    }
                }
                else {
                    _this.errorContactNumber = false;
                }
                if ('reg_email' in data) {
                    if (data.reg_email.code === 0 || data.reg_email.code === '0') {
                        _this.errorEmail = true;
                        _this.errorEmailExist = true;
                    }
                }
                else {
                    _this.errorEmail = false;
                    _this.errorEmailExist = false;
                }
                if ('reg_password' in data) {
                    if (data.reg_password.code === 0 || data.reg_password.code === '0') {
                        _this.errorInPassword = true;
                        _this.errorPassword = data.reg_password.message;
                    }
                }
                else {
                    _this.errorInPassword = false;
                    _this.errorPassword = "";
                }
                if ('confirmpassword' in data) {
                    if (data.confirmpassword.code === 0 || data.confirmpassword.code === '0') {
                        _this.errorConfirmPasswordMsg = data.confirmpassword.message;
                        _this.errorConfirmpassword = true;
                    }
                }
                else {
                    _this.errorConfirmPasswordMsg = "";
                    _this.errorConfirmpassword = false;
                }
            }
            _this.disableRegister = false;
        }, function (htError) {
            console.log(htError);
            _this.disableRegister = false;
        });
    };
    RegisterComponent.prototype.changeSubscription = function (event) {
        for (var _i = 0, _a = this.userProfileGroups; _i < _a.length; _i++) {
            var profileGroup = _a[_i];
            if (profileGroup.id == event.target.value) {
                this.subscriptionDescription = profileGroup.subscription_description;
            }
        }
    };
    RegisterComponent.prototype.onKey = function (e) {
        this.errorContactNumber = false;
        this.errorMobile = false;
        if (this.f['reg_contact_number'].value.length > 5) {
            if (this.f['reg_contact_number'].value.substring(0, 5) == "+9474") {
                this.registerForm.get('reg_contact_number').clearValidators();
                this.registerForm.controls['reg_contact_number'].updateValueAndValidity();
            }
            else {
                this.registerForm.get('reg_contact_number').setValidators([Validators.required]);
                this.registerForm.controls['reg_contact_number'].updateValueAndValidity();
            }
        }
        else {
            this.registerForm.get('reg_contact_number').clearValidators();
            this.registerForm.controls['reg_contact_number'].updateValueAndValidity();
            this.registerForm.get('reg_contact_number').setValidators([Validators.required]);
            this.registerForm.controls['reg_contact_number'].updateValueAndValidity();
        }
    };
    RegisterComponent.prototype.onPasswordKey = function (e) {
        this.errorConfirmpassword = false;
    };
    RegisterComponent.prototype.password = function () {
        this.show = !this.show;
    };
    RegisterComponent.prototype.confirmPassword = function () {
        this.confirmShow = !this.confirmShow;
    };
    return RegisterComponent;
}());
export { RegisterComponent };
