import { OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ComParentChildService } from '../../service/com-parent-child.service';
var ErrorpopupComponent = /** @class */ (function () {
    function ErrorpopupComponent(bsModalRef, router, comparentchildservice) {
        this.bsModalRef = bsModalRef;
        this.router = router;
        this.comparentchildservice = comparentchildservice;
        this.config = {
            backdrop: true,
            ignoreBackdropClick: true
        };
    }
    ErrorpopupComponent.prototype.ngOnInit = function () {
    };
    ErrorpopupComponent.prototype.hideModal = function () {
        if (this.bsModalRef) {
            this.bsModalRef.hide();
            this.bsModalRef = null;
        }
        // if(this.bsModifySearchModalRef){
        //   this.bsModifySearchModalRef.hide();
        //   this.bsModifySearchModalRef = null;
        // }
        // this.modifySearch.hideModal();
    };
    ErrorpopupComponent.prototype.onSubmitModifySearch = function () {
        this.hideModal();
        // this.bsModifySearchModalRef = this.modalService.show(SearchFormModalComponent, this.config);
        // this.bsModifySearchModalRef.content.searchLocation = 'modify';
        // onSubmitModifySearch
    };
    ErrorpopupComponent.prototype.redirectHome = function () {
        this.router.navigate(['/flight/search/engine']);
    };
    ErrorpopupComponent.prototype.enterNow = function () {
        this.hideModal();
    };
    ErrorpopupComponent.prototype.skip = function () {
        // this.flightBooking.onSubmit();
        this.comparentchildservice.publish('skip-name-change', "skip");
        this.hideModal();
    };
    ErrorpopupComponent.prototype.continuePayment = function () {
        this.comparentchildservice.publish('call-parent');
    };
    return ErrorpopupComponent;
}());
export { ErrorpopupComponent };
