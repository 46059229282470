import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { UserAuthenticaionService } from "./user-authenticaion.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(appService, router, route, platformId, appId, authService) {
        this.appService = appService;
        this.router = router;
        this.route = route;
        this.platformId = platformId;
        this.appId = appId;
        this.authService = authService;
        this.title = 'Ticketgadget';
        this.appService.setMenuBarLevels('free', '', '');
        if (!localStorage.getItem('csrfToken')) {
            this.getCSRFToken();
        }
        console.log(localStorage.getItem('authResult'));
        // if(localStorage.getItem('authResult') == null || localStorage.getItem('authResult') == undefined){
        //   this.getAuth();
        // }
        // else if(!(JSON.parse(localStorage.getItem('authResult'))).hasOwnProperty('accessToken')){
        //   this.getAuth();
        // } 
    }
    AppComponent.prototype.onActivate = function (event) {
        if (isPlatformBrowser(this.platformId)) {
            var scrollToTop_1 = window.setInterval(function () {
                var pos = window.pageYOffset;
                if (pos > 0) {
                    window.scrollTo(0, pos - 50); // how far to scroll on each step
                }
                else {
                    window.clearInterval(scrollToTop_1);
                }
            }, 16);
        }
    };
    AppComponent.prototype.getCSRFToken = function () {
        this.appService.getXCRFToken().subscribe(function (data) {
            if (data.code === 1) {
                localStorage.setItem('csrfToken', data.csrftoken);
            }
        }, function (htError) {
        });
    };
    return AppComponent;
}());
export { AppComponent };
