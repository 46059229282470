import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { UserAuthenticaionService } from 'src/app/user-authenticaion.service';
import { User } from 'src/models/user';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { redirectUrls } from '../helpers/redirect-urls';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  post: any;
  reg_name: String = '';
  reg_company_name: String = '';
  reg_contact_number: String = '';
  reg_email: String = '';
  reg_password: String = '';
  confirmpassword: String = '';
  currencyData: String = '';
  countryData: String = '';
  userCountryData: String = '';
  country2Data: String = '';
  phone_number1: any;
  phone_number2: any;
  disableRegister: boolean;

  errorPassword: any = "";
  errorConfirmPasswordMsg: any = "";
  errorEmailExist = false;
  errorName = false;
  errorCompanyName = false;
  errorContactNumber = false;
  errorStreet1 = false;
  errorStreet2 = false;
  errorAddresscity = false;
  errorProvince = false;
  errorAddresspostalcode = false;
  errorAddresscountry = false;
  errorWebsite = false;
  errorCurrency = false;
  errorOffice_contact_number = false;
  errorWork_email = false;
  errorEmail = false;
  errorInPassword = false;
  errorConfirmpassword = false;
  subscriptionDescription: string;
  errorAgree = false;
  errorMobile = false;
  errorMobileText='';
  agree = false;
  selectedCountry ='lk';

  errorMessage: String = '';
  userProfileGroups: any = [];
  show: boolean = false;
  confirmShow:boolean = false;

  constructor(formBuilder: FormBuilder, private appService: AppService, private authService: UserAuthenticaionService,
    private router: Router) {
    this.registerForm = formBuilder.group({
      reg_name: ['', [Validators.required]],
      reg_company_name: ['', [Validators.required]],
      reg_user_profile: ['', [Validators.required]],
      reg_contact_number:'',
      reg_email: ['', [Validators.required, Validators.email]],
      reg_password: ['', [Validators.required,Validators.pattern('^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()_+]).*$')]],
      confirmpassword: ['', [Validators.required]],
      isAgree:[false]
    });
  }

  get f() { return this.registerForm.controls; }
  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef;

  ngOnInit() {
    // if (localStorage.getItem('accessToken') != null && localStorage.getItem('user')) {
    //   // this.router.navigate(['/dashboard']);
    //   let data = localStorage.getItem('accessToken');
    //   const url = redirectUrls.redirectWelcome+'?token=' + data;
    //   window.location.href = url; 
    // }
    let country = sessionStorage.getItem('selectedCountry');
    if(country != null){
      this.selectedCountry = country;
    }
    this.appService.getRegistrationData().subscribe(
      (data: any) => {
        this.countryData = data[0];
        this.currencyData = data[1];
        this.userProfileGroups = data[4];
        if (this.userProfileGroups[0]) {
          this.f.reg_user_profile.setValue(this.userProfileGroups[0].id.toString());
          this.subscriptionDescription = this.userProfileGroups[0].subscription_description;
        }
      }, (htError: HttpErrorResponse) => {

      }
    );
    this.disableRegister = false;
  }


  /**
   * This will check if phone number is valid or not
   */
  checkPhoneNumberValid(){
    this.errorMobile = false;
    this.errorContactNumber = false;
    if(this.phoneNumberInput['selectedCountry'] != null){
      var data = {
        "phoneNumber": this.f['reg_contact_number'].value,
        "country":this.phoneNumberInput['selectedCountry']['countryCode']
      }
      this.authService.phoneNumberValidating(data).subscribe(
        (data: any) => {
          if (data.code == 1) {
            if(!this.f['isAgree'].value){
              this.errorAgree = true;
            }else{
              this.errorAgree = false;
              this.onSubmit();
            }
          } else {
            this.errorContactNumber = true;
          }
          this.disableRegister = false;
        }, (htError: HttpErrorResponse) => {
          console.log(htError);
          this.disableRegister = false;
        });
    }else{
      this.errorMobile = true;
      this.errorContactNumber = true;
      this.errorMobileText = "Please select the country"
    }

  }

  onAgreeChange(event){
   if(event.target.checked){
    this.errorAgree = false;
    this.f.isAgree.setValue(true);
   }else{
    this.f.isAgree.setValue(false);
   }
  }

  onSubmit() {
    this.disableRegister = true;
    const user = new User();
    user.setName(this.f['reg_name'].value);
    user.setCompanyName(this.f['reg_company_name'].value);
    user.setMobileNumber(this.f['reg_contact_number'].value);
    user.setEmail(this.f['reg_email'].value);
    user.setPassword(this.f['reg_password'].value);
    user.setConfirmPassword(this.f['confirmpassword'].value);
    user.setUserProfile(this.f['reg_user_profile'].value);
    this.authService.registerUserData(user).subscribe(
      (data: any) => {
        if (data.code === '1' || (data.code === 1)) {
          localStorage.setItem('registrationMessage', data.message);
          // this.authService.sendEmailVerification(data.emailId).subscribe(
          //   (emailData: any) => {
          //     console.log('succes data');
          //   }, (htError: HttpErrorResponse) => {
          //     console.log('error occrured');
          //     console.log(htError);
          //   });
          // console.log(localStorage.getItem('registrationMessage'));
          // this.router.navigate(['/login']);
          var reqData = {
            "token":data.accessToken,
            "email":this.f['reg_email'].value,
            "mobile":data['reg_contact_number']
          }
          localStorage.setItem('userData',JSON.stringify(reqData));
          const url = redirectUrls.redirectWelcome+'?data=' + btoa(JSON.stringify(reqData));
          window.location.href = url;
        } else {
          if ('reg_name' in data) {
            if (data.reg_name.code === 0 || data.reg_name.code === '0') {
              this.errorName = true;
            }
          } else {
            this.errorName = false;
          }
          if ('reg_company_name' in data) {
            if (data.reg_company_name.code === 0 || data.reg_company_name.code === '0') {
              this.errorCompanyName = true;
            }
          } else {
            this.errorCompanyName = false;
          }
          if ('reg_contact_number' in data) {
            if (data.reg_contact_number.code === 0 || data.reg_contact_number.code === '0') {
              this.errorContactNumber = true;
            }
          } else {
            this.errorContactNumber = false;
          }
          if ('reg_email' in data) {
            if (data.reg_email.code === 0 || data.reg_email.code === '0') {
              this.errorEmail = true;
              this.errorEmailExist = true;
            }
          } else {
            this.errorEmail = false;
            this.errorEmailExist = false;
          }
          if ('reg_password' in data) {
            if (data.reg_password.code === 0 || data.reg_password.code === '0') {
              this.errorInPassword = true;
              this.errorPassword = data.reg_password.message;
            }
          } else {
            this.errorInPassword = false;
            this.errorPassword = "";
          }
          if ('confirmpassword' in data) {
            if (data.confirmpassword.code === 0 || data.confirmpassword.code === '0') {
              this.errorConfirmPasswordMsg = data.confirmpassword.message;
              this.errorConfirmpassword = true;
            }
          } else {
            this.errorConfirmPasswordMsg = "";
            this.errorConfirmpassword = false;
          }
        }
        this.disableRegister = false;
      }, (htError: HttpErrorResponse) => {
        console.log(htError);
        this.disableRegister = false;
      });
  }

  changeSubscription(event) {
    for (let profileGroup of this.userProfileGroups) {
      if (profileGroup.id == event.target.value) {
        this.subscriptionDescription = profileGroup.subscription_description;
      }
    }
  }

  onKey(e){
    this.errorContactNumber = false;
    this.errorMobile= false;
    if(this.f['reg_contact_number'].value.length > 5){
      if(this.f['reg_contact_number'].value.substring(0,5) == "+9474"){
        this.registerForm.get('reg_contact_number').clearValidators();
        this.registerForm.controls['reg_contact_number'].updateValueAndValidity();
      }else{
        this.registerForm.get('reg_contact_number').setValidators([Validators.required]);
        this.registerForm.controls['reg_contact_number'].updateValueAndValidity();
      }
    }else{
      this.registerForm.get('reg_contact_number').clearValidators();
      this.registerForm.controls['reg_contact_number'].updateValueAndValidity();
      this.registerForm.get('reg_contact_number').setValidators([Validators.required]);
      this.registerForm.controls['reg_contact_number'].updateValueAndValidity();      
    }
  }
  onPasswordKey(e){
    this.errorConfirmpassword = false;
  }

  password(){
    this.show = !this.show;
  }

  confirmPassword(){
    this.confirmShow = !this.confirmShow;
  }
}
