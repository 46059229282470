//localhost
const url = 'https://tgapp.inqbaytor.me/account/welcome'; 
// const url = 'http://localhost:4200//account/welcome'; 

//payment Test
// const url = 'https://paymentupdateui.enabledbeings.com/account/welcome'

//pp
// const url = 'https://tguiv3pp.enabledbeings.com/account/welcome';

//test
// const url = 'https://tguiv3.enabledbeings.com/account/welcome';

//live
// const url = 'https://app.ticketgadget.com.au/account/welcome';

//NDC
// const url = 'https://tguindcui.enabledbeings.com/account/welcome'

//hotel
// const url = 'https://tguiv4pp.enabledbeings.com/account/welcome'


export const redirectUrls = {
    redirectWelcome:`${url}`,
}