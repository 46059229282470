<app-header></app-header>
<ng-http-loader backgroundColor="#F7721E" spinner="sk-wave"></ng-http-loader>

<style type="text/css">
  .body {
    height: 100%;
    position: relative;
    position: relative;
    padding-bottom: 6rem;
    min-height: 687px;
  }

  .wrapper {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 85px;
  }
</style>
<div class="body">
  <div class="wrapper">
    <div class="col-md-12 top-header">
      <h3>Login</h3>
    </div>
    <div class="content-wrapper" style="min-height: 600px;">
      <div class="container-fluid">

        <div id="login-interface">
          <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
            <div class="panel-body boxshadow" style="padding-bottom: 30px;">
              <div class="box box-default">
                <div class="box-body">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p>Log in to your Ticket Gadget account here. </p>
                  </div>
                  <div id="error-messages" class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [hidden]="!errorMessage">
                    <div class="alert alert-danger alert-dismissible">
                      <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="closeErrorAlert()">×</button>
                      <p class="erMessage">{{ errorMessage }}</p>
                    </div>
                  </div>
                  <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [hidden]="!successMessage">
                    <div class="alert alert-success alert-dismissible">
                      <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="closeSuccessMessage()">×</button>
                      <p>{{ successMessage }}</p>
                    </div>
                  </div> -->
                  <form [formGroup]='loginForm' (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label for="email">Email
                          <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" placeholder="Enter Email Address" formControlName="email" [ngClass]="{ 'error-border': f.submitted && loginForm.controls['email'].invalid }"
                          required/>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label for="password">Password
                          <span class="text-danger">*</span>
                        </label>
                        <input type="password" class="form-control" placeholder="Enter Password" formControlName="password" [ngClass]="{ 'error-border': f.submitted && loginForm.controls['password'].invalid }"
                          required minlength="6" />
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-bottom: 10px;">
                      <input type="checkbox" id="remember" name="remember">
                      <label for="remember" class="label-checkbox">Remember Me</label>
                      <a class="pull-right bold-on-hover" routerLink="/forgot/password">Forgot Password?</a>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 recaptcha-container">
                      <div class="g-recaptcha" data-sitekey="" data-callback="enableSubmitButton" style="transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;"></div>
                    </div>

                  <div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <a href="register" class="btn btn-primary btn-block btn-flat">Register</a>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <button type="submit" id="btnLogin" class="btn btn-primary btn-block btn-flat">Login</button>
                        <!--   
                                                             <button type="submit" id="btnLogin" class="btn btn-primary btn-block btn-flat" disabled>Login</button>-->
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>