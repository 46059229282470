import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-expiary',
  templateUrl: './session-expiary.component.html',
  styleUrls: ['./session-expiary.component.scss']
})
export class SessionExpiaryComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

  logout() {
    localStorage.removeItem('user');
  }

}
