import { Platform } from '@angular/cdk/platform';
import { OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { redirectUrls } from '../helpers/redirect-urls';
import { UserAuthenticaionService } from '../user-authenticaion.service';
// declare function scrollMethod():any;
var HomeComponent = /** @class */ (function () {
    function HomeComponent(router, activatedRoute, myElement, userAuthService, scrollToService, platform) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.myElement = myElement;
        this.scrollToService = scrollToService;
        this.platform = platform;
        this.linkNum = 0;
        this.showBanner = false;
        this.subscription = [];
        this.totalSubscription = [];
        this.activeSubsciptionData = 1;
        this.loading = false;
        // maps the appropriate column to fields property
        this.fields = { text: 'country', value: 'key' };
        this.value = 1;
        // set the height of the popup element
        this.height = '250px';
        // set the placeholder to DropDownList input element
        this.watermark = 'Country';
        this.data = [
            { key: 1, country: 'Sri Lanka', flagImg: '50xticket-gadget', link: 'https://crazyjets.com' },
        ];
        this.userAuthService = userAuthService;
    }
    HomeComponent.prototype.toggleDestination = function () {
        this.ngxScrollToDestination = this.ngxScrollToDestination === 'about' ? 'services' : 'faq';
    };
    // @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    //   // console.log($event);
    //   // console.log("scrolling");
    // } 
    HomeComponent.prototype.triggerScrollTo = function () {
        /**
         * @see NOTE:1
         */
        var config = {
            container: 'custom-container',
            target: 'faq',
            duration: 650,
            easing: 'easeOutElastic',
            offset: 20
        };
        this.scrollToService.scrollTo(config);
    };
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onCountryChange(1);
        this.checkPlatForm();
        this.activatedRoute.data.subscribe(function (data) {
            var idx = 0;
            switch (data.kind) {
                case "about": {
                    _this.linkNum = 1;
                    idx = 1;
                    break;
                }
                case "why": {
                    _this.linkNum = 2;
                    idx = 2;
                    break;
                }
                case "contact": {
                    _this.linkNum = 3;
                    idx = 3;
                    break;
                }
                case "subscription": {
                    _this.linkNum = 4;
                    idx = 4;
                    break;
                }
            }
            if (idx == 1) {
                setTimeout(function () {
                    _this.aboutSection();
                }, 100);
            }
            else if (idx == 2) {
                setTimeout(function () {
                    _this.whySection();
                }, 100);
            }
            else if (idx == 3) {
                setTimeout(function () {
                    _this.contactSection();
                }, 100);
            }
            else if (idx == 4) {
                setTimeout(function () {
                    _this.subscriptionSection();
                }, 100);
            }
            return;
        });
    };
    HomeComponent.prototype.onScroll = function ($event) {
        console.log("sce");
    };
    HomeComponent.prototype.windowScroll = function () {
        // if (
        //   document.body.scrollTop > 50 ||
        //   document.documentElement.scrollTop > 50
        // ) {
        //   document.getElementById('topnav').classList.add('nav-sticky');
        // } else {
        //   document.getElementById('topnav').classList.remove('nav-sticky');
        // }
        // if (document.body.scrollTop > 100 ||
        //   document.documentElement.scrollTop > 100) {
        //   document.getElementById('back-to-top').style.display = 'inline';
        // } else {
        //   document.getElementById('back-to-top').style.display = 'none';
        // }
    };
    HomeComponent.prototype.onSectionChange = function (sectionId) {
        // alert(sectionId)
        // this.currentSection = sectionId;
        console.log("test");
    };
    /**
     * This will get the available subscription data
     */
    HomeComponent.prototype.getSubscriptionData = function (region) {
        var _this = this;
        this.loading = true;
        this.userAuthService.subscriptionDetails(region).subscribe(function (res) {
            if (res.code === 1) {
                _this.totalSubscription = res.other;
                _this.subscription = [];
                _this.totalSubscription['travel_agent'].forEach(function (e) {
                    // if(e.type == 1){
                    _this.subscription.push(e);
                    // }
                });
            }
            _this.loading = false;
        }, function (errorEwaponse) {
        });
    };
    /**
     * This will trigger when user click on change sunscription type;
     */
    HomeComponent.prototype.changeSubscriptionType = function (type) {
        var _this = this;
        this.activeSubsciptionData = type;
        if (type == 1) {
            this.subscription = [];
            this.totalSubscription['travel_agent'].forEach(function (e) {
                _this.subscription.push(e);
            });
        }
        else if (type == 2) {
            this.subscription = [];
            this.totalSubscription['corporate'].forEach(function (e) {
                _this.subscription.push(e);
            });
        }
    };
    HomeComponent.prototype.checkTokenIsValid = function () {
        var _this = this;
        this.userAuthService.checkTokenisValid().subscribe(function (res) {
            if (res) {
                // this.router.navigate(['/dashboard']);
                var data = localStorage.getItem('accessToken');
                // const url = 'http://tguiv3.enabledbeings.com/account/welcome?token=' + data;
                var cjwData = localStorage.getItem("loggedDataCJW");
                if (cjwData != null && cjwData != undefined) {
                    var token_1 = JSON.parse(cjwData).accessToken;
                    // var cjwToken = {
                    //   "cjwToken":token,
                    // }
                    _this.userAuthService.settingCjwToken(token_1).subscribe(function (res) {
                        if (res) {
                            //Test
                            // const url = 'https://tguiv3.enabledbeings.com/account/welcome?token=' + data+'&ref='+res.reference
                            //Live
                            var url = redirectUrls.redirectWelcome + '?token=' + data + '&ref=' + res.reference;
                            window.location.href = url;
                        }
                    }, function (errorEwaponse) {
                    });
                }
                else {
                    // const url = 'http://localhost:4200/dashboard?token=' + data
                    // const url = 'https://tguiv3.enabledbeings.com/account/welcome?token=' + data
                    //Live
                    var reqData;
                    if (localStorage.getItem('userData') != null) {
                        var user = JSON.parse(localStorage.getItem('userData'));
                        reqData = {
                            "token": user.token,
                            "email": user.email,
                            "mobile": user.mobile
                        };
                    }
                    else {
                        var token = localStorage.getItem('accessToken');
                        reqData = {
                            "token": token,
                            "email": '',
                            "mobile": ''
                        };
                    }
                    var url = redirectUrls.redirectWelcome + '?data=' + btoa(JSON.stringify(reqData));
                    window.location.href = url;
                }
            }
        }, function (errorEwaponse) {
        });
    };
    HomeComponent.prototype.onClickOnLogin = function () {
        if (localStorage.getItem('accessToken') != null && localStorage.getItem('user')) {
            this.checkTokenIsValid();
        }
        else {
            this.router.navigateByUrl('/login');
        }
    };
    HomeComponent.prototype.onCountryChange = function (id) {
        if (id == 1) {
            this.selctedFlag = "assets/images/global.png";
            this.selectedCountry = "Global";
            sessionStorage.setItem('selectedCountry', 'lk');
            // const url = "https://ticketgadget.com"
            // window.location.href = url;    
            this.getSubscriptionData('US');
        }
        else if (id == 2) {
            this.selctedFlag = "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg";
            this.selectedCountry = "Australia";
            sessionStorage.setItem('selectedCountry', 'au');
            var url = "https://ticketgadget.com.au";
            window.location.href = url;
            this.getSubscriptionData('AU');
        }
        else if (id == 3) {
            this.selctedFlag = "https://cdn.britannica.com/17/3017-004-DCC13F9D/Flag-New-Zealand.jpg";
            this.selectedCountry = "New Zealand";
            sessionStorage.setItem('selectedCountry', 'nz');
            var url = "https://ticketgadget.co.nz";
            window.location.href = url;
            this.getSubscriptionData('NZ');
        }
    };
    // aboutSection() {
    //   let el = document.getElementById('about');
    //   el.scrollIntoView({behavior:'smooth', block: 'center'});
    // }
    HomeComponent.prototype.aboutSection = function () {
        var element = document.getElementById('about');
        var headerOffset = 80;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    };
    HomeComponent.prototype.whySection = function () {
        // let el = document.getElementById('services');
        // el.scrollIntoView({behavior:'smooth', block: 'center'});
        var element = document.getElementById('services');
        var headerOffset = 80;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    };
    HomeComponent.prototype.subscriptionSection = function () {
        var element = document.getElementById('subscription');
        var headerOffset = 80;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    };
    HomeComponent.prototype.contactSection = function () {
        var element = document.getElementById('contact');
        // el.scrollIntoView({behavior:'smooth', block: 'center'});
        // var element = document.getElementById('subscription');
        var headerOffset = 150;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    };
    //on view click action, check the platform and redirect to the store url
    HomeComponent.prototype.onViewClick = function () {
        if (this.platform.IOS) {
            var url = "https://apps.apple.com/app/id1526102889";
            window.location.href = url;
        }
        else if (this.platform.ANDROID || this.platform.BLINK) {
            var url = "https://play.google.com/store/apps/details?id=com.inq.ticketgadget";
            window.location.href = url;
        }
    };
    HomeComponent.prototype.onClickOnClose = function () {
        this.showBanner = false;
        sessionStorage.setItem('showBanner', JSON.stringify(this.showBanner));
    };
    HomeComponent.prototype.checkPlatForm = function () {
        if (this.platform.IOS || this.platform.ANDROID || this.platform.BLINK) {
            this.showBanner = true;
            var bannerStatus = JSON.parse(sessionStorage.getItem('showBanner'));
            if (bannerStatus != null) {
                this.showBanner = bannerStatus;
            }
        }
        if (this.platform.IOS) {
            this.priceDesc = "FREE - On the App Store";
        }
        else if (this.platform.ANDROID || this.platform.BLINK) {
            this.priceDesc = "FREE - In Google Play";
        }
    };
    ;
    return HomeComponent;
}());
export { HomeComponent };
