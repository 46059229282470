<div class="modal-header">
  <h4 class="modal-title pull-left">Customize E-Ticket & Download</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="col-md-12">
    <div class="col-md-4" ng-init="theme = 1">
      <label>Theme</label>
      <br>
      <label>
        <input type="radio" value="1" name="radiotheme" id="radioButtonThemeBNW" (click)="getTheme('blackandwhite')"> Black & White
      </label>
      <br/>
      <label>
        <input type="radio" value="2" name="radiotheme" [checked]="true" (click)="getTheme('colored')"> Coloured
      </label>
      <br/>
    </div>
    <div class="col-md-8" ng-init="showprice = 1">
      <label>Do you want to show price details in the ticket?</label>
      <br>
      <label>
        <input type="radio" value="1" id="radioButtonPriceShow" name="radioshowprice" (click)="getShowPriceValues('true')"> Yes
      </label>
      <br/>
      <label>
        <input type="radio" value="2" name="radioshowprice" [checked]="true" (click)="getShowPriceValues('false')"> No
      </label>
      <br/>
    </div>
  </div>
  <div class="btn-wrapper">
    <a (click)="downloadETicket()">
      <button class="btn btn-primary btn-flat">Submit</button>
    </a>&nbsp;&nbsp;&nbsp;
    <button class="btn btn-default btn-flat" (click)="cancelPopup()">Cancel</button>
  </div>
</div>