<div class="modal-header">
  <h4 class="modal-title pull-left">Delete Confirmation</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <center>
    <div class="row">Are you sure you want to proceed?</div>
    <br />
    <div class="row">
      <button (click)="confirmDelete()" class="btn btn-danger btn-flat">Yes</button>&nbsp;&nbsp;&nbsp;
      <button class="btn btn-secondary btn-flat" (click)="cancelPopup()">No</button>
    </div>
  </center>
</div>