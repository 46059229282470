import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TerminalModule } from 'primeng/terminal';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { FeaturesComponent } from './features/features.component';
import { FaqComponent } from './faq/faq.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { LoggedHeaderComponent } from './logged/logged-header/logged-header.component';
import { LoggedFooterComponent } from './logged/logged-footer/logged-footer.component';
import { LoggedMenuComponent } from './logged/logged-menu/logged-menu.component';
import { StylesComponentComponent } from './styles-component/styles-component.component';
import { TopIncludeCssComponent } from './top-include-css/top-include-css.component';
import { BottomIncludeJsComponent } from './bottom-include-js/bottom-include-js.component';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { PhoneNumberVerificationComponent } from './phone-number-verification/phone-number-verification.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConfirmRetrieveComponent } from './popup-modals/confirm-retrieve/confirm-retrieve.component';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';
import { EticketOptionsComponent } from './popup-modals/eticket-options/eticket-options.component';
import { CountdownModule } from 'ngx-countdown';
import { BsDropdownModule, AlertModule } from 'ngx-bootstrap';
import { EmailEticketComponent } from './popup-modals/email-eticket/email-eticket.component';
import { TimerExpiaryComponent } from './popup-modals/timer-expiary/timer-expiary.component';
import { SessionExpiaryComponent } from './popup-modals/session-expiary/session-expiary.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { HttpErrorPopupComponent } from './popup-modals/http-error-popup/http-error-popup.component';
import { UserSessionExpireComponent } from './popup-modals/user-session-expire/user-session-expire.component';
import { Injector } from '@angular/core';
import { DeleteConfirmComponent } from './popup-modals/delete-confirm/delete-confirm.component';
import { VardhanaWalletComponent } from './popup-modals/vardhana-wallet/vardhana-wallet.component';
import { OldPhoneNumberVerificationComponent } from './old-phone-number-verification/old-phone-number-verification.component';
import { ChartsModule } from 'ng2-charts';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePhoneNumberComponent } from './change-phone-number/change-phone-number.component';
import { ResendEmailVerificationComponent } from './resend-email-verification/resend-email-verification.component';
import { ItineraryPrinterComponent } from './logged/itinerary-printer/itinerary-printer.component';
import { AccordionModule } from "ngx-bootstrap";
import { SelectDropDownModule } from 'ngx-select-dropdown';
import * as $ from 'jquery';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxGalleryModule } from "ngx-gallery";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { PriceFormatPipeModuleModule } from 'src/app/price-format-pipe-module/price-format-pipe-module.module';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { CardModule } from 'ngx-card/ngx-card';
// import { NgSelectModule } from '@ng-select/ng-select';

import { DatePipe } from '@angular/common';
import { ErrorpopupComponent as GErrorPopupComponent } from './popup-modals/errorpopup/errorpopup.component';
import { AuthRegisterredirecterComponent } from './auth-registerredirecter/auth-registerredirecter.component';
import { HttpErrorInterceptor } from './http-error-interceptor';
import { LoginfoComponent } from './loginfo/loginfo.component';
import { CouponComponent } from './coupon/coupon.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ShowUpdatedPasswordPopupComponent } from './popup-modals/show-updated-password-popup/show-updated-password-popup.component';
import { ShowRemarkDataComponent } from './popup-modals/show-remark-data/show-remark-data.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { UserGuidelinesComponent } from './user-guidelines/user-guidelines.component';
import { ForgetPasswordEmailVerificationComponent } from './forget-password-email-verification/forget-password-email-verification.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FeaturesComponent,
    FaqComponent,
    RegisterComponent,
    LoginComponent,
    LoggedHeaderComponent,
    LoggedFooterComponent,
    LoggedMenuComponent,
    StylesComponentComponent,
    TopIncludeCssComponent,
    BottomIncludeJsComponent,
    PhoneNumberVerificationComponent,
    EmailVerificationComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ConfirmRetrieveComponent,
    EticketOptionsComponent,
    EmailEticketComponent,
    TimerExpiaryComponent,
    SessionExpiaryComponent,
    HttpErrorPopupComponent,
    UserSessionExpireComponent,
    DeleteConfirmComponent,
    VardhanaWalletComponent,
    OldPhoneNumberVerificationComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    ChangePhoneNumberComponent,
    ResendEmailVerificationComponent,
    ItineraryPrinterComponent,
    GErrorPopupComponent,
    AuthRegisterredirecterComponent,
    LoginfoComponent,
    CouponComponent,
    ShowUpdatedPasswordPopupComponent,
    ShowRemarkDataComponent,
    UserGuidelinesComponent,
    ForgetPasswordEmailVerificationComponent
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    CountdownModule,
    HttpClientModule,
    HttpModule,
    NgHttpLoaderModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    SelectDropDownModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ChartsModule,
    NgxIntlTelInputModule,
    InternationalPhoneNumberModule,
    TerminalModule,
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxGalleryModule,
    SlickCarouselModule,
    AccordionModule.forRoot(),
    PriceFormatPipeModuleModule,
    Nl2BrPipeModule,
    CardModule,
    QRCodeModule,
    ScrollToModule.forRoot(),
    // NgSelectModule,
  ],
  schemas: [
    NO_ERRORS_SCHEMA,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    AuthGuard,
    ComParentChildService,
    Title,
    BsModalService,
    BsModalRef,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmRetrieveComponent,
    EticketOptionsComponent,
    EmailEticketComponent,
    SessionExpiaryComponent,
    TimerExpiaryComponent,
    HttpErrorPopupComponent,
    UserSessionExpireComponent,
    DeleteConfirmComponent,
    VardhanaWalletComponent,
    GErrorPopupComponent,
    ShowUpdatedPasswordPopupComponent,
    ShowRemarkDataComponent,
  ]
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
