<ng-http-loader backgroundColor="#F7721E" spinner="sk-wave"></ng-http-loader>
<div class="div-body" data-spy="scroll" data-target=".navbar" data-offset="50">
  <div class="container" (window:scroll)="windowScroll()">
    <div class="fixed-top p-0 m-0">
      <div class="row m-0 p-3 banner" *ngIf="showBanner">
        <div
          style="width: 10%"
          class="close text-left"
          (click)="onClickOnClose()"
        >
          <i class="fas fa-times"></i>
        </div>
        <div style="width: 20%">
          <img
            src="../../assets/tg/assets/logo-sm.png"
            alt="logo-sm.png"
            class="img-fluid"
            width="45"
            height="45"
          />
        </div>
        <div style="width: 50%">
          <p class="m-0">Ticket Gadget</p>
          <p class="m-0" style="font-size: 11px; color: rgb(194, 192, 192)">
            {{ priceDesc }}
          </p>
        </div>
        <div style="width: 20%" class="text-right">
          <button class="btn view-btn" (click)="onViewClick()">View</button>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="container">
          <div>
            <a class="navbar-brand" href="#" style="padding-top: 8px;">
              <img
                src="assets/img/50xticket-gadget.png"
                alt=""
                class="img-fluid"
                width="200"
              />
            </a>
          </div>
          <!-- <a class="navbar-brand" href="#"><img src="assets/img/50xticket-gadget.png" alt="" class="img-fluid"></a> -->
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse navul" id="navbarTogglerDemo02">
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
              <li class="nav-item" [class.active]="linkNum == 0">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#home'"
                  routerLink="/"
                  href="#home"
                  >Home</a
                >
                <!-- <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a> -->
              </li>
              <li [class.active]="linkNum == 1">
                <a
                  class="nav-link"
                  (click)="triggerScrollTo()"
                  routerLink="/about"
                  href="#about"
                  >About</a
                >
                <!-- <a class="nav-link" href="#">Link</a> -->
              </li>
              <li [class.active]="linkNum == 2">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#services'"
                  [ngxScrollToOffset]="-80"
                  routerLink="/why"
                  href="#why-us"
                  >Why</a
                >
              </li>
              <li [class.active]="linkNum == 3">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#contact'"
                  [ngxScrollToOffset]="-80"
                  routerLink="/contact"
                  >Contact</a
                >
              </li>
              <li [class.active]="linkNum == 4">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#subscription'"
                  [ngxScrollToOffset]="-80"
                  routerLink="/subscription"
                  href="#subscription"
                  >Subscriptions</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#register'"
                  routerLink="/register"
                  >Register</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#home'"
                  (click)="onClickOnLogin()"
                  >Login</a
                >
              </li>
            </ul>
          </div>
          <div class="dropdown">
            <button class="dropbtn">
              <a
                ><img src="{{ selctedFlag }}" width="20" height="15" style="object-fit: contain;"/><span
                  style="padding-left: 10px"
                  >{{ selectedCountry }}</span
                ><i style="padding-left: 10px" class="fas fa-caret-down"></i
              ></a>
            </button>

            <div class="dropdown-content">
              <a href="#" (click)="onCountryChange(1)">
                <img
                  src="assets/images/global.png"
                  width="20"
                  height="15"
                  style="object-fit: contain;"
                />
                Global</a
              >
              <a href="#" (click)="onCountryChange(2)">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg"
                  width="20"
                  height="23"
                  style="object-fit: contain;"
                />
                Australia</a
              >
              <a href="#" (click)="onCountryChange(3)">
                <img
                  src="https://cdn.britannica.com/17/3017-004-DCC13F9D/Flag-New-Zealand.jpg"
                  width="20"
                  height="23"
                  style="object-fit: contain;"
                />
                New Zealand</a
              >
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <!-- <header id="header">
    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
      rel="stylesheet">
    <div class="container d-flex">

      <div class="logo mr-auto">
        <a href="index.html">
          <img src="assets/img/50xticket-gadget.png" alt="" class="img-fluid">
        </a>
      </div>

      <nav class="nav-menu d-none d-lg-block  mobile-nav">
        <ul>
          <li [class.active]="linkNum == 0">
            <a routerLink="/">Home</a>
          </li>
          <li [class.active]="linkNum == 1">
            <a routerLink="/about" class="internal-cl">About</a>
          </li>
          <li [class.active]="linkNum == 2">
            <a routerLink="/why" class="internal-cl">Why</a>
          </li>
          <li [class.active]="linkNum == 3">
            <a routerLink="/contact" class="internal-cl">Contact</a>
          </li>
          <li>
            <a routerLink="/register">Register</a>
          </li>
          <li>
            <a routerLink="/login">Login</a>
          </li>
        </ul>
      </nav>
    </div>
  </header> -->

  <div
    appScrollspy
    [spiedTags]="['SECTION']"
    (sectionChange)="onSectionChange($event)"
  >
    <!-- ======= Hero Section ======= -->
    <section
      id="hero"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <div class="container" data-aos="fade-in">
        <h1>The Ticketing Super App</h1>
        <h2>Democratising travel services through one awesome app</h2>
        <div class="d-flex align-items-center">
          <!-- <i class="bx bxs-right-arrow-alt get-started-icon"></i> -->
          <a
            href="https://play.google.com/store/apps/details?id=com.inq.ticketgadget"
          >
            <img
              src="../../assets/images/google-play-badge.png"
              width="170"
              height="70"
            />
          </a>
          <a href="https://apps.apple.com/app/id1526102889">
            <img
              src="../../assets/images/app-store-badge.png"
              width="185"
              height="70"
            />
          </a>
          <!-- <i class="bx bxl-apple get-started-icon"></i>
          <a href="https://apps.apple.com/app/id1526102889" class="btn-get-started scrollto">iOS</a>
          <i class="bx bxl-play-store get-started-icon"></i>
          <a href="https://play.google.com/store/apps/details?id=com.inq.ticketgadget"
            class="btn-get-started scrollto">Android</a> -->
        </div>
      </div>
    </section>
    <!-- End Hero -->
    <!-- ======= Why Us Section ======= -->
    <section id="why-us" class="why-us">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-5" data-aos="fade-up">
            <div class="content">
              <h3>Disrupting the travel industry</h3>
              <p>
                We are democratising travel services by reducing cost, and
                barriers to entry by levelling the playing field in a secure and
                responsible way using technology. Ticket Gadget is a one-stop
                shop for booking air tickets, hotels, tours, cruises and car
                hires more efficiently and conveniently than conventional
                single-purpose alternatives. Whether you are a travel company or
                a corporate travel manager, we have the perfect solution for
                you.
              </p>
              <p>
                Our revolutionary technology has helped businesses to transform
                from conventional travel services to more accessible, economical
                and efficient travel services.
              </p>
            </div>
          </div>
          <div class="col-xl-8 col-lg-7 d-flex">
            <div class="icon-boxes d-flex flex-column justify-content-center">
              <div class="row">
                <div
                  class="col-xl-4 d-flex align-items-stretch"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div class="icon-box mt-4 mt-xl-0">
                    <i class="fas fa-glass-cheers"></i>
                    <h4>Independence</h4>
                    <p>
                      Issue all your tickets in one place instantly from
                      anywhere anytime with zero wait time.
                    </p>
                  </div>
                </div>
                <div
                  class="col-xl-4 d-flex align-items-stretch"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div class="icon-box mt-4 mt-xl-0">
                    <i class="bx bx-cut"></i>
                    <h4>Cut the Red Tape</h4>
                    <p>
                      You no longer have to carry the burden of obtaining and
                      maintaining regulatory requirements such as ticketing
                      authority.
                    </p>
                  </div>
                </div>
                <div
                  class="col-xl-4 d-flex align-items-stretch"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div class="icon-box mt-4 mt-xl-0">
                    <i class="fas fa-hand-holding-usd"></i>
                    <h4>Save Money</h4>
                    <p>
                      Start your journey with zero setup cost and get access to
                      the best fares through our direct partnerships with
                      airlines, GDS and other inventory providers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Why Us Section -->

    <!-- ======= About Section ======= -->
    <section id="about" class="about section-bg">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch"
            data-aos="fade-right"
          >
            <!-- <a href="https://www.youtube.com/watch?v=d-Y2hiqQHog" class="venobox play-btn mb-4" data-vbtype="video"
              data-autoplay="true"></a> -->
          </div>

          <div
            class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
          >
            <h4 data-aos="fade-up">About us</h4>
            <h3 data-aos="fade-up">
              Reengineering, Digitising & Revolutionising
            </h3>
            <p data-aos="fade-up">
              We are on a journey to reengineering the distribution of travel
              services by democratising and simplifying the processes to fit the
              current digital world. We offer searching, booking, ticketing,
              ancillary upselling and all post-ticketing management services
              through our super app. We are dedicated to empowering businesses
              by providing a simple and seamless experience.
            </p>

            <div class="icon-box" data-aos="fade-up">
              <div class="icon">
                <i class="bx bxs-plane-alt"></i>
              </div>
              <h4 class="title">
                <a>GDS and NDC Fares</a>
              </h4>
              <p class="description">
                You can book and ticket GDS as well as NDC fares in one place.
                We remove the need to log into multiple portals for fare
                comparisons.
              </p>
            </div>

            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon">
                <i class="bx bx-paper-plane"></i>
              </div>
              <h4 class="title">
                <a>Low-Cost and Direct Inventory Fares</a>
              </h4>
              <p class="description">
                Get access to low-cost carrier fares and best pricing via direct
                airline connectivity.
              </p>
            </div>

            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon">
                <i class="bx bx-laptop"></i>
              </div>
              <h4 class="title">
                <a>Advance Flight Search Tool</a>
              </h4>
              <p class="description">
                Our revolutionary Advance Flight Search Tool lets you make
                bookings using your favourite GDS commands. Just like a GDS but
                better, simpler, faster, smarter, and mobile.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Section -->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services section-bg">
      <div class="container">
        <div class="section-title" data-aos="fade-up">
          <h2>Why Ticket Gadget?</h2>
          <p>
            Whether you are a travel company or a corporate travel manager,
            Ticket Gadget is the perfect solution for all travel services.
          </p>
          <!-- <p>IATA accredited travel agents can now issue their tickets through ticket gadget anywhere anytime with
            unlimited
            capping. We let you concentrate on important things and we take care of everything else.</p> -->
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6" data-aos="fade-up">
            <div class="icon-box">
              <div class="icon">
                <i class="icofont-law-document"></i>
              </div>
              <h4 class="title">
                <a>NO UPFRONT COST</a>
              </h4>
              <p class="description">
                Just register online and you are good to go
              </p>
            </div>
          </div>

          <div
            class="col-lg-6 col-md-6"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="icon-box">
              <div class="icon">
                <!-- <i class="icofont-law-document"></i> -->
                <i class="icofont-clock-time"></i>
              </div>
              <h4 class="title">
                <a>NO WAIT TIME</a>
              </h4>
              <p class="description">
                Work on your schedule, anywhere at anytime.
              </p>
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div class="icon-box">
              <div class="icon">
                <i class="icofont-thumbs-up"></i>
              </div>
              <h4 class="title">
                <a>zero issuing fees</a>
              </h4>
              <p class="description">You don't pay additional commissions to issue tickets. You get to keep all the
                airline commissions to your
                self.
              </p>
            </div>
          </div> -->
          <div
            class="col-lg-6 col-md-6"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div class="icon-box">
              <div class="icon">
                <i class="icofont-simple-smile"></i>
              </div>
              <h4 class="title">
                <a>AVAILABLE 24/7</a>
              </h4>
              <p class="description">Work on your schedule anywhere anytime</p>
            </div>
          </div>

          <div
            class="col-lg-6 col-md-6"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div class="icon-box">
              <div class="icon">
                <i class="icofont-bill"></i>
              </div>
              <h4 class="title">
                <a>NO BANK GUARANTEES</a>
              </h4>
              <p class="description">Just top up and issue</p>
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
            <div class="icon-box">
              <div class="icon">
                <i class="icofont-money-bag"></i>
              </div>
              <h4 class="title">
                <a>Best Segment Incentives</a>
              </h4>
              <p class="description">We have segment incentive plans that suit every Travel Agent. Even if you do 10
                segments or 10,000 segments,
                we got you covered.</p>
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <!-- End Services Section -->

    <!-- ======= Pricing Section ======= -->
    <section id="subscription" class="pricing">
      <div class="container">
        <div class="section-title">
          <h2 data-aos="fade-up">Subscriptions</h2>
          <p data-aos="fade-up">
            Whether you are a travel agent or a corporate travel manager we have
            the perfect subscriptions for you to choose from.
          </p>
        </div>
        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-12 d-flex justify-content-center portfolio">
            <ul id="portfolio-flters">
              <li [ngClass]="{'filter-active': activeSubsciptionData === 1}"  (click)="changeSubscriptionType(1)">Travel Agent</li>
              <li [ngClass]="{'filter-active': activeSubsciptionData === 2}"
              (click)="changeSubscriptionType(2)">Corporate Travel Manager</li>
            </ul>
          </div>
        </div>
        <div *ngIf="loading" class="row">
          <div class="col-md-6 col-xl-3">
            <div class="shimmerBox shine"></div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="shimmerBox shine"></div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="shimmerBox shine"></div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="shimmerBox shine"></div>
          </div>
        </div>
        <p *ngIf="!loading && subscription.length == 0" class="text-center">Not available.</p>
        <div class="row" *ngIf="!loading && subscription.length > 0" style="justify-content:center">
            <div
              class="col-lg-3 col-md-6 mt-4 mt-md-0"
              data-aos="fade-up"
              data-aos-delay="100"
              *ngFor="let item of subscription"
            >
            <div class="box" [ngClass]="{'featured': item.is_featured == 1}">
              <span *ngIf="item.sash !=''" class="advanced">{{item.sash}}</span>
              <h3>{{ item.name }}</h3>
              <h4 *ngIf="item.price != null">
                <sup>{{ item.currency }}</sup
                >{{ item.price }}<span> / {{ item.price_frequency }}</span>
              </h4>
              <h4 *ngIf="item.price == null">
                <span>
                  {{ item.price_frequency }}
                </span>
              </h4>
              <p style="white-space: pre-wrap;">
                {{ item.inclusions }}
              </p>
              <!-- <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li>Pharetra massa</li>
                <li class="na">Massa ultricies mi</li>
              </ul> -->
              <div class="btn-wrap" *ngIf="item.button_name != ''">
                <a
                  href="{{ item.button_url }}"
                  target="_blank"
                  class="btn-buy"
                  >{{ item.button_name }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <p style="font-size: 10px;" class="text-center mt-5">Companies should comply with all applicable regulatory requirements based on geographic location and subscription. Taxes may apply.</p>
      </div>
    </section>
    <!-- End Pricing Section -->    

    <!-- ======= F.A.Q Section ======= -->
    <section id="faq" class="faq section-bg">
      <div class="container">
        <div class="section-title">
          <h2 data-aos="fade-up">Milestones</h2>
        </div>

        <div class="faq-list">
          <ul>
            <li data-aos="fade-up">
              <!-- <i class="fas fa-pennant"></i> -->
              <i class="far fa-flag icon-help"></i>
              <!-- <i class="fas fa-pennant icon-help"></i> -->
              <a data-toggle="collapse" class="collapse" href="#faq-list-1"
                >Aug 2020 - Launched MVP
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div
                id="faq-list-1"
                class="collapse show"
                data-parent=".faq-list"
              >
                <p>
                  Launched the Minimal Viable Product (MVP) with ticket issuance
                  features for Galileo, Amadeus and Sabre on the Apple App Store
                  and Google Play Store
                </p>
              </div>
            </li>

            <!-- <li data-aos="fade-up" data-aos-delay="100">
              <i class="bx bx-help-circle icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-2" class="collapsed">How do I link my PCC / Office ID to Ticket
                Gadget?
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-2" class="collapse" data-parent=".faq-list">
                <p>1. Navigate to 'My Details' → 'Update My Details' → 'Add Agency PCC' section.</p>
                <p>2. Enter Provider/GDS, PCC / Office ID and submit.</p>
                <p>3. You will be notified via email once your PCC is linked to Ticket Gadget.</p>
              </div>
            </li> -->

            <li data-aos="fade-up" data-aos-delay="200">
              <i class="far fa-flag icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-3" class="collapsed"
                >Jan 2021 - Integration of flight search and book features
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-3" class="collapse" data-parent=".faq-list">
                <p>
                  Integrated user-friendly flight search & booking engine as
                  well advanced flight search tool
                </p>
                <!-- <p>2. Enter PNR and retrieve booking.</p>
                <p>3. Review segments and pricing.</p>
                <p>4. Pay and issue.</p> -->
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="500">
              <i class="far fa-flag icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-4" class="collapsed"
                >Oct 2021 - Became the 3rd largest revenue generator in the IATA
                BSP LK market
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-4" class="collapse" data-parent=".faq-list">
                <p>
                  We became the 3rd largest revenue generator in the IATA BSP LK
                  market
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="300">
              <i class="far fa-flag icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-5" class="collapsed"
                >Jan 2022 - Became the largest revenue generator in the IATA BSP
                LK market
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-5" class="collapse" data-parent=".faq-list">
                <p>
                  We became the largest revenue generator in the IATA BSP LK
                  market
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i class="far fa-flag icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-6" class="collapsed"
                >Feb 2022 - Became the largest revenue generator for Malaysia
                Airlines in the IATA BSP LK market
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-6" class="collapse" data-parent=".faq-list">
                <p>
                  We became the largest revenue generator for Malaysia Airlines
                  in the IATA BSP LK market
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i class="far fa-flag icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-7" class="collapsed"
                >Apr 2022 - Became the largest revenue generator for SriLankan
                Airlines for the first quarter of 2022 in the IATA BSP LK market
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-7" class="collapse" data-parent=".faq-list">
                <p>
                  We became the largest revenue generator for SriLankan Airlines
                  for the first quarter of 2022 in the IATA BSP LK market
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i class="far fa-flag icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-8" class="collapsed"
                >May 2022 - Won the Top Agent Silver Award from SriLankan
                Airlines for the FYE 2021-22 in the IATA BSP LK market
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-8" class="collapse" data-parent=".faq-list">
                <p>
                  We won the Top Agent Silver Award from SriLankan Airlines for
                  the FYE 2021-22 in the IATA BSP LK market
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i class="far fa-flag icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-9" class="collapsed"
                >May 2022 - Became the largest revenue generator for SriLankan Airlines, Malaysia Airlines and Gulf Air in the IATA BSP LK market
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-9" class="collapse" data-parent=".faq-list">
                <p>
                  We became the largest revenue generator for SriLankan Airlines, Malaysia Airlines and Gulf Air in the IATA BSP LK market
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i class="far fa-flag icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-10" class="collapsed"
                >Jun 2022 - Became the largest revenue generator for SriLankan Airlines, Malaysia Airlines, Gulf Air, Jetstar Airways and KLM Royal Dutch Airlines in the IATA BSP LK market
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-10" class="collapse" data-parent=".faq-list">
                <p>
                  We became the largest revenue generator for SriLankan Airlines, Malaysia Airlines, Gulf Air, Jetstar Airways and KLM Royal Dutch Airlines in the IATA BSP LK market
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i class="far fa-flag icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-11" class="collapsed"
                >Jul 2022 - Became the largest revenue generator for SriLankan Airlines, Malaysia Airlines and Gulf Air in the IATA BSP LK market
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-11" class="collapse" data-parent=".faq-list">
                <p>
                  We became the largest revenue generator for SriLankan Airlines, Malaysia Airlines and Gulf Air in the IATA BSP LK market
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i class="far fa-flag icon-help"></i>
              <a data-toggle="collapse" href="#faq-list-12" class="collapsed"
                >Aug 2022 - Became the largest revenue generator in the IATA BSP LK market
                <i class="bx bx-chevron-down icon-show"></i>
                <i class="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-12" class="collapse" data-parent=".faq-list">
                <p>
                  We became the largest revenue generator in the IATA BSP LK market
                </p>
              </div>
            </li>            

          </ul>
        </div>
      </div>
    </section>
    <!-- End F.A.Q Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container">
        <div class="section-title">
          <h2 data-aos="fade-up">Contact</h2>
        </div>

        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-6 mt-4" data-aos="fade-up">
            <div class="info-box">
              <p>We provide online support.</p>
              <p>Please use the 'Assistance' tab below to chat with us</p>
            </div>
          </div>

          <!-- <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="100">
            <div class="info-box">
              <i class="bx bx-envelope"></i>
              <h3>Email Us</h3>
              <p>sales@ticketgadget.com</p>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="200">
            <div class="info-box">
              <i class="bx bx-phone-call"></i>
              <h3>Call Us</h3>
              <p>0117 909090</p>
            </div>
          </div> -->
        </div>

        <div
          class="row justify-content-center"
          data-aos="fade-up"
          data-aos-delay="300"
        ></div>
      </div>
    </section>
    <!-- End Contact Section -->
  </div>
  <!-- End #main -->

  <app-footer></app-footer>
</div>
