import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class TicketManagerService {
  appService: AppService;
  requestData: any;
  constructor(private http: HttpClient, applicationService: AppService) {
    this.appService = applicationService;
  }

  getPccDetails() {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('my/pccs'),
      {},
      { headers: headerData }
    );
  }

  retrieveBookingRequest1(pccId, timezoneId, pnrcode) {
    const headerData = this.appService.getHeaderAfterLogged();
    this.requestData = {
      'pcc': pccId,
      'pnrcode': pnrcode,
      'zone': timezoneId
    }
    return this.http.post(
      environment.apiLink.concat('retrieve/booking/request1'),
      this.requestData,
      { headers: headerData }
    );
  }
  retrieveBookingRequest2(pccId, timezoneId, pnrcode, retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('retrieve/booking/request2'),
      {
        'pcc': pccId,
        'pnrcode': pnrcode,
        'zone': timezoneId,
        'retrieveInstanceId': retrieveInstanceId
      },
      { headers: headerData }
    );
  }
  retrieveBookingRequest3(pccId, timezoneId, pnrcode, retrieveInstanceId, origin, destination) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('retrieve/booking/request3'),
      {
        'pcc': pccId,
        'pnrcode': pnrcode,
        'zone': timezoneId,
        'origin': origin,
        'destination': destination,
        'retrieveInstanceId': retrieveInstanceId,
      },
      { headers: headerData }
    );
  }
  retrieveBookingRequest4(retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    if (sessionStorage.getItem("isCCard") && sessionStorage.getItem("isCCard") == "1") {
      this.requestData = {
        'retrieveInstanceId': retrieveInstanceId,
        'carddetails': JSON.parse(sessionStorage.getItem('carDetails'))
      }
    } else {
      this.requestData = {
        'retrieveInstanceId': retrieveInstanceId
      }
    }
    return this.http.post(
      environment.apiLink.concat('retrieve/booking/request4'),
      this.requestData,
      { headers: headerData }
    );
  }

  addToQueueBooking(retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('queue/ticket'),
      {
        'retrieveInstanceId': retrieveInstanceId
      },
      { headers: headerData }
    );
  }

  apiStartTimer(pnrCode, retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('start/timer'),
      {
        'pnr': pnrCode,
        'retrieveInstanceId': retrieveInstanceId
      },
      { headers: headerData }
    );
  }
  apiGetRemainingTimer(pnrCode, retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('remaining/timer'),
      {
        'pnr': pnrCode,
        'retrieveInstanceId': retrieveInstanceId
      },
      { headers: headerData }
    );
  }

  getPdfFilePath(pnrcode, pcc_id, zone) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('retrieve/ticket/save/file'),
      {
        'pcc': pcc_id,
        'pnrcode': pnrcode,
        'zone': zone
      },
      { headers: headerData }
    )
  }

  retrievePriceDetails(pnrcode, retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('retrieve/pricing'),
      {
        'pnr': pnrcode,
        'retrieveInstanceId': retrieveInstanceId
      },
      { headers: headerData }
    );
  }

  checkPaymentStatus(retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('check/payment/status'),
      {
        'retrieveInstanceId': retrieveInstanceId
      },
      { headers: headerData }
    );
  }

  issueAirTicket(retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('issue/ticket'),
      {
        'retrieveInstanceId': retrieveInstanceId
      },
      { headers: headerData }
    );
  }

  minMaxAmount(retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('get/amounts'),
      {
        'retrieveInstanceId': retrieveInstanceId
      },
      { headers: headerData }
    );
  }

  sendCreditCardAuthorizationRequest(retrieveInstanceId, amount) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('credit/card/authorization'),
      {
        'retrieveInstanceId': retrieveInstanceId,
        'amount': amount
      },
      { headers: headerData }
    );
  }

  voidAirTicket(ticketNumber, retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('void/ticket'),
      {
        'id': ticketNumber,
        'retrieveInstanceId': retrieveInstanceId
      },
      { headers: headerData }
    );
  }

  downloadEticket(ticketNumber, showPrice, theme) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('validate/download/ticket'),
      {
        'id': ticketNumber,
        'showPrice': showPrice,
        'theme': theme
      },
      { headers: headerData }
    );
  }

  emailEticket(ticketNumber, showPrice, theme) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('email/ticket'),
      {
        'id': ticketNumber,
        'showPrice': showPrice,
        'theme': theme
      },
      { headers: headerData }
    );
  }

  getIssuedAirTickets() {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('issued/tickets'),
      {},
      { headers: headerData }
    );
  }
  getQueuedBookings() {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('queued/bookings'),
      {},
      { headers: headerData }
    );
  }

  validateCardDetails(cradDetails) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('vaildate/card/details'),
      cradDetails,
      { headers: headerData }
    );
  }

  keepUserDataBeforePay(retrieveInstanceId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('keep/user/data'),
      {
        'retrieveInstanceId': retrieveInstanceId
      },
      { headers: headerData }
    );
  }

  sendOtp() {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('auth/user/phonenumber'),
      {},
      { headers: headerData }
    );
  }

  refreshBookingDetails(bookingId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('retrive/booking/data'),
      {
        "booking_id": bookingId
      },
      { headers: headerData }
    );
  }

  saveBookingreference(bookingReference) {
    const headerData = this.appService.getHeaderAfterLogged();
    const token = JSON.parse(localStorage.getItem("loggedDataCJW")).accessToken;
    return this.http.post(
      environment.apiLink.concat('save/booking/reference'),
      {
        'booking_reference': bookingReference,
        'token': token
      },
      { headers: headerData }
    );
  }

  getBookingDetails(): Observable<any> {
    let params = {};
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('get/flight/booking/details'), {},
      { headers: headerData }
    );
  }

  cancelAFlightBooking(params) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('cancel/flight/booking'),
      params,
      { headers: headerData }
    );
  }

  getMoreDetailsForBooking(params) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('more/booking/data'),
      params,
      { headers: headerData }
    );
  }

  getUser1GPcc() {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('get/1g/details'),
      "",
      { headers: headerData }
    );
  }
  getInqbaytorPayTrasnactions() {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('auth/user/inqbaytorpay/transaction'),
      {},
      { headers: headerData }
    );
  }

  getTrasnactions(startDate, endDate) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('auth/user/transactions'),
      {
        'start_date': startDate,
        'end_date': endDate
      },
      { headers: headerData }
    );
  }

  getStatementDetails(startDate) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('get/statement/date/details'),
      {
        'selected_date': startDate
      },
      { headers: headerData }
    );
  }

  getTicketPaymentData(currentDate, isYesterday) {
    const headerData = this.appService.getHeaderAfterLogged();
    let params = null;
    if (isYesterday == 1) {
      params = {
        'current_date': currentDate,
        'isYesterday': 1
      };
    } else {
      params = {
        'current_date': currentDate
      };
    }
    return this.http.post(
      environment.apiLink.concat('ticket/payment/data'),
      params,
      { headers: headerData }
    );
  }

  transerUserLog(logData) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('user/log'),
      logData,
      { headers: headerData }
    );
  }

  validateTicketDownload(ticketNumber) {
    const headerData = this.appService.getHeaderAfterLogged();
    let params = {
      'ticket_number': ticketNumber
    };
    return this.http.post(
      environment.apiLink.concat('validate/eticket/number'),
      params,
      { headers: headerData }
    );
  }

  validateFlightBooking(flightBookingReference) {
    const headerData = this.appService.getHeaderAfterLogged();
    let params = {
      'booking_reference': flightBookingReference
    };
    return this.http.post(
      environment.apiLink.concat('validate/booking/reference'),
      params,
      { headers: headerData }
    );
  }

  changeOwnerShip(bookingId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('change/booking/ownership'),
      {
        "booking_id": bookingId
      },
      { headers: headerData }
    );
  }

  showVendorRemarks(bookingId) {
    const headerData = this.appService.getHeaderAfterLogged();
    return this.http.post(
      environment.apiLink.concat('get/virtual/remarks'),
      {
        "booking_id": bookingId
      },
      { headers: headerData }
    );
  }

}
