/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-remark-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./show-remark-data.component";
import * as i3 from "ngx-bootstrap/modal/bs-modal-ref.service";
import * as i4 from "../../service/com-parent-child.service";
var styles_ShowRemarkDataComponent = [i0.styles];
var RenderType_ShowRemarkDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowRemarkDataComponent, data: {} });
export { RenderType_ShowRemarkDataComponent as RenderType_ShowRemarkDataComponent };
export function View_ShowRemarkDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Void Confirmation"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "center", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary btn-flat"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.xmlString; _ck(_v, 9, 0, currVal_0); }); }
export function View_ShowRemarkDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-show-remark-data", [], null, null, null, View_ShowRemarkDataComponent_0, RenderType_ShowRemarkDataComponent)), i1.ɵdid(1, 114688, null, 0, i2.ShowRemarkDataComponent, [i3.BsModalRef, i4.ComParentChildService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShowRemarkDataComponentNgFactory = i1.ɵccf("app-show-remark-data", i2.ShowRemarkDataComponent, View_ShowRemarkDataComponent_Host_0, {}, {}, []);
export { ShowRemarkDataComponentNgFactory as ShowRemarkDataComponentNgFactory };
