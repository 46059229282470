import { OnInit } from '@angular/core';
var SessionExpiaryComponent = /** @class */ (function () {
    function SessionExpiaryComponent() {
    }
    SessionExpiaryComponent.prototype.ngOnInit = function () {
    };
    SessionExpiaryComponent.prototype.logout = function () {
        localStorage.removeItem('user');
    };
    return SessionExpiaryComponent;
}());
export { SessionExpiaryComponent };
