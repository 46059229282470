import { OnInit } from '@angular/core';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent() {
        this.isTicketGadget = true;
        this.isTicketGadget = false;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        if (localStorage.getItem('user')) {
            this.isUserLogged = true;
        }
    };
    return HeaderComponent;
}());
export { HeaderComponent };
