import { Component, OnInit  } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ComParentChildService } from '../../service/com-parent-child.service';

@Component({
  selector: 'app-errorpopup',
  templateUrl: './errorpopup.component.html',
  styleUrls: ['./errorpopup.component.scss']
})
export class ErrorpopupComponent implements OnInit {

  bsModifySearchModalRef: BsModalRef;
  message: string;
  source: string;
  type: string;

  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private comparentchildservice: ComParentChildService,
  ) { }

  ngOnInit() {
  }
  hideModal() {

    if(this.bsModalRef){
      this.bsModalRef.hide();
      this.bsModalRef = null;
    }

    // if(this.bsModifySearchModalRef){
    //   this.bsModifySearchModalRef.hide();
    //   this.bsModifySearchModalRef = null;
    // }

    // this.modifySearch.hideModal();

  }

  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  onSubmitModifySearch() {
    this.hideModal();
    // this.bsModifySearchModalRef = this.modalService.show(SearchFormModalComponent, this.config);
    // this.bsModifySearchModalRef.content.searchLocation = 'modify';
    // onSubmitModifySearch

  }

  redirectHome() {
    this.router.navigate(['/flight/search/engine']);
  }

  enterNow() {
    this.hideModal();
  }

  skip() {
    // this.flightBooking.onSubmit();
    this.comparentchildservice.publish('skip-name-change', "skip");
    this.hideModal();
  }

  continuePayment() {
    this.comparentchildservice.publish('call-parent');
  }
}
