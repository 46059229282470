import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceFormatterPipe } from 'src/app/price-formatter.pipe';

@NgModule({
  declarations: [PriceFormatterPipe],
  imports: [
    CommonModule
  ],
  exports: [PriceFormatterPipe]
})
export class PriceFormatPipeModuleModule { }
