<app-header></app-header>
<ng-http-loader backgroundColor="#F7721E" spinner="sk-wave"></ng-http-loader>

<style type="text/css">
  body {
    min-height: 850px;
  }

  .content-wrapper {
    margin-top: 80px;
  }

  .intl-tel-input {
    width: 100% !important;
    display: block !important;
    display: table-cell;
  }

  .input-group .intl-tel-input .form-control {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
  }
</style>
<div class="wrapper">
  <div class="col-md-12 top-header">
    <h3>Verify Email Address</h3>
  </div>
  <div class="content-wrapper" style="min-height: 487px;">
    <div class="container-fluid">
      <div id="login-interface">

        <div class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-12">
          <div class="panel-body boxshadow">
            <div class="box box-default">

              <div class="box-body">
                <!-- @if(Session::has('successMessage')) -->
                <div class="alert alert-success alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  Success Message
                </div>
                <!-- @elseif(Session::has('updateMessage')) -->
                <div class="alert alert-success alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  Update Message
                </div>
                <!-- @elseif(Session::has('errorMessage')) -->
                <div class="alert alert-danger alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  Error message
                </div>
                <!-- @elseif($status['code']==7) -->
                <div class="alert alert-danger alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  Status Messages
                </div>
                <!-- @elseif($status['code']==0) -->
                <div class="alert alert-danger alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  Status Message
                </div>
                <!-- @elseif($status['code']==1) -->
                <div class="alert alert-success alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  Status Message
                </div>
                <!-- @elseif($status['code']==2) -->
                <div class="alert alert-info alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  Status Message
                </div>
                <!-- @elseif($status['code']==3) -->
                <div class="alert alert-success alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  Status Message
                </div>
                <!-- @else -->
                <div class="alert alert-warning alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  Status Message
                </div>
                <!-- @endif -->

                <div class="row">
                  <!-- @if(!$isVerifyEmail) -->
                  <form id="formResendVerificationEmail" method="POST" action="BaseUrlresendVerificationEmail">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                      <!-- @if(!Session::has('successMessage') && $status['code']!=1 && $status['code']!=2 && $status['code']!=3) -->
                      <button type="submit" id="btnResenEmail" name="resendVerificationEmail" class="btn btn-primary btn-block" disabled>Resend Account Activation Email</button>
                      <!-- @endif -->
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                      <!-- @if($status['code']==1) -->
                      <a href="home_url" class="btn btn-primary btn-block">Go Home</a>
                      <!-- @else -->
                      <a href="baseUrl_logout" class="btn btn-primary btn-block">Back to Login</a>
                      <!-- @endif -->
                    </div>
                  </form>
                  <!-- @else -->
                  <div class="col-md-6 col-md-offset-6 col-sm-6 col-sm-offset-6 col-xs-12">
                    <a href="baseUrl_logout" class="btn btn-primary btn-block">Back to Login</a>
                  </div>
                  <!-- @endif -->
                </div>

              </div>
              <!-- /.box-body -->
            </div>
            <!-- /.box -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>