<app-header></app-header>
<ng-http-loader backgroundColor="#F7721E" spinner="sk-wave"></ng-http-loader>

<style type="text/css">
  .body {
    height: 100%;
    position: relative;
    position: relative;
    padding-bottom: 6rem;
    min-height: 687px;
  }

  .wrapper {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 85px;
  }
</style>
<div class="body">
  <div class="wrapper">
    <div class="col-md-12 top-header">
      <h3>Verify Mobile Number</h3>
    </div>
    <div class="content-wrapper" style="min-height: 487px;">

      <div id="login-interface">
        <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12 no-padding">
          <div class="panel-body boxshadow" style="margin-top: 52px;">
            <div class="box box-default">
              <span style="display:block; height: 10px;"></span>
              <div class="box-body">
                <div class="alert alert-success alert-dismissible" [hidden]="!successMessage">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="closeSuccessMessage()">&times;</button>
                  {{ successMessage }}
                </div>
                <div class="alert alert-danger alert-dismissible" [hidden]="!errorMessage">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="closeErrorAlert()">&times;</button>
                  {{ errorMessage }}
                </div>
                <div class="alert alert-warning alert-dismissible" [hidden]="!warningMessage">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                  Warning Message
                </div>
                <!-- <div class="text-center">
                  <span id="spanSkip" (click)="skipVerfication()" class="orange-color bold-on-hover" style="cursor: pointer;">Skip</span>
                </div> -->
                <form [formGroup]='smsVerficationForm' (ngSubmit)="f.form.valid && checkPhoneNumberValid()" #f="ngForm" novalidate>
                  <input type="hidden" id="hiddenMode" name="_mode" value="verify" />
                  <div>
                    <div class="form-group">
                      <label class="control-label">Mobile Number</label>
                      <international-phone-number #phoneNumberInput (keyup)="onKey($event)" placeholder="Enter phone number" [minlength]="10" [maxlength]="15" [defaultCountry]="'lk'" [required]="false"
                        formControlName="tel" [ngClass]="{ 'intlContactNumber': (f.submitted && smsVerficationForm.controls['tel'].invalid)||errorContactNumber }"></international-phone-number>
                    </div>
                    <div class="form-group ">
                      <label class="control-label ">Code</label>
                      <input type="text" class="form-control" formControlName="code" [ngClass]="{ 'error-border': f.submitted && smsVerficationForm.controls['code'].invalid }"
                        placeholder="Enter 6-digit code sent via SMS" maxlength="6" value="old code or empty" required>
                    </div>
                  </div>
                  <div class="form-group " style="padding-bottom: 50px; ">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 " style="padding-left: 0; ">
                      <button *ngIf="disableResend" type="button" (click)="resendeSMSCode()" id="btnResendCode" name="resend" class="btn btn-primary btn-block"
                        [disabled]="disableResend">
                        Resend Code (
                        <countdown (finished)="finshedTimer()" [config]="{leftTime: remainingTime}">$!m!:$!s!</countdown>)
                      </button>
                      <button *ngIf="!disableResend" type="button" (click)="resendeSMSCode()" id="btnResendCode" name="resend" class="btn btn-primary btn-block">
                        Resend Code
                      </button>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 " style="padding-left: 0; ">
                      <a routerLink="/change/phonenumber" class="btn btn-primary btn-block ">Change Number</a>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pull-right " style="padding-left: 0; ">
                      <button type="submit" id="btnVerify" name="verify" class="btn btn-primary btn-block" value="verify">Verify</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>