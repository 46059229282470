import { OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
var UserSessionExpireComponent = /** @class */ (function () {
    function UserSessionExpireComponent(modalRef, modalService, router, route) {
        this.modalRef = modalRef;
        this.modalService = modalService;
        this.router = router;
        this.route = route;
    }
    UserSessionExpireComponent.prototype.ngOnInit = function () {
    };
    UserSessionExpireComponent.prototype.logoutFormTheSystem = function () {
        this.modalRef.hide();
        this.modalRef = null;
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        this.router.navigate(['/login']);
    };
    return UserSessionExpireComponent;
}());
export { UserSessionExpireComponent };
