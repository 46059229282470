import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComParentChildService } from 'src/app/service/com-parent-child.service';

@Component({
  selector: 'app-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent implements OnInit {

  constructor(
    public modalRef: BsModalRef,
    private comparentchildservice: ComParentChildService
  ) { }

  ngOnInit() {
  }

  confirmDelete() {
    this.comparentchildservice.publish('confirm-delete');
    this.cancelPopup();
  }

  cancelPopup() {
    this.modalRef.hide();
    this.modalRef = null;
  }

}
