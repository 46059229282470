/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./http-error-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./http-error-popup.component";
import * as i3 from "ngx-bootstrap/modal/bs-modal-ref.service";
var styles_HttpErrorPopupComponent = [i0.styles];
var RenderType_HttpErrorPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HttpErrorPopupComponent, data: {} });
export { RenderType_HttpErrorPopupComponent as RenderType_HttpErrorPopupComponent };
export function View_HttpErrorPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-dialog modal-m"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "modal-body"], ["style", "background-color: #f7f7f7;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "center", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [["style", "padding-bottom: 5px;text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.httpErrorObject.name; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.httpErrorObject.message; _ck(_v, 11, 0, currVal_1); }); }
export function View_HttpErrorPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-http-error-popup", [], null, null, null, View_HttpErrorPopupComponent_0, RenderType_HttpErrorPopupComponent)), i1.ɵdid(1, 245760, null, 0, i2.HttpErrorPopupComponent, [i3.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HttpErrorPopupComponentNgFactory = i1.ɵccf("app-http-error-popup", i2.HttpErrorPopupComponent, View_HttpErrorPopupComponent_Host_0, {}, {}, []);
export { HttpErrorPopupComponentNgFactory as HttpErrorPopupComponentNgFactory };
