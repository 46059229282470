import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PhoneNumberVerificationComponent } from 'src/app/phone-number-verification/phone-number-verification.component';
import { EmailVerificationComponent } from 'src/app/email-verification/email-verification.component';
import { OldPhoneNumberVerificationComponent } from 'src/app/old-phone-number-verification/old-phone-number-verification.component';
import { ForgetPasswordComponent } from 'src/app/forget-password/forget-password.component';
import { ResetPasswordComponent } from 'src/app/reset-password/reset-password.component';
import { ChangePhoneNumberComponent } from 'src/app/change-phone-number/change-phone-number.component';
import { ResendEmailVerificationComponent } from 'src/app/resend-email-verification/resend-email-verification.component';
import { AuthRegisterredirecterComponent } from "src/app/auth-registerredirecter/auth-registerredirecter.component";
import { UserGuidelinesComponent } from './user-guidelines/user-guidelines.component';
import { ForgetPasswordEmailVerificationComponent } from './forget-password-email-verification/forget-password-email-verification.component';
export var routes = [
    {
        path: '',
        children: [
            { path: '', component: HomeComponent },
            { path: 'about', component: HomeComponent, data: { kind: 'about' } },
            { path: 'why', component: HomeComponent, data: { kind: 'why' } },
            { path: 'contact', component: HomeComponent, data: { kind: 'contact' } },
            { path: 'subscription', component: HomeComponent, data: { kind: 'subscription' } }
        ]
    },
    // {
    //   path: 'features',
    //   component: FeaturesComponent
    // },
    // {
    //   path: 'faq',
    //   component: FaqComponent
    // },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'auth/register',
        component: AuthRegisterredirecterComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    // {
    //   path: 'terms-and-conditions',
    //   component: TermsAndConditionsComponent
    // },
    // {
    //   path: 'privacy-policy',
    //   component: PrivacyPolicyComponent
    // },
    {
        path: 'email/verification',
        component: EmailVerificationComponent
    },
    {
        path: 'sms/verification',
        component: PhoneNumberVerificationComponent
    },
    {
        path: 'verify/old-new',
        component: OldPhoneNumberVerificationComponent
    },
    {
        path: 'user-guidelines',
        component: UserGuidelinesComponent
    },
    // {
    //   path: 'app/log',
    //   component: LoginfoComponent,
    //   canActivate: [AuthGuard]
    // },
    {
        path: 'forgot/password',
        component: ForgetPasswordComponent
    },
    {
        path: 'reset/password',
        component: ResetPasswordComponent
    },
    {
        path: 'verify/otp',
        component: ForgetPasswordEmailVerificationComponent
    },
    {
        path: 'change/phonenumber',
        component: ChangePhoneNumberComponent
    },
    // {
    //   path: 'itinerary-printer',
    //   component: ItineraryPrinterComponent
    // },
    {
        path: 'resend/email/verfication',
        component: ResendEmailVerificationComponent
    },
    {
        path: 'not-found',
        loadChildren: './not-found/not-found.module#NotFoundModule'
    },
    {
        path: '**',
        redirectTo: ''
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
