import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
var ForgetPasswordEmailVerificationComponent = /** @class */ (function () {
    function ForgetPasswordEmailVerificationComponent(formBuilder, applicationService, router) {
        this.applicationService = applicationService;
        this.router = router;
        this.otp = '';
        this.errorMessage = "";
        this.forgotPasswordEmailVerifyForm = formBuilder.group({
            'otp': ['', [Validators.required]],
        });
    }
    ForgetPasswordEmailVerificationComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(ForgetPasswordEmailVerificationComponent.prototype, "f", {
        get: function () { return this.forgotPasswordEmailVerifyForm.controls; },
        enumerable: true,
        configurable: true
    });
    ForgetPasswordEmailVerificationComponent.prototype.submitOtp = function () {
        var _this = this;
        this.errorMessage = '';
        var otp = this.f['otp'].value;
        var email = localStorage.getItem('fpemail');
        var requestData = {
            "otp": otp,
            "email": email
        };
        this.applicationService.sendOtpVerifiication(requestData).subscribe(function (data) {
            if (data.code === 1 || data.code === '1') {
                localStorage.setItem('fotp', otp);
                _this.router.navigate(['/reset/password']);
            }
            else {
                _this.errorMessage = data.message;
            }
        }, function (httpError) {
            _this.errorMessage = "Sorry, Something went wrong. Please try again later.";
        });
    };
    return ForgetPasswordEmailVerificationComponent;
}());
export { ForgetPasswordEmailVerificationComponent };
