import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserDetailsService } from 'src/app/functionalservices/user-details.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserAuthenticaionService } from '../user-authenticaion.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-change-phone-number',
  templateUrl: './change-phone-number.component.html',
  styleUrls: ['./change-phone-number.component.scss']
})
export class ChangePhoneNumberComponent implements OnInit {

  changePhoneNumberForm: FormGroup;
  successMessage: any;
  errorMessage: any;
  warningMessage: any;
  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef;
  errorContactNumber = false;
  constructor(
    formBuilder: FormBuilder,
    applicationService: UserDetailsService,
    private router: Router,
    private userDetailService: UserDetailsService,
    private route: ActivatedRoute,
    private authService: UserAuthenticaionService,
    ) {

    this.changePhoneNumberForm = formBuilder.group({
      'tel': ['', [Validators.required]]
    });
  }

  ngOnInit() {
  }

  checkPhoneNumberValid(){
   var data = {
     "phoneNumber": this.changePhoneNumberForm.controls['tel'].value,
     "country":this.phoneNumberInput['selectedCountry']['countryCode']
   }
   this.authService.phoneNumberValidating(data).subscribe(
     (data: any) => {
       if (data.code == 1) {
         this.changePhoneNUmber();
       } else {
         this.errorContactNumber = true;
       }
     }, (htError: HttpErrorResponse) => {
     });
 }  
 onKey(e){
  this.errorContactNumber = false;
  if(this.f['tel'].value.length > 5){
    if(this.f['tel'].value.substring(0,5) == "+9474"){
      this.changePhoneNumberForm.get('tel').clearValidators();
      this.changePhoneNumberForm.controls['tel'].updateValueAndValidity();
    }else{
      this.changePhoneNumberForm.get('tel').setValidators([Validators.required]);
      this.changePhoneNumberForm.controls['tel'].updateValueAndValidity();
    }
  }else{
    this.changePhoneNumberForm.get('tel').clearValidators();
    this.changePhoneNumberForm.controls['tel'].updateValueAndValidity();
    this.changePhoneNumberForm.get('tel').setValidators([Validators.required]);
    this.changePhoneNumberForm.controls['tel'].updateValueAndValidity();      
  }
}
  changePhoneNUmber() {
    const phoneNumber = this.f['tel'].value;
    this.userDetailService.changeUserContactNumber(phoneNumber).subscribe(
      (data: any) => {
        if (data.code === 1 || data.code === '1') {
          this.userDetailService.getUserDetails().subscribe(
            (userData: any) => {
              if (userData !== null) {
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(userData));
                this.router.navigate(['/sms/verification']);
              }
            }
          );
        } else {
          this.errorMessage = data.message;
        }

      },
      (httpError: any) => {

      }
    );
  }
  get f() { return this.changePhoneNumberForm.controls; }
}
