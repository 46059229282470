<app-header></app-header>
<ng-http-loader backgroundColor="#F7721E" spinner="sk-wave"></ng-http-loader>

<style type="text/css">
  .body {
    height: 100%;
    position: relative;
    position: relative;
    padding-bottom: 6rem;
    min-height: 687px;
  }

  .wrapper {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 85px;
  }
</style>
<div class="body">
  <div class="wrapper">
    <div class="col-md-12 top-header">
      <h3>Verify Email Address</h3>
    </div>
    <div class="content-wrapper" style="min-height: 487px;">
      <div class="container-fluid">
        <div id="login-interface">

          <div class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-12">
            <div class="panel-body boxshadow">
              <div class="box box-default">

                <div class="box-body">
                  <div class="row">
                    <form id="formResendVerificationEmail" method="POST" action="resendVerificationEmail">
                      <div class="col-md-6 col-sm-6 col-xs-12">
                        <button type="submit" id="btnResenEmail" name="resendVerificationEmail" class="btn btn-primary btn-block" disabled>Resend Account Activation Email</button>
                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-12">
                        <a href="" class="btn btn-primary btn-block">Go Home</a>
                        <a href="" class="btn btn-primary btn-block">Back to Login</a>
                      </div>
                    </form>
                    <div class="col-md-6 col-md-offset-6 col-sm-6 col-sm-offset-6 col-xs-12">
                      <a href="" class="btn btn-primary btn-block">Back to Login</a>
                    </div>
                  </div>

                </div>
                <!-- /.box-body -->
              </div>
              <!-- /.box -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>