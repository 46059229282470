import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.service";
var UserDetailsService = /** @class */ (function () {
    function UserDetailsService(http, applicationService) {
        this.http = http;
        this.appService = applicationService;
    }
    UserDetailsService.prototype.getUserDetails = function () {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('user/details'), {}, { headers: headerData });
    };
    UserDetailsService.prototype.updateUserDetails = function (user) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('update/user'), JSON.stringify(user), { headers: headerData });
    };
    UserDetailsService.prototype.changePassword = function (jsonData) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('changepassworddeails'), jsonData, { headers: headerData });
    };
    UserDetailsService.prototype.getUserPccs = function () {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('all/pccs'), {}, { headers: headerData });
    };
    UserDetailsService.prototype.getAllProviders = function () {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('all/providers'), {}, { headers: headerData });
    };
    UserDetailsService.prototype.requestPccs = function (jsonData) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('request/pccs'), jsonData, { headers: headerData });
    };
    UserDetailsService.prototype.changeUserContactNumber = function (phoneNumber) {
        var headerData = this.appService.getHeaderAfterLogged();
        return this.http.post(environment.apiLink.concat('changetel'), {
            "phone_number": phoneNumber
        }, { headers: headerData });
    };
    UserDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function UserDetailsService_Factory() { return new UserDetailsService(i0.inject(i1.HttpClient), i0.inject(i2.AppService)); }, token: UserDetailsService, providedIn: "root" });
    return UserDetailsService;
}());
export { UserDetailsService };
