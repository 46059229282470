// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseLink: 'https://ticketgadgetlk-api.inqbaytor.co/',
  // linkText: 'tgibs3ir2',
  // apiLink: 'http://localhost:8000/PRE-PRODUCTION/ticketgadget-api/localapi.ticketgadget.com/public/api/',

  // apiLink: 'https://ticketgadgetlk-api.inqbaytor.co/apis/',
  apiLink:'https://ticketgadgetlk-api.inqbaytor.com.au/api/',
  // apiLink:'https://ticketgadgetlk-api.inqbaytor.io/api/',
  // apiLink:'http://192.168.169.189:8000/tgapi2/public/api/',
  // baseLink: 'http://127.0.0.1:8000/tgapi/public/',
  linkText: 'tgapi',
  // hotelAuthapi:'http://192.168.170.71:8000/cjwapi/public/api',

  hotelAuthapi: 'http://127.0.0.1:8000/cjwapi-git-pushed/cjwapi/public/api',
  hotelApi: 'https://hotelsapi.crazyjets.com.au/api',
  // apiLink: 'http://tgibs3ir2.enabledbeings.com/api/'

  flightAPI: 'https://cjwapi.inqbaytor.co/api/',
  pdfDomain: 'https://demo.onopicks.com/#/',
  gdsDomain: 'https://ticketgadget.com.au/#/',
  sampathBankAction: 'https://sampath.paycorp.lk/webinterface/app/payment',

  imageUrl: [
    "https://photos.hotelbeds.com/giata/",
    "https://photos.hotelbeds.com/giata/small/",
    "https://photos.hotelbeds.com/giata/original/",
    "https://photos.hotelbeds.com/giata/medium/",
    "https://photos.hotelbeds.com/giata/bigger/"
  ],

  ENCRYPT_KEY1: "efa475c7cf87fc1252b8fa9092b51fb37e347384fcb2c0a04e2616b67e4f69ca",
  ENCRYPT_KEY2: "161f792db2475b5d164b6eea39067e31ac44d481b911a2a45c6b85aeb1c76baf",

  bookingrefestr: 'CRAZYJETS.COM',
  paymentsImageArr: [
    { image: '../../assets/images/banks/crazyjets-visa.jpg', thumbImage: '../../assets/images/banks/crazyjets-visa.jpg' },
    { image: '../../assets/images/banks/crazyjets-master.jpg', thumbImage: '../../assets/images/banks/crazyjets-master.jpg' },
    { image: '../../assets/images/banks/crazyjets-amex.jpg', thumbImage: '../../assets/images/banks/crazyjets-amex.jpg' },
    { image: '../../assets/images/banks/crazyjets-hnb.jpg', thumbImage: '../../assets/images/banks/crazyjets-hnb.jpg' },
    { image: '../../assets/images/banks/crazyjets-ndb.jpg', thumbImage: '../../assets/images/banks/crazyjets-ndb.jpg' },
    { image: '../../assets/images/banks/crazyjets-sampath.jpg', thumbImage: '../../assets/images/banks/crazyjets-sampath.jpg' },
    { image: '../../assets/images/banks/crazyjets-combank.jpg', thumbImage: '../../assets/images/banks/crazyjets-combank.jpg' },
    { image: '../../assets/images/banks/crazyjets-dfcc.jpg', thumbImage: '../../assets/images/banks/crazyjets-dfcc.jpg' },
    { image: '../../assets/images/banks/crazyjets-pabc.jpg', thumbImage: '../../assets/images/banks/crazyjets-pabc.jpg' },
    { image: '../../assets/images/banks/crazyjets-seylan.jpg', thumbImage: '../../assets/images/banks/crazyjets-seylan.jpg' },
    { image: '../../assets/images/banks/crazyjets-scb.jpg', thumbImage: '../../assets/images/banks/crazyjets-scb.jpg' },
    { image: '../../assets/images/banks/crazyjets-ntb.jpg', thumbImage: '../../assets/images/banks/crazyjets-ntb.jpg' },
    { image: '../../assets/images/banks/crazyjets-peoples.jpg', thumbImage: '../../assets/images/banks/crazyjets-peoples.jpg' },
    { image: '../../assets/images/banks/crazyjets-boc.jpg', thumbImage: '../../assets/images/banks/crazyjets-boc.jpg' },
    { image: '../../assets/images/banks/crazyjets-union.jpg', thumbImage: '../../assets/images/banks/crazyjets-union.jpg' },
    { image: '../../assets/images/banks/crazyjets-cargills.jpg', thumbImage: '../../assets/images/banks/crazyjets-cargills.jpg' },
  ],

  inclusionsNote: "Please note that the above rules, terms and conditions are overridden by the terms and conditions laid out in each promotion and by our payment partners. The full list of terms and conditions can be found at checkout once you select your payment method, on the Crazy Jets promotion page or on the payment partner's promotion page."
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
