import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CountdownComponent } from 'ngx-countdown';
import { UserDetailsService } from 'src/app/functionalservices/user-details.service';
import { redirectUrls } from '../helpers/redirect-urls';
var OldPhoneNumberVerificationComponent = /** @class */ (function () {
    function OldPhoneNumberVerificationComponent(formBuilder, applicationService, router, userDetailService) {
        this.router = router;
        this.userDetailService = userDetailService;
        var loggedUser = JSON.parse(localStorage.getItem('user'));
        this.loggedUserId = loggedUser.id;
        this.mobileNumber = loggedUser.mobile_number;
        this.appService = applicationService;
        this.disableResend = true;
        this.smsVerficationForm = formBuilder.group({
            'oldcode': ['', [Validators.required]],
            'newcode': ['', [Validators.required]],
            'telOld': ['', []],
            'telNew': ['', []]
        });
    }
    OldPhoneNumberVerificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.oldPhoneData = JSON.parse(localStorage.getItem('oldPhoneData'));
        this.appService.getResendRemainingTimer().subscribe(function (data) {
            if (data.code === 1 || data.code === '1') {
                _this.remainingTime = data.remainingSeconds;
                _this.disableResend = true;
            }
            else {
                _this.disableResend = false;
            }
        }, function (errorResponse) {
        });
        this.smsVerficationForm.controls['telOld'].setValue(this.oldPhoneData.phoneNumber);
        this.smsVerficationForm.controls['telNew'].setValue(this.mobileNumber);
    };
    Object.defineProperty(OldPhoneNumberVerificationComponent.prototype, "f", {
        get: function () { return this.smsVerficationForm.controls; },
        enumerable: true,
        configurable: true
    });
    OldPhoneNumberVerificationComponent.prototype.verifySMSCode = function () {
        var _this = this;
        // tslint:disable-next-line:label-position
        this.successMessage = null;
        this.errorMessage = null;
        var mode = 'verify_both';
        var oldCode = this.f['oldcode'].value;
        var newCode = this.f['newcode'].value;
        this.appService.verifyBothSmsCode(mode, oldCode, newCode).subscribe(function (data) {
            if (data.code === 1) {
                localStorage.setItem('oldPhoneData', null);
                console.log(data.message);
                sessionStorage.setItem('dashboardSuccessMsg', data.message);
                _this.userDetailService.getUserDetails().subscribe(function (userData) {
                    if (userData !== null) {
                        localStorage.setItem('user', JSON.stringify(userData));
                        var token = localStorage.getItem('accessToken');
                        var url = redirectUrls.redirectWelcome + '?token=' + token;
                        window.location.href = url;
                    }
                });
            }
            else {
                _this.errorMessage = data.message;
                _this.successMessage = null;
            }
        }, function (errorResponse) {
        });
    };
    OldPhoneNumberVerificationComponent.prototype.resendeSMSCode = function () {
        var _this = this;
        this.successMessage = null;
        this.errorMessage = null;
        this.disableResend = true;
        this.appService.resendBothSMSVerification().subscribe(function (data) {
            if (data.code === 1) {
                localStorage.setItem('user', JSON.stringify(data.updatedUserData));
                _this.successMessage = data.message;
                _this.errorMessage = null;
                _this.f['oldcode'].setValue('');
                _this.f['newcode'].setValue('');
                _this.remainingTime = 120;
            }
            else {
                _this.errorMessage = data.message;
                _this.successMessage = null;
                _this.disableResend = false;
            }
        }, function (errorResponse) {
        });
    };
    OldPhoneNumberVerificationComponent.prototype.skipVerfication = function () {
        var mode = 'skip';
        console.log(mode);
        this.appService.skipSMSVerification(mode).subscribe(function (data) {
            if (data.code === 1) {
                localStorage.setItem('user', JSON.stringify(data.updatedUserData));
                var token = localStorage.getItem('accessToken');
                var url = redirectUrls.redirectWelcome + '?token=' + token;
                window.location.href = url;
            }
        }, function (errorResponse) {
        });
    };
    OldPhoneNumberVerificationComponent.prototype.closeErrorAlert = function () {
        this.errorMessage = null;
    };
    OldPhoneNumberVerificationComponent.prototype.closeSuccessMessage = function () {
        this.successMessage = null;
    };
    OldPhoneNumberVerificationComponent.prototype.finshedTimer = function () {
        this.disableResend = false;
    };
    return OldPhoneNumberVerificationComponent;
}());
export { OldPhoneNumberVerificationComponent };
