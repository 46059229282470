import { OnInit } from '@angular/core';
var AuthRegisterredirecterComponent = /** @class */ (function () {
    function AuthRegisterredirecterComponent() {
    }
    AuthRegisterredirecterComponent.prototype.ngOnInit = function () {
        location.href = "/register";
    };
    return AuthRegisterredirecterComponent;
}());
export { AuthRegisterredirecterComponent };
