<app-header></app-header>
<ng-http-loader backgroundColor="#F7721E" spinner="sk-wave"></ng-http-loader>

<style type="text/css">
  .body {
    height: 100%;
    position: relative;
    position: relative;
    padding-bottom: 6rem;
    min-height: 687px;
  }

  .wrapper {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 85px;
  }
</style>
<div class="body">
  <div class="wrapper">
    <div class="col-md-12 top-header">
      <h3>Register</h3>
    </div>
    <div class="content-wrapper" style="min-height: 487px; margin-bottom: 50px;">
      <div class="container-fluid">
        <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">

          <div class="panel-body boxshadow">

            <div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p>Fill up this form to self register.</p>
              </div>
              <div id="error-messages" class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [hidden]="!errorMessage">
                <div class="alert alert-danger alert-dismissible">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  <p class="erMessage">{{ errorMessage }}</p>
                </div>
              </div>
              <!-- register form -->
              <form [formGroup]='registerForm' (ngSubmit)="f.form.valid && checkPhoneNumberValid()" #f="ngForm" novalidate>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group" id="div_reg_name">
                    <label>Name
                      <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" placeholder="Enter Name" formControlName="reg_name" maxlength="100" autocomplete="off"
                      value="" [ngClass]="{ 'error-border': f.submitted && registerForm.controls['reg_name'].invalid || errorName}"
                      required/>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group" id="div_reg_company_name">
                    <label>Company Name
                      <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" placeholder="Enter Company Name" formControlName="reg_company_name" maxlength="50"
                      autocomplete="off" value="" [ngClass]="{ 'error-border': f.submitted && registerForm.controls['reg_company_name'].invalid || errorCompanyName}"
                      required/>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group" id="div_reg_profile_group">
                    <label>Subscription
                      <span class="text-danger">*</span>
                    </label>
                    <select class="form-control" formControlName="reg_user_profile" [ngClass]="{ 'error-border': f.submitted && registerForm.controls['reg_user_profile'].invalid }"
                      (change)="changeSubscription($event)">
                      <option *ngFor="let profile of userProfileGroups" [value]="profile.id">{{ profile.group_name }}</option>
                    </select>
                  </div>
                  <div *ngIf="subscriptionDescription" class="subscription_description text-center">
                    <p class="subsc-description-p">{{ subscriptionDescription }}</p>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12" *ngIf="selectedCountry">
                  <div class="form-group" id="div_reg_contact_number">
                    <label>Mobile Number
                      <span class="text-danger">*</span>
                    </label>
                    <international-phone-number #phoneNumberInput (keyup)="onKey($event)" placeholder="Enter phone number" [maxlength]="20" [defaultCountry]="selectedCountry" [required]="false" formControlName="reg_contact_number"
                      [ngClass]="{ 'intlContactNumber': f.submitted && registerForm.controls['reg_contact_number'].invalid || errorContactNumber}"></international-phone-number>
                    <!-- <ngx-intl-tel-input [(value)]="phone_number1" ></ngx-intl-tel-input> -->
                    <!-- {{ phone_number1 }} -->
                    <!-- <input type="text" class="form-control" value="" formControlName="reg_contact_number" [ngClass]="{ 'error-border': f.submitted && registerForm.controls['reg_contact_number'].invalid }"
                      required /> -->
                     <p *ngIf="errorMobile" id="ngp_reg_mobile" class="text-danger">{{errorMobileText}}</p>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <p id="ngp_reg_contact_number" class="text-danger hidden"></p>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group" id="div_reg_email">
                    <label>Registration Email
                      <span class="text-danger">*</span>
                    </label>
                    <input type="email" class="form-control" placeholder="Enter Registration Email" autocomplete="off" value="" maxlength="255"
                      formControlName="reg_email" [ngClass]="{ 'error-border': f.submitted && registerForm.controls['reg_email'].invalid || errorEmail}"
                      required>
                    <p *ngIf="f.submitted && registerForm.controls['reg_email'].invalid" id="ngp_reg_email" class="text-danger">This value email invalid</p>
                    <p *ngIf="errorEmailExist" class="text-danger">We have detected that this email address is already in use.
                    </p>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group" id="div_reg_password">
                    <label>Password
                      <span class="text-danger">*</span>
                    </label>
                    <div class="row m-0 p-0" style="display: flex;align-items: center;justify-content: center;">
                      <div class="col-md-11 m-0 p-0">
                        <input [type]="show ? 'text' : 'password'" class="form-control" placeholder="Enter Password" autocomplete="off" maxlength="30" formControlName="reg_password"
                        [ngClass]="{ 'error-border': f.submitted && registerForm.controls['reg_password'].invalid || errorInPassword}"
                        required/>
                        <div *ngIf="f.submitted && registerForm.get('reg_password').errors?.pattern" class="text-danger">
                          <p class="m-0" style="margin: 0px;">The password must be a minimum of 8 characters and should contain at least one upper- and lower-case letter, a number, and a special character</p>
                        </div>                        
                      </div>
                      <div class="col-md-1 m-0 p-0">
                        <i *ngIf="!show" (click)="password()" style="font-size: 20px;" class="bx bxs-show bx-lg"></i>
                        <i *ngIf="show" (click)="password()" style="font-size: 20px;" class="bx bxs-hide bx-lg"></i>
                      </div>
                    </div>
                    <p *ngIf="errorInPassword" id="ngp_reg_password" class="text-danger">{{ errorPassword }}</p>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group" id="div_confirmpassword">
                    <label>Confirm Password
                      <span class="text-danger">*</span>
                    </label>
                    <div class="row m-0 p-0" style="display: flex;align-items: center;justify-content: center;">
                      <div class="col-md-11 m-0 p-0">
                        <input (keyup)="onPasswordKey($event)" [type]="confirmShow ? 'text' : 'password'" placeholder="Enter Confirm Password" class="form-control" maxlength="30" formControlName="confirmpassword"
                        [ngClass]="{ 'error-border': f.submitted && registerForm.controls['confirmpassword'].invalid || errorConfirmpassword}"
                        onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" required> 
                      </div>
                      <div class="col-md-1 m-0 p-0">
                        <i *ngIf="!confirmShow" (click)="confirmPassword()" style="font-size: 20px;" class="bx bxs-show bx-lg"></i>
                        <i *ngIf="confirmShow" (click)="confirmPassword()" style="font-size: 20px;" class="bx bxs-hide bx-lg"></i>
                      </div>
                    </div>
                    <p *ngIf="errorConfirmpassword" id="ngp_confirmpassword" class="text-danger">{{ errorConfirmPasswordMsg }}</p>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-bottom: 10px;">
                  <div class="row d-flex">
                    <div class="col-md-1">
                      <input type="checkbox" id="remember" name="remember" (change)="onAgreeChange($event)" formControlName="isAgree">
                    </div>
                    <div class="col-md-11">
                      <label for="remember" class="label-checkbox">By registering, I confirm and acknowledge that the information I have provided is true and correct. Further, I agree that I comply and will continue to comply with ticket gadget <a href="/user-guidelines" target="_blank" style="color:#959ea8">user guidelines</a> as well as all regulatory and legal requirements in my jurisdiction. I understand that any penalties, fines or expenditures incurred as a result of not meeting regulatory requirements will be my sole responsibility.</label>  
                      <p *ngIf="errorAgree" id="ngp_reg_password" class="text-danger">Please agree to terms and condition.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [disabled]="disableRegister">
                  <button id="btnRegister" type="submit" class="btn btn-primary next pull-right">Register</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>