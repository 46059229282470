import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-show-updated-password-popup',
  templateUrl: './show-updated-password-popup.component.html',
  styleUrls: ['./show-updated-password-popup.component.scss']
})
export class ShowUpdatedPasswordPopupComponent implements OnInit {

  qrCodeValue: any;
  dataArray: any;

  constructor(
    public modalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.qrCodeValue = sessionStorage.getItem("qrcode_data");
    console.log(this.qrCodeValue);
    if (this.qrCodeValue) {
      this.dataArray = this.qrCodeValue.split(',');
    }
  }

  cancelPopup() {
    this.modalRef.hide();
    this.modalRef = null;
  }

}
